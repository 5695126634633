// import api from "@/urls/mfd01";
import api from "@/urls/urls_all";
const state = {
  mfd0101: [],
  mfd0102: {},
  mfd0103: {},
  mfd0104: {},
  mfd01011: [],
  mfd01011Loading: true,
};

const getters = {};

const mutations = {
  SET_MFD0101(state, { mfd0101 }) {
    state.mfd0101 = mfd0101;
  },
  SET_MFD0102(state, { mfd0102, feed_no }) {
    state.mfd0102[feed_no] = mfd0102;
  },
  SET_MFD0103(state, { mfd0103, feed_no }) {
    state.mfd0103[feed_no] = mfd0103;
  },
  SET_MFD0104(state, { mfd0104, feed_no }) {
    state.mfd0104[feed_no] = mfd0104;
  },
  SET_MFD01011(state, { mfd01011 }) {
    state.mfd01011 = mfd01011;
    state.mfd01011Loading = false;
  },
};

const actions = {
  async fetchMfd0101({ commit }, { sort, hot_place, good_travel, good_food }) {
    const data = await api.getMfd0101({
      proc_cd: "01",
      sort,
      hot_place,
      good_travel,
      good_food,
    });
    commit("SET_MFD0101", { mfd0101: data.data.Res_data });
  },
  async fetchMfd0102({ commit }, { feed_no, mem_id }) {
    const data = await api.getMfd0102({
      proc_cd: "01",
      feed_no,
      mem_id,
    });
    const images = await api.getMfd0102({
      proc_cd: "02",
      feed_no,
    });
    if (data.data.Res_data.length > 0) {
      commit("SET_MFD0102", {
        mfd0102: {
          ...data.data.Res_data[0],
          images: images.data.Res_data.filter(
            (image) => image.IMG_TYPE !== "V"
          ),
        },
        feed_no,
      });
    }
  },
  async fetchMfd0103({ commit }, { feed_no }) {
    const data = await api.getMfd0103({
      proc_cd: "01",
      feed_no,
    });
    commit("SET_MFD0103", {
      mfd0103: data.data.Res_data,
      feed_no,
    });
  },
  async fetchMfd0104({ commit }, { feed_no, mem_id, proc_cd }) {
    const data = await api.getMfd0104({
      proc_cd,
      feed_no,
      mem_id,
    });
    commit("SET_MFD0104", {
      mfd0104: data.data.Res_data,
      feed_no,
    });
  },
  async fetchMfd01011({ commit }, { mem_id }) {
    const data = await api.getMfd01011({
      proc_cd: "01",
      mem_id,
    });
    commit("SET_MFD01011", { mfd01011: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
