<template>
	<div class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a class="nav__btn nav__btn--prev" @click="handleClose()">
								<i class="icon icon-close"></i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10017')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="filter">
						<div class="box">
							<!-- 필터 테마 -->
							<article class="filter__item">
								<div class="flex">
									<h4 class="filter__title">{{t('10669')}}</h4><!-- 재번역체크 -->
									<div class="filter__seleted">
										<strong>{{theme.length}}</strong><!-- 재번역체크 -->
										{{t('10770')}}
									</div>
								</div>
								<!-- [D] 클릭시 active 클래스 추가 -->
								<ul class="facilities">
									<li v-for="item in themeList" :key="item.code" @click="onClickTheme(item.code)" :class="{'active': theme.indexOf(item.code) > -1}">
										<div class="facility__item">
											<i class="facility__icon" v-if="item.img_url !== null && item.img_url.length > 0">
												<img :alt="item.code" :src="item.img_url"  @error="replaceByDefault">
											</i>
											<!-- <i class="i icon-mirror"></i> -->
											<span class="facility__text">
											{{item.code_nm}}
											</span>
										</div>
									</li>
								</ul>
							</article>
							<!-- 필터 편의시설 -->
							<article class="filter__item">
								<div class="flex">
									<h4 class="filter__title">{{t('10670')}}</h4><!-- 재번역체크 -->
									<div class="filter__seleted">
										<strong>{{convs.length}}</strong><!-- 재번역체크 -->
										{{t('10770')}}
									</div>
								</div>
								<!-- [D] radio 처리 -->
								<div class="facilities__wrap">
									<!-- [D] 활성화시 active 클래스 추가 -->
									<div class="facilities__slider">
										<swiper :scrollbar="{ el : '.progress__bar--1' }" :slidesPerView="2.2">
											<swiper-slide v-for="(convsList, index) in convsChunkList" :key="index">
												<div class="facility__inner" >
													<div class="facility__item" v-for="item in convsList" :key="item.code" @click="onClickConvs(item.code)" :class="{'active': convs.indexOf(item.code) > -1}">
														<i class="facility__icon" v-if="item.img_url !== null && item.img_url.length > 0">
															<img :alt="item.code" :src="item.img_url" >
														</i>
														<span class="facility__text">
															{{item.code_nm}}
														</span>
													</div>
												</div>
											</swiper-slide>
										</swiper>
									</div>
									<div class="progress">
										<div class="progress__bar progress__bar--1"></div>
									</div>
								</div>
							</article>
							<!-- 필터 가격 -->
							<article class="filter__item" style="margin-top:40px">
								<h4 class="filter__title">{{t('10671')}}</h4>
								<div class="filter__price">
									<Slider v-model="price" :format="format" :min=1 :max=51 class="filter__priceblue" @change="valueToText"/>
								</div>
								<div class="filter__input">
									<input readonly type="text" class="ipt-filter" :value="price[0] > 50 ? '500,000 ~' : valueToText(price[0])">
									<input readonly type="text" class="ipt-filter" :value="price[1] > 50 ? '500,000 ~' : valueToText(price[1])">
								</div>
							</article>
							
							<!-- navigation -->
							<div id="navigation" class="navigation">
								<div class="navigation-button">
									<a class="btn btn-submit" @click="onClickSubmit(handleSubmit)">
										{{t('10672')}}
									</a>
									<ul class="item__menu">
										<li>
											<a>
												<i class="icon icon-refresh" @click="onClickReset">{{t('10673')}}</i>
											</a>
										</li>
									</ul>
								</div>
							</div>
							<!-- //navigation -->
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex"
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { onMounted, computed, ref } from "vue"
import Slider from '@vueform/slider'
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ['handleClose', 'handleSubmit'], 
	components: {
		Slider,
	},
	setup(props) {
		const store = useStore();
		const route = useRoute();
		const img_error = ref("N");
    const mcd00 = computed(() => store.state.mcd00.mcd00);
		const typeList = computed(()=> (mcd00.value["02"] ?? []));
		const filterItems = computed(() => store.state.filter.filterItems);

		const themeList = computed(()=> typeList.value.filter(item => item.code_grp === "03"));
		const convsList = computed(()=> typeList.value.filter(item => (item.code_grp === "01" || item.code_grp === "02") && item.grp_nm === "인기시설"));
		const convsChunkList = computed(()=> convsList.value.reduce((all,one,i) => {const ch = Math.floor(i/4); all[ch] = [].concat((all[ch]||[]),one); return all}, []));

		const theme = ref(filterItems.value.theme !== undefined && filterItems.value.route === route.fullPath ? [...filterItems.value.theme] : []);
		const convs = ref(filterItems.value.convs !== undefined && filterItems.value.route === route.fullPath ? [...filterItems.value.convs] : []);
		const price = ref(filterItems.value.price !== undefined && filterItems.value.route === route.fullPath ? [...filterItems.value.price] : [1, 51]);
        const { t }= useI18n() 

		const onClickTheme = (code) => {
			const index = theme.value.indexOf(code);
			if (index > -1) {
				theme.value.splice(index, 1);
			} else {
				theme.value.push(code);
			}
		}
		const replaceByDefault = (e) => {
    	   e.target.classList.add('noimage')
		   if(e.target.classList.contain('noimage')){
				e.target.src = '@/asset/images/noimage.png'
		   }
    	}
		const onClickConvs = (code) => {
			const index = convs.value.indexOf(code);
			if (index > -1) {
				convs.value.splice(index, 1);
			} else {
				convs.value.push(code);
			}
		}

		function onClickSubmit(handleSubmit) {
			store.commit("filter/SET_FILTER_ITEMS", {
				route: route.fullPath,
				theme: theme.value,
				convs: convs.value,
				price: price.value,
			});

			handleSubmit([...convs.value, ...theme.value], price.value);
		}

		const onClickReset = () => {
			theme.value = [];
			convs.value = [];
			price.value = [1, 51];

			store.commit("filter/SET_FILTER_ITEMS", {
				route: route.fullPath,
				theme: [],
				convs: [],
				price: [1, 51],
			});
		}

		onMounted(()=>{
			if(!("02" in mcd00.value)){ 
				store.dispatch("mcd00/fetchMcd00", {
				proc_cd: "02"
				});
			}
			document.getElementsByClassName('noimage').src = "@/asset/images/noimage.png"
		})

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

		return {
			themeList,
			convsChunkList,
			onClickSubmit,
			price,
			theme,
			convs,
			replaceByDefault,
			typeList,
			onClickTheme,
			img_error,
			onClickConvs,
			onClickReset,
			t,  //번역필수 모듈
		  i18n, 
			format: (value) => {
				return value > 50? "50만원 이상" : `${Math.round(value)}만원`;
			},
			valueToText(value) {
				return (value * 10000).toLocaleString("en-US");
			},
		}
	},
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
/*Filter Style*/
.filter__priceblue {
	--slider-connect-bg:var(--blue);
	--slider-tooltip-bg:#007DFF;
	--slider-handle-ring-color:var(--grey4);
	--slider-height:3px;
	--slider-bg:var(--grey4);
}
.filter__priceblue .slider-tooltip {border-color:var(--blue);background:var(--blue)}
.filter__priceblue .slider-tooltip-top:before {content:none}
.filter__priceblue .slider-tooltip-top {bottom:auto;top:calc(var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px))}
.filter__priceblue .slider-tooltip {font-size:12px;font-weight:400}
</style>