//import api from "@/urls/mmy02";
import api from "@/urls/urls_all";

const state = {
  mmy0201: [],
  mmy020102: [],
  mmy020109: [],
  mmy0202: {},
};

const getters = {};

const mutations = {
  SET_MMY0201(state, { mmy0201 }) {
    state.mmy0201 = mmy0201;
  },
  SET_MMY020102(state, { mmy020102 }) {
    state.mmy020102 = mmy020102;
  },
  SET_MMY0202(state, { mmy0202, booking_no }) {
    if (mmy0202.length > 0) state.mmy0202[booking_no] = mmy0202;
  },
  SET_MMY020109(state, { mmy020109 }) {
    state.mmy020109 = mmy020109;
  },
};

const actions = {
  async fetchMmy0201({ commit }, { proc_cd, mem_id }) {
    const data = await api.getMmy0201({ proc_cd, mem_id });
    commit("SET_MMY0201", { mmy0201: data.data.Res_data });
  },
  async fetchMmy020102({ commit }, { proc_cd, mem_id }) {
    const data = await api.getMmy0201({ proc_cd, mem_id });
    commit("SET_MMY020102", { mmy020102: data.data.Res_data });
  },
  async fetchMmy020109({ commit }, { mem_id }) {
    const data = await api.getMmy0201({ proc_cd: "09", mem_id });
    commit("SET_MMY020109", { mmy020109: data.data.Res_data });
  },
  async fetchMmy0202({ commit }, { proc_cd, mem_id, booking_no }) {
    const data = await api.getMmy0202({ proc_cd, mem_id, booking_no });
    commit("SET_MMY0202", { mmy0202: data.data.Res_data, booking_no });

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
