<template>
	<footer id="footer" class="footer">
		<div class="footer__wrap">
			<header class="footer__header">
				<div class="footer__logo">
					<!-- <img src="@/assets/images/logo.png" alt="H_SALAD"> -->
				</div>
				<div class="footer__button">
					<!-- [D] 클릭시 active 클래스 추가 -->
					<button @click="showCompanyInfo" type="button" :class="!isShowCompanyInfo ? '' : 'active'" class="btn-company">
						{{t('10840')}}
						<i class="icon icon-down icon-down--light"></i>
					</button>
				</div>
			</header>
			<div class="footer__body" v-if="mhm00.length > 0">
      		<div class="footer__notice" v-if="isShowCompanyInfo">
					{{mhm00[0].BIZ_NM}}
					<br/>
					{{t('10841')}} : {{mhm00[0].REP_NM}}
					<br/>
					{{t('10842')}} : {{mhm00[0].BIZ_NO}}
					<br/>
					{{t('10843')}} : {{mhm00[0].COMMSALES_NO}}
					<br/>
					{{t('10844')}} : {{mhm00[0].ADDRESS}}
					<br/>
					{{t('10845')}} : {{mhm00[0].CS_TEL}}
					<br/>
					{{t('10846')}} : {{mhm00[0].OP_TIME}}
					<br/>
					{{t('10847')}} : {{mhm00[0].EMAIL}}
					<br/>
					{{t('10848')}}: {{mhm00[0].HOSTING}}
				</div>
				<div class="footer__menu">
					<router-link to="/layers/terms/service">{{t('10849')}}</router-link>
					<span class="footer__dot"></span>
					<router-link to="/layers/terms/private" style="font-weight:bold">{{t('10850')}}</router-link>
					<span class="footer__dot"></span>
					<router-link to="/layers/terms/location">{{t('10851')}}</router-link><br>
					<router-link to="/layers/terms/youth">{{t('10852')}}</router-link>
					<span class="footer__dot"></span>
					<router-link to="/customer/partnership">{{t('10853')}}</router-link>
				</div>
				<div class="footer__notice" v-html="t('10854')">
					
				</div>
				<div class="footer__copyright">
					COPYRIGHT © 2022 HELLOSTAY. ALL RIGHT RESERVED.
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"

import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup: function() {
		const store = useStore();

        const { t }= useI18n() //번역필수 모듈

		const isShowCompanyInfo = ref(false);
		const isShowTopButton = ref(false);

		const mhm00 = computed(() => store.state.mhm00.mhm00);
		const mcs0105 = computed(() => store.state.mcs01.mcs01["05"] ?? []);

		const showCompanyInfo = ()=>{
      isShowCompanyInfo.value = !isShowCompanyInfo.value;
		}
		
		onMounted(() => {
			store.dispatch("mhm00/fetchMhm00");
			store.dispatch("mcs01/fetchMcs01", {
				proc_cd : "05"
			});
		});
		
		return {
			mhm00,
			mcs0105,
			t,  //번역필수 모듈
			i18n,
			isShowCompanyInfo,
			showCompanyInfo,
			isShowTopButton,
			goTop(duration) {
				// cancel if already on top
				if (document.scrollingElement.scrollTop === 0) return;

				const cosParameter = document.scrollingElement.scrollTop / 2;
				let scrollCount = 0, oldTimestamp = null;

				function step (newTimestamp) {
					if (oldTimestamp !== null) {
						// if duration is 0 scrollCount will be Infinity
						scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
						if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
						document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
					}
					oldTimestamp = newTimestamp;
					window.requestAnimationFrame(step);
				}
				window.requestAnimationFrame(step);
			}
		}
	}
};
</script>
