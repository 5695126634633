//import api from "@/urls/mrm01";
import api from "@/urls/urls_all";

const state = {
  mrm0101: [],
  alleventList: [],
  eventList: [],
  planList: [],
  menuEventList: [],
  operatorRecommend: [],
  popularRecommend: [],
  eventListLoading: true,
  planListLoading: true,
  alleventListLoading: true,
  popularRecommendLoading: true,
  locationRecommend: {},
  mrm0104: [],
  mrm0105: [],
  mrm01051: [],
  mrm01011: [],
};

const getters = {};

const mutations = {
  SET_MRM0101(state, { mrm0101 }) {
    state.mrm0101 = mrm0101;
  },
  SET_ALLEVENT_LIST(state, { mrm0103 }) {
    state.alleventList = mrm0103;
    state.alleventListLoading = false;
  },
  SET_EVENT_LIST(state, { mrm0103 }) {
    state.eventList = mrm0103;
    state.eventListLoading = false;
  },
  SET_PLAN_LIST(state, { mrm0103 }) {
    state.planList = mrm0103;
    state.planListLoading = false;
  },
  SET_MENU_EVENT_LIST(state, { mrm0103 }) {
    state.menuEventList = mrm0103;
  },
  SET_OPERATOR_RECOMMEND(state, { mrm0102 }) {
    state.operatorRecommend = mrm0102;
  },
  SET_POPULAR_RECOMMEND(state, { mrm0102 }) {
    state.popularRecommend = mrm0102;
    state.popularRecommendLoading = false;
  },
  SET_LOCATION_RECOMMEND(state, { mrm0102, area_cd }) {
    state.locationRecommend[area_cd] = mrm0102;
  },
  SET_MRM0104(state, { mrm0104 }) {
    state.mrm0104 = mrm0104;
  },
  SET_MRM0105(state, { mrm0105 }) {
    state.mrm0105 = mrm0105;
  },
  SET_MRM01051(state, { mrm01051 }) {
    state.mrm01051 = mrm01051;
  },
  SET_MRM01011(state, { mrm01011 }) {
    state.mrm01011 = mrm01011;
  },
};

const actions = {
  async fetchMrm0101(
    { commit },
    {
      arr_date,
      dep_date,
      area_cd,
      hotel_type,
      adult_cnt,
      child_cnt,
      room_cnt,
      cp_yn,
      mem_id,
      S_SALES_YN,
      D_SALES_YN 
    }
  ) {
    const data = await api.getMrm0101({
      sdate: arr_date,
      edate: dep_date,
      area_cd,
      hotel_type,
      adult_cnt,
      child_cnt,
      room_cnt,
      cp_yn,
      mem_id,
      S_SALES_YN,
      D_SALES_YN 
    });
    commit("SET_MRM0101", { mrm0101: data.data.Res_data });
  },
  async fetchallEventList({ commit }) {
    const data = await api.getMrm0103({
      proc_cd: "00",
    });
    commit("SET_ALLEVENT_LIST", { mrm0103: data.data.Res_data });
  },
  async fetchEventList({ commit }) {
    const data = await api.getMrm0103({
      proc_cd: "01",
    });
    commit("SET_EVENT_LIST", { mrm0103: data.data.Res_data });
  },
  async fetchPlanList({ commit }) {
    const data = await api.getMrm0103({
      proc_cd: "02",
    });
    commit("SET_PLAN_LIST", { mrm0103: data.data.Res_data });
  },
  async fetchMenuEventList({ commit }) {
    const data = await api.getMrm0103({
      proc_cd: "03",
    });
    commit("SET_MENU_EVENT_LIST", { mrm0103: data.data.Res_data });
  },
  async fetchOperatorRecommend({ commit }, { area_cd, lati, longi }) {
    const data = await api.getMrm0102({
      proc_cd: "04 ",
      area_cd,
      lati,
      longi,
    });
    commit("SET_OPERATOR_RECOMMEND", { mrm0102: data.data.Res_data });
  },
  async fetchPopularRecommend({ commit }) {
    const data = await api.getMrm0102({
      proc_cd: "02",
    });
    commit("SET_POPULAR_RECOMMEND", { mrm0102: data.data.Res_data });
  },
  async fetchLocationRecommend({ commit }, { area_cd }) {
    const data = await api.getMrm0102({
      proc_cd: "03",
      area_cd,
    });
    commit("SET_LOCATION_RECOMMEND", { mrm0102: data.data.Res_data, area_cd });
  },
  async fetchMrm0104({ commit }) {
    const data = await api.getMrm0104();
    commit("SET_MRM0104", { mrm0104: data.data.Res_data });
  },
  async fetchMrm0105({ commit }, { hotel_type, latitude, longitude }) {
    const data = await api.getMrm0105({
      proc_cd: "01",
      hotel_type,
      latitude,
      longitude,
    });
    commit("SET_MRM0105", { mrm0105: data.data.Res_data });
  },
  async fetchMrm01051({ commit }, params) {
    const data = await api.getMrm01051(params);
    commit("SET_MRM01051", { mrm01051: data.data.Res_data });
  },
  async fetchMrm01011({ commit }, params) {
    const data = await api.getMrm01011(params);
    commit("SET_MRM01011", { mrm01011: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
