// import api from "@/urls/mcs01";
import api from "@/urls/urls_all";
const state = {
  mcs01: {},
  mcs01_00: [],
  mcs01_01: [],
  mcs01_02: [],
  mcs01_03: [],
  mcs01_04: [],
  mcs01_05: [],
  mcs0101: [],
  mcs0102: { "01": {}, "02": {} },
  mcs0104: [],
};

const getters = {};

const mutations = {
  SET_MCS01(state, { mcs01_00, proc_cd }) {
    state.mcs01[proc_cd] = mcs01_00;
  },
  SET_MCS0101(state, { mcs0101 }) {
    state.mcs0101 = mcs0101;
  },
  SET_MCS0102(state, { mcs0102, proc_cd, type }) {
    if (proc_cd === "02") {
      if (state.mcs0102[proc_cd] === undefined) {
        state.mcs0102[proc_cd] = {};
      }
      state.mcs0102[proc_cd][type] = mcs0102;
    } else {
      state.mcs0102[proc_cd] = mcs0102;
    }
  },
  SET_MCS0104(state, { mcs0104 }) {
    state.mcs0104 = mcs0104;
  },
};

const actions = {
  async fetchMcs01({ commit }, { proc_cd }) {
    const data = await api.getMcs01({ proc_cd: proc_cd });
    commit("SET_MCS01", { mcs01_00: data.data.Res_data, proc_cd: proc_cd });
  },
  async fetchMcs0101({ commit }, params) {
    const data = await api.getMcs0101(params);
    commit("SET_MCS0101", { mcs0101: data.data.Res_data });
  },
  async fetchMcs0102({ commit }, { proc_cd, type }) {
    const data = await api.getMcs0102({ proc_cd: proc_cd, type: type });
    commit("SET_MCS0102", {
      mcs0102: data.data.Res_data,
      proc_cd: proc_cd,
      type: type,
    });

    return data;
  },
  async fetchMcs0104({ commit }) {
    const data = await api.getMcs0104({ proc_cd: "01" });
    commit("SET_MCS0104", { mcs0104: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
