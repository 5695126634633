import axios from "axios";

const ENDPOINT = "https://admin.hellostay.co.kr"

    
const fetch = (method, pathFunction) => (data) => {
  const formData = new FormData();
  if (method === "POST") {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    });
    formData.append("sitecd", "B2C");
    formData.append("authcd", "b2c0x43274h2h");
  }

  return axios({
    method,
    url: ENDPOINT + pathFunction(data),
    data: method !== "GET" && method !== "get" ? formData : undefined,
    params:
      method === "GET" || method === "get"
        ? { sitecd: "B2C", authcd: "b2c0x43274h2h", ...data }
        : undefined,
  });
};

export default fetch;
