<template>
	<div class="layer menu-background-anim" style="display:block;z-index: 999">
        <div class="layer__dim menu-background-anim"></div>
        <section class="menu menu-anim">
            <!-- menu-side -->
            <div class="menu__side menu-background-anim" @click="handleClose()">
                <button type="button" class="menu__btn">
                    <i class="icon icon-close--white"></i>
                </button>
            </div>
            <!-- //menu-side -->
            <div class="menu__wrap">
                <!-- menu__banner -->
                <div class="menu__banner noneimage" v-if="mev0104.length > 0">
                    <router-link :to="'/event/event_detail/'+ mev0104[0].EV_NO">
                        <div class="imagebg" :style="{'background-image': 'url(\'' + mev0104[0].EVENT_PIC1 + '\')'}" />
                    </router-link>
                </div>
                <!-- //menu__banner -->
                <!-- menu__inner -->
                <div class="menu__inner">
                    <div class="menu__inbox">
                        <ul>
                            <li @click="selectMenu('숙박')" :class="{active: selectedMenu === '숙박'}"><a>{{t('10002')}}</a></li>
                            <!-- <router-link to="/feed" custom v-slot="{ navigate }"><li @click="navigate" :class="{active: selectedMenu === '피드'}"><a>{{t('10002')}}</a></li></router-link> -->
                            <router-link to="/event" custom v-slot="{ navigate }"><li @click="navigate" :class="{active: selectedMenu === '이벤트'}"><a>{{t('10683')}}</a></li></router-link>
                            <li @click="selectMenu('고객센터')" :class="{active: selectedMenu === '고객센터'}"><a>{{t('10684')}}</a></li>
                        </ul>
				        <div class="login_btn" v-if="isLogin" @click="onClickLogout">{{t('10421')}}</div>
				        <div class="login_btn"  v-else><a href="/layers/login">{{t('10685')}}</a></div>
                    </div>
                    <div class="menu__inbox menu__inbox--right">
                        <!-- [D] 활성화된 메뉴에 active 클래스 추가 -->
                        <ul class="menu__gnb" :class="{active: selectedMenu === '숙박'}">
                            <router-link v-for="item in typeList" :key="item.code" :to="item.grp_nm" custom v-slot="{ navigate }"><li @click="navigate"><a>{{t(item.code)}}</a></li></router-link>
                        </ul>
                        <ul class="menu__gnb" :class="{active: selectedMenu === '고객센터'}">
                            <router-link to="/customer/notice" custom v-slot="{ navigate }"><li @click="navigate"><a>{{t('10032')}}</a></li></router-link>
                            <router-link to="/customer/faq" custom v-slot="{ navigate }"><li @click="navigate"><a>{{t('10031')}}</a></li></router-link>
                            <router-link to="/customer/partnership" custom v-slot="{ navigate }"><li @click="navigate"><a>{{t('10034')}}</a></li></router-link>
                            <router-link to="/customer/terms" custom v-slot="{ navigate }"><li @click="navigate"><a>{{t('10042')}}</a></li></router-link>
                        </ul>
                    </div>
                </div>
                <!-- //menu__inner -->
            </div>
        </section>
	</div>
</template>

<script>
import { computed, onMounted, ref, watchEffect} from 'vue'
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
    props: ['handleClose'], 
    setup() {
        const store = useStore();
        const route = useRoute();
		const router = useRouter();
		const pushError = ref(false);
        const mev0104 = computed(() => store.state.mev01.mev0104);
        const userData = computed(() => store.state.userData);
        const isLogin = computed(() => Object.keys(userData.value).length !== 0);
        const selectedMenu = ref("숙박");
        const { t }= useI18n() 
        const selectMenu = (menu) => {
            selectedMenu.value = menu;
        }
        const mcd00 = computed(() => store.state.mcd00.mcd00);
        const typeList = computed(()=> (mcd00.value["R03"] ?? []));

        watchEffect(() => {
          if(route.path.indexOf("rooms") !== -1) {
            selectedMenu.value = "숙박";  
          }else if(route.path.indexOf("feed") !== -1) {
            selectedMenu.value = "피드";  
          }
          else if(route.path.indexOf("event") !== -1) {
              selectedMenu.value = "이벤트"; 
          }else if(route.path.indexOf("customer") !== -1) {
              selectedMenu.value = "고객센터"; 
          }
        })

        onMounted(() => {
            store.dispatch("mrm01/fetchMenuEventList");
            if(!("R03" in mcd00.value)){ 
                store.dispatch("mcd00/fetchMcd00", {
                    code_grp: "R03"
                });
            }
            store.dispatch("mev01/fetchMev0104",{
				proc_cd: "00",
				evNo: ""
			});
        })
        

	    const onClickLogout = () => {
            store.commit("SET_USERDATA", {userData: {}});
			store.commit('mhm01/RESET_ALL');
            router.go();
		}

		onMounted(()=>{
      try {
      	store.dispatch('app/requestPushOnOff');
      } catch(e) {
				pushError.value = true;
      }
		});

        return {
            selectedMenu,
            selectMenu,
            isLogin,
            userData,
			onClickLogout,
			pushError,
            mev0104,
            t,  //번역필수 모듈
		  i18n, 
            menuEventList: computed(() => store.state.mrm01.menuEventList),
            typeList
        }
    },
}
</script>