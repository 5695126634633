import api from "@/urls/common";

const state = {
  cd086: [],
  ta111: [],
};

const getters = {};

const mutations = {
  SET_CD086(state, { cd086 }) {
    state.cd086 = cd086;
  },
  SET_TA111(state, { ta111 }) {
    state.ta111 = ta111;
  },
};

const actions = {
  async fetchCd086({ commit }) {
    const data = await api.getCd086({});
    commit("SET_CD086", { cd086: data.data.Res_data });
  },
  async fetchta111({ commit }) {
    const data = await api.getTa111({});
    commit("SET_TA111", { TA_111: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
