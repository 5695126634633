export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중국어"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일본어"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박"])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모텔"])},
  "HO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호텔"])},
  "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펜션"])},
  "RT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리조트"])},
  "CP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠핑"])},
  "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게하"])},
  "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글램핑"])},
  "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강남 논현 삼성"])},
  "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박"])},
  "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레저/티켓"])},
  "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["액티비티"])},
  "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마파크"])},
  "10006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공연/전시"])},
  "10007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워터파크/스파"])},
  "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단골쿠폰할인 가능한 숙소"])},
  "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정렬"])},
  "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1만원 할인쿠폰"])},
  "10011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다이나믹 트램폴린 체험!"])},
  "10012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[서울] 역삼 바운스 트램폴린"])},
  "10013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 위치로부터 15km"])},
  "10014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당일사용가능 미사용 전액 환불"])},
  "10015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콕"])},
  "10016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울 논현동"])},
  "10017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
  "10018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변에 레저·티켓이 없어요"])},
  "10019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 레저·티켓을 만나고  레저·티켓을 구매해 보세요!"])},
  "10020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레저·티켓 보러가기"])},
  "10021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모텔"])},
  "10022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호텔"])},
  "10023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펜션"])},
  "10024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펜션·리조트"])},
  "10025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캠핑/글램핑"])},
  "10026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변에 숙소가 없어요"])},
  "10027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 숙소를 만나고  숙소를 예약해 보세요!"])},
  "10028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 보러가기"])},
  "10029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게하"])},
  "10030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 페이지"])},
  "10031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주하는 질문"])},
  "10032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
  "10033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 공지사항이 없어요"])},
  "10034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴문의"])},
  "10035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "10036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폼 리셋"])},
  "10037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 번호"])},
  "10038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
  "10039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의내용"])},
  "10040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 수집 및 이용 동의 (필수)"])},
  "10041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수집하는 개인정보 항목 : 이름, 연락처, 이메일 주소 작성해주시는 개인정보는 문의 접수 및 고객 불만 해결을 위해 5년간 보관됩니다."])},
  "10042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약관 및 정책"])},
  "10043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용약관"])},
  "10044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 처리방침"])},
  "10045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치정보 이용약관"])},
  "10046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청소년 보호정책"])},
  "10047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 기획전 "])},
  "10048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대한민국 숙박대전 사전인증 이벤트"])},
  "10049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 숙소"])},
  "10050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1만원 할인쿠폰"])},
  "10051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선착순 할인쿠폰"])},
  "10052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환상적인 루프탑이 있을까요? 없을까요?"])},
  "10053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전주 한옥에코빌리지 펜션"])},
  "10054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["역삼역 1번 출구에서 145m"])},
  "10055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 대실 5시간 객실 보유"])},
  "10056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실 5시간"])},
  "10057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 18시부터"])},
  "10058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대"])},
  "10059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
  "10060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["셀프체크인"])},
  "10061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단골쿠폰"])},
  "10062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
  "10063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주중 무한대실 이벤트! 최장 10시간"])},
  "10064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰"])},
  "10065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
  "10066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기획전"])},
  "10067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 진행중인 이벤트가 없어요"])},
  "10068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 진행중인 기획전이 없어요"])},
  "10069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행 취향 입력 완료!"])},
  "10070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님의 취향을 저격하는 여행지를 추천해 드릴게요"])},
  "10071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 숙소 예약하러 가기"])},
  "10072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어떤 여행지를 좋아하세요?"])},
  "10073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좋아하는 여행지를 3개까지 픽!"])},
  "10074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행취향을 알려주세요!"])},
  "10075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 취향을 5개까지 픽!"])},
  "10076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누구와 최소 1개 선택"])},
  "10077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행스타일 최소 1개 선택"])},
  "10078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
  "10079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변 예약"])},
  "10080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좋아요"])},
  "10081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 상세"])},
  "10082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비회원 예약 조회"])},
  "10083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약일시 :"])},
  "10084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품 정보"])},
  "10085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통합예약번호"])},
  "10086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약번호"])},
  "10087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소"])},
  "10088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원"])},
  "10089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["까지 무료 취소 가능"])},
  "10090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약자 정보"])},
  "10091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "10092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 번호"])},
  "10093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용자 정보"])},
  "10094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소방문수단"])},
  "10095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액 및 결제 정보"])},
  "10096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제수단"])},
  "10097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약금액"])},
  "10098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰"])},
  "10099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
  "10100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제휴할인"])},
  "10101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제금액"])},
  "10102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약내역이 없어요"])},
  "10103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약정보를 다시 확인해 주세요"])},
  "10104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비회원 예약조회"])},
  "10105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 시 전달받은 예약번호와 휴대폰 번호를 입력해 주세요."])},
  "10106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 예약번호 입니다. 다시 확인해 주세요."])},
  "10107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약조회"])},
  "10108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 완료!"])},
  "10109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 숙소 예약하러 가기"])},
  "10110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행 취향 입력하기"])},
  "10111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래 내용을 확인하고 여행을 준비해볼까요"])},
  "10112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체동의"])},
  "10113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만14세 이상 사용 (필수)"])},
  "10114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용약관 (필수)"])},
  "10115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기"])},
  "10116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 수집 및 이용동의 (필수)"])},
  "10117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치 정보 이용약관 (선택)"])},
  "10118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["혜택 알림 수신 동의 (선택)"])},
  "10119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음으로"])},
  "10120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 인증"])},
  "10121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원활한 서비스 제공을 위해 휴대폰 번호를 입력해 주세요."])},
  "10122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증요청"])},
  "10123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인인증"])},
  "10124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 번호"])},
  "10125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송"])},
  "10126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 번호가 일치하지 않습니다."])},
  "10127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인인증 완료"])},
  "10128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보 입력"])},
  "10129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
  "10130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복확인"])},
  "10131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 가입된 아이디(이메일)입니다"])},
  "10132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
  "10133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영문포함"])},
  "10134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자포함"])},
  "10135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8~20자 이내"])},
  "10136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
  "10137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 일치"])},
  "10138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
  "10139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복확인"])},
  "10140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2~6자 이내"])},
  "10141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용중인 닉네임입니다"])},
  "10142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천인 코드"])},
  "10143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
  "10144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천인 코드가 일치하지 않습니다"])},
  "10145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입완료"])},
  "10146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패러글라이딩 체험비행 명가"])},
  "10147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[충북] 단양 패러글라이딩"])},
  "10148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17명이 픽한 상품입니다"])},
  "10149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효기간 : 구매일로부터 60일"])},
  "10150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당일사용가능 미사용 전액 환불"])},
  "10151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세정보"])},
  "10152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액 및 할인 정보"])},
  "10153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품 사용 안내"])},
  "10154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 구매 (최소 1일전 구매필수)  유선 예약확인 필수 (010-0000-0000)  예약한 날짜에 이용하기"])},
  "10155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유의 사항 안내"])},
  "10157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용후기"])},
  "10158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반실(A타입) - 숙박"])},
  "10159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임A"])},
  "10160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["침대가 정말 크고 편안해서 잘 쉬었습니다.  분위기도 좋고 시설도 좋아요. 잘 쉬다 갑니다.  다음 휴가때도 오려구요~"])},
  "10161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변정보"])},
  "10162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변 레저·티켓"])},
  "10163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[서울 영등포] 여의도 한강공원  전동킥보드 자유이용권 최저가"])},
  "10164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박"])},
  "10165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변 숙소"])},
  "10166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["킹덤호텔"])},
  "10167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호텔 오클라우드"])},
  "10168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
  "10169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충북전체"])},
  "10170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단골쿠폰할인 가능한 숙소"])},
  "10171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마파크"])},
  "10172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공연·전시"])},
  "10173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["워터파크·<br>스파"])},
  "10174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축제·행사"])},
  "10175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교통"])},
  "10176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마A"])},
  "10177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마B"])},
  "10178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 레저·티켓"])},
  "10179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불금은 다같이 맥주파티!"])},
  "10180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[서울] 한강 맥주파티"])},
  "10181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주의 낭만을 즐기며"])},
  "10182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[제주] 차박 대여 세트"])},
  "10183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
  "10184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비발디파크 리프트권 단독특가"])},
  "10185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기간 한정 최대 57% 할인"])},
  "10186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신라스테이 호텔 브랜드 특가 이벤트"])},
  "10187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MD 강력추천 가을 숙소"])},
  "10188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주간 인기 레저·티켓"])},
  "10189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가족과 함께 즐기기 좋은"])},
  "10190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[강원] 대관령 양떼목장"])},
  "10191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환상적인 루프탑이 있어요"])},
  "10192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["역삼 벤"])},
  "10193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 놀기 좋은 지역은?"])},
  "10194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울"])},
  "10195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부산"])},
  "10196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주"])},
  "10197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강원"])},
  "10198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경북"])},
  "10199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경남"])},
  "10200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전남"])},
  "10201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환상적인 예술작품이 가득한"])},
  "10202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[부산] 뮤지엄 DAH"])},
  "10203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기획전"])},
  "10204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기획전"])},
  "10205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["겨울은 스키·보드의 계절!"])},
  "10206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초보자도 부담없이 즐기는!"])},
  "10207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[평창] 대원리조트 스키강습"])},
  "10208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 플레이스"])},
  "10209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소울 플레이스, 소울 맛집을 소개합니다."])},
  "10210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내 감성 풀빌라 베스트 3"])},
  "10211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴면 회원 해제"])},
  "10212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장기 미 접속으로 인해 휴면회원 전환 상태입니다."])},
  "10213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최초 가입일"])},
  "10214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최종 접속일"])},
  "10215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴면처리일"])},
  "10217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송"])},
  "10218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴면 상태 해제"])},
  "10219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴면 회원 해제 완료"])},
  "10220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정상적으로 <span class='red'>휴면해제</span> 처리 되었습니다."])},
  "10221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인인증 방법 선택"])},
  "10222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증"])},
  "10223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS 인증"])},
  "10224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 보내기"])},
  "10225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 찾기 완료"])},
  "10226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님이 가입한 아이디(이메일) 정보입니다."])},
  "10227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 회원입니다."])},
  "10228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간편 로그인을 진행하세요."])},
  "10229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 찾기"])},
  "10230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 하기"])},
  "10231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 찾기"])},
  "10232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경 완료"])},
  "10233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경이 완료되었습니다.  로그인 페이지로 이동합니다."])},
  "10234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 확인 후 휴대폰 본인 인증을 통해  비밀번호를 다시 설정합니다."])},
  "10235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
  "10236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 비밀번호"])},
  "10237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 비밀번호 확인"])},
  "10238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴 처리가 완료되었습니다"])},
  "10240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈으로"])},
  "10241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴 시 주의사항"])},
  "10242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 사용중인 계정 정보는 모두 삭제됩니다."])},
  "10244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 및 모든 혜택이 소멸됩니다."])},
  "10246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위 내용을 모두 숙지했고, 탈퇴에 동의합니다."])},
  "10247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아쉽지만, 이별의 시간인가요.."])},
  "10248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그동안 저희서비스를 즐겨주셔서 감사합니다. 더 나은 서비스를 제공하기 위해 떠나시는 이유를 알려주세요."])},
  "10249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출"])},
  "10250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
  "10251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급 별 혜택 안내"])},
  "10252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급 별 달라지는 혜택을 확인해 보세요."])},
  "10253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반"])},
  "10254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반회원"])},
  "10255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규가입 한 모든 회원"])},
  "10256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP회원"])},
  "10257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전년도 이용금액 50만원 이상 AND 구매 건수 10건 이상"])},
  "10258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 20%할인 혜택 (총 2회)"])},
  "10259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 예약내역"])},
  "10260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문 전"])},
  "10261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문 후"])},
  "10262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 취소"])},
  "10263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소완료"])},
  "10264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약번호 R1234"])},
  "10265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상세내역"])},
  "10266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종로 레몬트리"])},
  "10267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 디럭스"])},
  "10268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
  "10269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화"])},
  "10270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크인"])},
  "10271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크아웃"])},
  "10272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용완료"])},
  "10273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 예약하기"])},
  "10274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리뷰 작성하기"])},
  "10275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하신 숙소 내역이 없어요"])},
  "10276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약취소"])},
  "10277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품정보"])},
  "10278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약번호 :"])},
  "10279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크인"])},
  "10280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체크아웃"])},
  "10281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소정보"])},
  "10282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품금액"])},
  "10283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트사용"])},
  "10284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실 결제 금액"])},
  "10285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상취소 수수료 0원"])},
  "10286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상 환불 금액 0원"])},
  "10287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위 내용을 모두 확인하였습니다."])},
  "10288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소하기"])},
  "10289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니"])},
  "10290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체선택"])},
  "10291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택삭제"])},
  "10292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준"])},
  "10293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 시간 예약 마감"])},
  "10294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총  건"])},
  "10295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약하기"])},
  "10296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담긴  상품이 없어요"])},
  "10297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 숙소를 한번에 예약해 보세요!"])},
  "10298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품 보러가기"])},
  "10299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰함"])},
  "10300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님의 사용가능한 쿠폰"])},
  "10301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 쿠폰 장"])},
  "10302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용가능"])},
  "10303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용완료"])},
  "10304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% 할인쿠폰"])},
  "10305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원 할인쿠폰"])},
  "10306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 최대 원 할인"])},
  "10307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일까지"])},
  "10308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 종료시까지 계속"])},
  "10309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 내역이 없어요"])},
  "10310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 발급 이벤트에 참여하고 쿠폰을 받으실 수 있습니다."])},
  "10311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기많은만두님의 사용가능한 쿠폰"])},
  "10312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 등록하기"])},
  "10313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입축하 쿠폰"])},
  "10314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% 할인 쿠폰"])},
  "10315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 기념 최대 1만원 할인"])},
  "10316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.11.19일까지 (D-3)"])},
  "10317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박대전 쿠폰"])},
  "10318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP 쿠폰"])},
  "10319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보 수정"])},
  "10320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 변경"])},
  "10321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경"])},
  "10322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 변경"])},
  "10323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 닉네임 입니다."])},
  "10324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 일치하지 않습니다."])},
  "10325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴"])},
  "10326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 문의내역"])},
  "10327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변"])},
  "10328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의사항이 접수되었습니다. 빠른 시일내에 답변드리겠습니다.  감사합니다."])},
  "10329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
  "10330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
  "10331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록으로"])},
  "10332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 건"])},
  "10333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 1:1문의가 없어요"])},
  "10334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 중 궁금하신 부분에 대해 문의 주시면  빠른 시일내에 답변 드리겠습니다."])},
  "10335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의유형"])},
  "10336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의내용"])},
  "10337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하기"])},
  "10338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레저·티켓 구매내역"])},
  "10339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 전"])},
  "10340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 후"])},
  "10341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 취소"])},
  "10342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소완료"])},
  "10343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효기간 :"])},
  "10344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2인, 식사포함"])},
  "10345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 구매하기"])},
  "10346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP 등급혜택"])},
  "10347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천코드 총 초대회원수 1명"])},
  "10348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트"])},
  "10349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 15장"])},
  "10350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프 0/5"])},
  "10351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박예약 내역 "])},
  "10352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레저/티켓 구매내역 "])},
  "10353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니 "])},
  "10354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 리뷰"])},
  "10355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 본 상품"])},
  "10356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행 취향 설정"])},
  "10357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행취향 입력하고 20% 쿠폰받기"])},
  "10358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1문의"])},
  "10359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환경설정"])},
  "10360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
  "10361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화상담"])},
  "10362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터 운영시간"])},
  "10363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연중무휴 10시 ~ 24시 (점심) 12시30분 ~ 13시30분"])},
  "10364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡 문의"])},
  "10365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡 고객센터 운영시간"])},
  "10366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연중무휴 10시 ~ 19시 (점심) 12시30분 ~ 13시30분"])},
  "10367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인/회원가입하기"])},
  "10368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입하면 2,000포인트 즉시 지급!"])},
  "10369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천인코드"])},
  "10370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 초대회원수 명"])},
  "10371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천인코드 입력하면 포인트 더블 적립!"])},
  "10372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 리뷰"])},
  "10373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 평가"])},
  "10374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접 이용해보니 어떠셨나요?"])},
  "10375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 위 내용은 해당 업체에게만 공개됩니다."])},
  "10376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진"])},
  "10377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성하신 리뷰 내역이 없어요"])},
  "10378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 숙소를 이용하고 <br> 리뷰를 작성해 보세요!"])},
  "10379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소하신 숙소 내역이 없어요"])},
  "10380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용하신 숙소 내역이 없어요"])},
  "10381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약하신 숙소 내역이 없어요"])},
  "10382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담긴 상품이 없어요"])},
  "10383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 쿠폰 내역이 없어요"])},
  "10384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의하기"])},
  "10385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소하신 레저·티켓이 없어요"])},
  "10386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용하신 레저·티켓 내역이 없어요"])},
  "10387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 레저·티켓 리스트를 만나고 지금 레저·티켓을 예약해보세요!"])},
  "10388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하신 레저·티켓 내역이 없어요"])},
  "10389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
  "10390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님의 사용가능한 포인트"])},
  "10391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔여 포인트 "])},
  "10392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7일"])},
  "10393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30일"])},
  "10394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90일"])},
  "10395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180일"])},
  "10396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립/사용 가능한 포인트 내역이 없어요"])},
  "10397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 가입 추천을 하면 포인트를 얻을 수 있어요"])},
  "10398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 추천인코드 :"])},
  "10399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 본 숙소가 없어요"])},
  "10400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 숙소를 만나고 지금 숙소를 예약해 보세요!"])},
  "10401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 본 레저·티켓이 없어요"])},
  "10402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 레저티켓을 만나고 지금 레저티켓을 구매해 보세요!"])},
  "10403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 초대한 회원"])},
  "10404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님이 초대한 회원수"])},
  "10405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내가 초대한 회원이 없어요"])},
  "10406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 초대하고 1000 포인트 받으세요!"])},
  "10407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용완료한 쿠폰 내역이 없어요"])},
  "10408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님의 소중한 정보를 안전하게 <br>변경하기 위해 <span class='blue'>비밀번호 확인</span>이 <br>필요합니다."])},
  "10409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립/사용일"])},
  "10410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 내역이 없어요"])},
  "10411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체삭제"])},
  "10412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[강원] 대관령 양떼 목장"])},
  "10413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울 강남구 대치동 891-35"])},
  "10414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약"])},
  "10415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입일"])},
  "10416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
  "10417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버전정보 V1.0"])},
  "10418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["푸쉬알림"])},
  "10419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접근 권한 설정"])},
  "10420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS 연결 설정"])},
  "10421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "10422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결할 아이디 선택"])},
  "10423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS계정을 연결할 아이디를 선택해주세요."])},
  "10424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS계정 연결하기"])},
  "10425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오 로그인"])},
  "10426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결하기"])},
  "10427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제하기"])},
  "10428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네이버 로그인"])},
  "10429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애플 로그인"])},
  "10430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프"])},
  "10431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프카드"])},
  "10432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립내역"])},
  "10433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님의 스탬프 현황   /5개"])},
  "10434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회"])},
  "10435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프 5회 적립 시 5000P 적립!"])},
  "10436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프 획득 방법"])},
  "10437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 퇴실 24시간 후, 스탬프 1회 자동 적립!"])},
  "10438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스탬프 유의사항 안내"])},
  "10439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 퇴실 이후 스탬프가 자동 적립됩니다 (적립 시점 : 퇴실일 기준 익일 00시 적용)"])},
  "10440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 스탬프 5회 적립 시 5000P 적립!"])},
  "10441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 숙박 상품 이용시에만 스탬프가 적립됩니다."])},
  "10442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. 지급된 포인트 유효기간은 180일 입니다."])},
  "10443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. 스탬프 정책은 상황에 따라 달라질 수 있습니다."])},
  "10444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적 적립스탬프 개"])},
  "10445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적 적립포인트 p"])},
  "10446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립일"])},
  "10447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적 적립스탬프 3,000개"])},
  "10448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품정보 건"])},
  "10449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원"])},
  "10450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["102(개별 수영장 + 복층)"])},
  "10451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021.12.13(월) 11:00 ~ 16:00"])},
  "10452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 후 할인 혜택 받으세요!"])},
  "10453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문자 정보"])},
  "10454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실제 방문하시는 분 정보 입력"])},
  "10455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약자 정보와 동일합니다."])},
  "10456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 방문 수단 "])},
  "10457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택하기"])},
  "10458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 방문 수단을 선택해 주세요."])},
  "10459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종로레몬트리"])},
  "10460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도보"])},
  "10461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량"])},
  "10462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경주빌라"])},
  "10463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주차가 불가능한 숙소입니다."])},
  "10464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액 및 할인 정보"])},
  "10465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인하고 할인 혜택 받으세요!"])},
  "10466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 결제금액"])},
  "10467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제수단 선택"])},
  "10468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용/체크카드"])},
  "10469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간편 계좌이체"])},
  "10470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["혜택"])},
  "10471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용카드"])},
  "10472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이코 결제 혜택"])},
  "10473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5만원 이상 결제시 5천원 즉시 할인!"])},
  "10474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소득공제"])},
  "10475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지출증빙"])},
  "10476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미발행"])},
  "10477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현금영수증 카드 번호"])},
  "10478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 또는 쿠폰 이용 시, 실제 현금성으로 결제 된 금액만 현금영수증이 발행됩니다."])},
  "10480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 결제 수단을 다음에도 사용"])},
  "10481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 불가 및 수수료"])},
  "10482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 및 환불규정에 따라 취소불가, 수수료가 발생할 수 있습니다."])},
  "10483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미성년자 및 법정대리인 필수"])},
  "10484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미성년자는 법정대리인 동행 없이 투숙이 불가능합니다."])},
  "10485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 전체 동의합니다"])},
  "10486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 상품 이용 규칙 동의 (필수)"])},
  "10487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소/환불 규정 동의 (필수)"])},
  "10488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 제 3자 제공 동의 (필수)"])},
  "10489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(주) 헬로스테이는 통신판매중개자로서, 통신판매의 당사자가 아니라는 사실을 고지하며, 상품의 예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다."])},
  "10490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원 결제하기"])},
  "10491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장"])},
  "10492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 변경하기"])},
  "10493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 번호는 안심번호로 변경되어 숙소에 전달됩니다. 단, 안심번호 처리가 어려운 경우에 한해 제한적으로 개인정보 제공 동의에 의거하여 실제 휴대폰번호가 전달 될 수 있습니다."])},
  "10494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 0원 할인"])},
  "10495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 원 할인"])},
  "10496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P 보유"])},
  "10497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특별 할인 혜택"])},
  "10498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대한민국 예비, 전, 현역 병사/간부 인증 시 할인!"])},
  "10499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나라사랑회원 10%할인"])},
  "10500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미발행을 선택하신 경우에도 현금영수증이 자진 발급됩니다."])},
  "10501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 일시"])},
  "10502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소내역 삭제"])},
  "10503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 완료"])},
  "10504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약이 완료되었습니다."])},
  "10505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적립예정내역"])},
  "10506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 퇴실 24시간 이후 포인트/스탬프가 지급됩니다."])},
  "10507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(대실 상품은 스탬프가 지급되지 않습니다.)"])},
  "10508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약내역으로"])},
  "10509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약내역 삭제"])},
  "10510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실 이용시간 변경하기"])},
  "10511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 및 환불 규정"])},
  "10512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 보기"])},
  "10513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니 담기"])},
  "10514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 예약하기"])},
  "10515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 레저·티켓을 픽 해보세요"])},
  "10516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐거움으로 가득찬 여행을 만날 수 있습니다."])},
  "10517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 레저·티켓 보러가기"])},
  "10518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더욱 빠르게 관심 숙소를 만날 수 있어요!"])},
  "10519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 숙소 보러가기"])},
  "10520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 숙소를 픽 해보세요"])},
  "10521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체사진"])},
  "10522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표사진"])},
  "10523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡"])},
  "10524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카톡"])},
  "10525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시 할인 쿠폰"])},
  "10526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 원 쿠폰 확인"])},
  "10527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단골쿠폰"])},
  "10528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 변경"])},
  "10529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실선택"])},
  "10530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소정보"])},
  "10531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소소개"])},
  "10532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히보기"])},
  "10533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편의시설 및 서비스"])},
  "10534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약공지"])},
  "10535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 리뷰가 없어요"])},
  "10536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고"])},
  "10537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실 선택하기"])},
  "10538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헐리우드룸"])},
  "10539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실시설"])},
  "10540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피트니스"])},
  "10541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사우나"])},
  "10542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스파"])},
  "10543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수영장"])},
  "10544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["골프 연승장"])},
  "10545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["살롱"])},
  "10546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컨퍼런스 룸"])},
  "10547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공기청정기"])},
  "10548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바"])},
  "10549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레스토랑"])},
  "10550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본정보"])},
  "10551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 객실은 차량이용고객은 예약이 불가하오니, 이용에 참고부탁드립니다."])},
  "10553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소규정"])},
  "10555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박"])},
  "10556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용시간"])},
  "10557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영시간"])},
  "10558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실"])},
  "10559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약마감"])},
  "10560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기까지"])},
  "10561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역이나 날짜, 인원을 변경해보세요"])},
  "10562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건에 맞는 숙소가 없어요"])},
  "10563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게하·한옥"])},
  "10564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["글램핑"])},
  "10565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천하는 주변숙소"])},
  "10566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["꿈꾸던 완벽한 숙소"])},
  "10567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역별 추천 숙소"])},
  "10568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매월 추천하는 플레이스를 소개합니다."])},
  "10569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 리뷰"])},
  "10570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 숙소를 이용 하시고 첫번째 리뷰를 작성해주세요!"])},
  "10571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포토 리뷰만 보기"])},
  "10572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼 영역"])},
  "10573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기검색어"])},
  "10574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근검색어"])},
  "10575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한옥"])},
  "10576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾으시는 결과가 없나요?"])},
  "10577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이런 검색어는 어떠세요?"])},
  "10578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주도"])},
  "10579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여수"])},
  "10580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수상스키"])},
  "10581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전주"])},
  "10582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["물놀이"])},
  "10583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["속초"])},
  "10584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님, 프라이빗하게 힐링하고 싶다면"])},
  "10585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 위치"])},
  "10586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 일자"])},
  "10587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 인원"])},
  "10588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성인"])},
  "10589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아동"])},
  "10590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나만의 숙소찾기"])},
  "10591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화살표"])},
  "10592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충북"])},
  "10593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충남"])},
  "10594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전북"])},
  "10595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
  "10596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화"])},
  "10597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수"])},
  "10598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목"])},
  "10599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금"])},
  "10600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토"])},
  "10601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맑음"])},
  "10602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구름많음"])},
  "10603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["흐림"])},
  "10604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비"])},
  "10605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["눈"])},
  "10606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["눈/비"])},
  "10607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1박기준"])},
  "10608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["떠나요 #부산 모든걸 훌훌 버리고"])},
  "10609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금은 차박시대"])},
  "10610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차박 초보자를 위한 가이드"])},
  "10611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평창 카페 바셀로"])},
  "10612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감성 카페 투어! 분위기 맛집"])},
  "10613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["밤의 낭만을 그대에게"])},
  "10614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나만 아는 동네 / 충주"])},
  "10615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가을날의 물놀이"])},
  "10616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국내 감성 풀빌라 베스트3"])},
  "10617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나랑 별 보러 가지 않을래?"])},
  "10618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["별 보기 좋은 전국의 천문대"])},
  "10619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주에 뜬 미식의 별"])},
  "10620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주도 스타 셰프의 맛집"])},
  "10621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추워도 너무 춥다!"])},
  "10622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["넷플릭스 보면서 뒹굴거리기 어때요?"])},
  "10623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["역사덕후들을 위한 역사투어"])},
  "10624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["밀양 유적지 월연정"])},
  "10625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["군산으로 시간 여행을 떠나자!"])},
  "10626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신비로운 군산시간여행마을"])},
  "10627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["춘천 명물 감자빵"])},
  "10628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한번만 먹어본 사람은 없다!"])},
  "10629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행지\n\n 찾기"])},
  "10630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발걸음이 가벼워지는 이번주 날씨는?"])},
  "10631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 인기 있는 숙박"])},
  "10632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["군인신분인증조회"])},
  "10633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나라사랑 포털 회원 가입 후 <span class='blue'>본인인증</span>이 <br>완료되어야 할인이 적용됩니다."])},
  "10634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나라사랑인증"])},
  "10635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20세~35세 남성 회원"])},
  "10636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간부인증"])},
  "10637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현역간부,군무원,국방부 공무원"])},
  "10638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1인 이용권"])},
  "10639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동영상 촬영 미포함"])},
  "10640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로 구매하기"])},
  "10641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰번호를 다시 확인해 주세요."])},
  "10642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 적용 닫기"])},
  "10643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 적용"])},
  "10644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 쿠폰"])},
  "10645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 일반쿠폰이 없습니다"])},
  "10646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택안함"])},
  "10647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["할인"])},
  "10648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중복 쿠폰"])},
  "10649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 중복쿠폰이 없습니다"])},
  "10650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 적용하기"])},
  "10651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 쿠폰 정보"])},
  "10652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 시 바로 사용 가능한 쿠폰입니다."])},
  "10653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 유의사항 안내"])},
  "10654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본 쿠폰은 방문 결제 및 비회원 사용이 불가합니다."])},
  "10655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본 쿠폰은 예약 시 사용 가능한 즉시 할인 쿠폰으로 잔여 수량이 남아있는 경우 예약 페이지에서 선택하여 사용 가능합니다."])},
  "10656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 생성 수량에 따라 조기 마감될 수 있습니다."])},
  "10657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본 혜택은 제휴점 및 당사의 사정에 의해 변경/중지 될 수 있습니다."])},
  "10658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 적용내역 닫기"])},
  "10659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 적용내역"])},
  "10660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 쿠폰 할인액"])},
  "10661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜 선택"])},
  "10662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주변예약"])},
  "10663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천순"])},
  "10664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신순"])},
  "10665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["핫플레이스"])},
  "10666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여행"])},
  "10667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["맛집"])},
  "10668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 적용"])},
  "10669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마"])},
  "10670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편의시설"])},
  "10671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
  "10672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 적용하기"])},
  "10673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 초기화"])},
  "10674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["객실"])},
  "10675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 닫기"])},
  "10676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지"])},
  "10677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역 선택"])},
  "10678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
  "10679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 인원 선택"])},
  "10680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박하실 인원을 선택하세요."])},
  "10681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명"])},
  "10682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피드"])},
  "10683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트·기획전"])},
  "10684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
  "10685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "10686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주하는질문"])},
  "10687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전 화면으로 돌아가시나요?"])},
  "10688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력하신 정보가 모두 사라집니다."])},
  "10689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뒤로가기"])},
  "10690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제가 취소되었습니다."])},
  "10691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제오류가 발생했습니다. 다시 시도해 주세요."])},
  "10692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제하시겠습니까?"])},
  "10693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 내역이 목록에서 삭제됩니다."])},
  "10694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 후 이용해 주세요"])},
  "10695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용을 위해 로그인해 주세요"])},
  "10696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인하기"])},
  "10697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제하시겠습니까?"])},
  "10698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 상품이 픽목록에서 해제됩니다."])},
  "10699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해제"])},
  "10700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고가 완료되었습니다."])},
  "10701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리뷰신고"])},
  "10702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신고하기"])},
  "10703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 계정으로 SNS로그인 연결 하시겠습니까?"])},
  "10704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결하기"])},
  "10705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택된 쿠폰이 없습니다"])},
  "10706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대한민국 숙박대전"])},
  "10708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인인증 진행하기"])},
  "10709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인인증을 진행하지 않을래요"])},
  "10710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID : 에 숙박대전 할인쿠폰이 발급완료 되었습니다"])},
  "10711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 발급된 쿠폰이 있습니다."])},
  "10712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약하러가기"])},
  "10713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 발급 정보 입력"])},
  "10714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※현재 거주하시는 지역을 선택해주세요"])},
  "10715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도/시"])},
  "10716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택해주세요"])},
  "10717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시/군/구"])},
  "10718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박대전 개인정보 활용 동의"])},
  "10719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박대전 쿠폰 발급을 위해서 개인정보제공에 관한 약관 확인 및 동의가 필수입니다."])},
  "10720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 전체 동의합니다"])},
  "10721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선착순 발급 받기"])},
  "10722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유하기"])},
  "10723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡 공유하기"])},
  "10724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS 공유하기"])},
  "10725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크복사"])},
  "10726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천순"])},
  "10727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거리순"])},
  "10728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리뷰 많은 순"])},
  "10729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랜덤순"])},
  "10730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실 낮은 가격순"])},
  "10731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실 높은 가격순"])},
  "10732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 낮은 가격순"])},
  "10733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 높은 가격순"])},
  "10734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 수집 및 이용동의"])},
  "10735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소/환불 규정동의"])},
  "10736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 규정"])},
  "10737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰 정책"])},
  "10738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포인트 정책"])},
  "10739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙박 상품 이용 규칙 동의"])},
  "10740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 제 3자 제공 동의"])},
  "10741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 3자 제공에 대해 동의 거부 가능하며  이 경우 서비스 이용이 불가합니다."])},
  "10742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대실이용시간"])},
  "10743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 방문수단 닫기"])},
  "10744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 방문수단"])},
  "10745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도보방문"])},
  "10746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차량방문"])},
  "10747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주차가 불가합니다."])},
  "10748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 정보"])},
  "10749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(주)루넷"])},
  "10750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표이사 : 박기현"])},
  "10751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자등록번호 : 215-87-14179"])},
  "10752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통신판매번호 : 2021-서울마포-2542 호"])},
  "10753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 : 서울특별시 마포구 양화로 161, 케이스퀘어 5층 513호 (동교동)"])},
  "10754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터 : 02-407-8640"])},
  "10755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영시간 : 09:00 ~ 18:00"])},
  "10756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메일 : roonet.co"])},
  "10757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스팅서비스 제공자: 주식회사 (주)루넷"])},
  "10758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보처리방침"])},
  "10759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고/제휴문의"])},
  "10761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴 열기/닫기"])},
  "10762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈"])},
  "10763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내주변"])},
  "10764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
  "10765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이페이지"])},
  "10766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 상품이 담겼습니다."])},
  "10767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바로가기"])},
  "10768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건을 선택해 주세요"])},
  "10769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["픽 목록에 추가되었습니다"])},
  "10770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개 선택"])},
  "10771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디를 입력해 주세요"])},
  "10772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해 주세요"])},
  "10773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세 이상"])},
  "10774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
  "10775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적용하기"])},
  "10776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 골랐어요"])},
  "10777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약번호를 입력해 주세요"])},
  "10778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 번호를 입력해 주세요"])},
  "10779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호를 입력해 주세요"])},
  "10780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디(이메일)를 입력해 주세요"])},
  "10781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재입력"])},
  "10782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 보내기"])},
  "10783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 한번 더 입력해 주세요"])},
  "10784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 삭제하려는 이유를 알려주세요."])},
  "10785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전년도 이용 금액 100만원 이상 <br> AND 구매 건수 20건 이상"])},
  "10786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 50%할인 혜택 (총 5회)"])},
  "10787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 모든 회원등급 유지기간은 1년 입니다."])},
  "10788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용가능한 쿠폰"])},
  "10789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 모두 동의해주세요."])},
  "10790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 또는 비밀번호가 올바르지 않습니다. 다시 입력해주세요."])},
  "10791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 모두 동의해주세요."])},
  "10792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 실패했습니다."])},
  "10793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 모두 동의해주세요."])},
  "10794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의가 접수되었습니다."])},
  "10795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수 동의사항 모두 동의해주세요."])},
  "10796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 번호가 일치하지 않습니다. 다시 확인해주세요."])},
  "10797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."])},
  "10798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."])},
  "10799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호를 재발송 하였습니다."])},
  "10800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴가 완료되었습니다."])},
  "10801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴에 실패하였습니다."])},
  "10802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 인증번호가 일치하지 않습니다."])},
  "10803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 중복확인을 해주세요."])},
  "10804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용중인 닉네임입니다."])},
  "10805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 일치하지 않습니다."])},
  "10806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 영문, 숫자를 포함하여 8~20자리로 입력해주세요."])},
  "10807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천코드가 복사되었습니다."])},
  "10808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약자명을 입력해주세요."])},
  "10809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 번호를 인증해주세요."])},
  "10810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문자명을 입력해주세요."])},
  "10811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방문자 휴대폰 번호를 입력해주세요."])},
  "10812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숙소 방문 수단을 선택해주세요."])},
  "10813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제수단을 선택해주세요."])},
  "10814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현금영수증 정보 수집에 동의해주세요."])},
  "10815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관에 모두 동의해주세요."])},
  "10816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 비용이 0원 이하입니다."])},
  "10817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약하시겠습니까?"])},
  "10818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 성공했습니다."])},
  "10819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 실패했습니다."])},
  "10820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없는 지역입니다. 관리자에 문의 부탁드리겠습니다."])},
  "10821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예약 가능 시간이 아닙니다."])},
  "10822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제가 발생하였습니다."])},
  "10823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 날짜의 숙박은 불가능합니다."])},
  "10824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력해 주세요."])},
  "10825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠폰번호를 입력해 주세요"])},
  "10826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 10자 이상 작성해 주세요"])},
  "10827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰번호를 입력해주세요"])},
  "10828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해주세요"])},
  "10829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 삭제하려는 이유를 알려주세요."])},
  "10830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임을 입력해 주세요"])},
  "10831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 한번 더 입력해 주세요"])},
  "10832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의유형을 선택해 주세요"])},
  "10833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해 주세요"])},
  "10834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객님의 솔직한 평가를 적어주세요.  <br><br>  텍스트 리뷰 작성 시 : 30P 즉시 지급<br>  포토 리뷰 작성 시 : 50P 즉시 지급"])},
  "10835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력해주세요"])},
  "10836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역, 상품명으로 검색해 보세요"])},
  "10837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["떠나는 인원은 몇명인가요?"])},
  "10838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["떠나는 여행지는 어디인가요?"])},
  "10839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["떠나는 날짜는 언제인가요?"])},
  "10840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자정보"])},
  "10841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표이사"])},
  "10842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자등록번호"])},
  "10843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통신판매번호"])},
  "10844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
  "10845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
  "10846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영시간"])},
  "10847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메일"])},
  "10848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호스팅서비스 제공자"])},
  "10849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용약관"])},
  "10850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보처리방침"])},
  "10851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치정보 이용약관"])},
  "10852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청소년 보호정책"])},
  "10853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광고/제휴문의"])},
  "10854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헬로스테이는 통신판매중개자로서 통신판매의 당사자가 아니며,<br>상품의 예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다."])},
  "10855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["박"])},
  "10856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 숙소를 <br> 픽 해보세요"])},
  "10857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더욱 빠르게 관심 숙소를 만날 수 있어요!"])},
  "10858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 숙소 보러가기"])},
  "10859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급혜택"])},
  "area00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
  "area01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울"])},
  "area02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부산"])},
  "area03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인천"])},
  "area04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광주"])},
  "area05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대전"])},
  "area06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대구"])},
  "area07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["울산"])},
  "area10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강원"])},
  "area11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경기"])},
  "area12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경남"])},
  "area13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경북"])},
  "area14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전남"])},
  "area15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전북"])},
  "area16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주"])},
  "area17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충남"])},
  "area18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충북"])},
  "area0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
  "area0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울 전지역"])},
  "area0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강남/역삼/서초/삼성"])},
  "area0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강동/천호/길동/둔촌/방이/신천"])},
  "area0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강북/미아/성북/수유/월곡"])},
  "area0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강서/까치산역"])},
  "area0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관악/동작/사당/서울대/신림/"])},
  "area0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구로/금천/신도림/오류"])},
  "area0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["면목/상봉/중랑/태릉/노원/도봉/창동"])},
  "area0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명동/회현/이태원/용산/중구"])},
  "area0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목동/화곡/양천"])},
  "area0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서대문/마포/은평"])},
  "area0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선릉/신사/논현/방배"])},
  "area0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송파/잠실/새내"])},
  "area0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신촌/합정/홍대/불광/연신내/응암"])},
  "area0114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여의도/영등포"])},
  "area0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["왕십리/성수/금호/충무로/신당/약수"])},
  "area0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회기/안암/청량리/신설/동대문"])},
  "area0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장안동/답십리/건대/군자/구의/광진"])},
  "area0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종로/대학로/성신여대"])},
  "area0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부산 전지역"])},
  "area0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강서구(명지,신호)/북구(덕천,만덕,구포,화면,북구)"])},
  "area0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금정구(부산대,구서,연산,토곡)/동래구(온천장, 사직)"])},
  "area0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기장군(기장/정관)"])},
  "area0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남구(경성대,대연,용호,문현)/중구(남포동)/동구(부산역,범일동)/영도구(영도)"])},
  "area0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부산진구(서면,양정,초읍)/사상/연제"])},
  "area0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서구(송도)/사하구(하단,사하)"])},
  "area0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해운대구(송정/수영구(광안리,민락)/재송"])},
  "area0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인천 전지역"])},
  "area0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강화/옹진/중구/백령도"])},
  "area0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동구/미추홀/남동구"])},
  "area0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동암/간석"])},
  "area0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부평/계양/서구/주안/검단"])},
  "area0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송도/연수"])},
  "area0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월미도/차이나타운/신포/동인천"])},
  "area0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인천공항/을왕리"])},
  "area0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광주 전지역"])},
  "area0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광산구"])},
  "area0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광산구 하남/송정역"])},
  "area0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남구"])},
  "area0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동구"])},
  "area0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["북구"])},
  "area0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서구"])},
  "area0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대전 전지역"])},
  "area0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대덕구(신탄진/중리)"])},
  "area0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동구(용전/복합터미널)"])},
  "area0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서구(둔산/용문/월평)"])},
  "area0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유성구(유성 봉명/도안/장대)"])},
  "area0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중구(중구은행/대흥/선화/유천)"])},
  "area0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대구 전지역"])},
  "area0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남구(대명/봉덕/서부정류장)"])},
  "area0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달서구(두류공원/두류/본리/죽전/감삼/성서공단/계명대)"])},
  "area0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달성군"])},
  "area0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동구(동대구역/신암/신천/등촌유원지/대구공항/혁신도시)"])},
  "area0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["북구(대구역/엑스코/칠곡3지구/태전동)"])},
  "area0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서구(평리/내당/비산/원대)"])},
  "area0607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수성구(들안길/수성못)"])},
  "area0608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중구(경북대/동성로/시청/서문시장)"])},
  "area0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["울산 전지역"])},
  "area0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남구/동구"])},
  "area0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["울주군"])},
  "area0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중구/북구"])},
  "area1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강원 전지역"])},
  "area1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강릉/동해/삼척"])},
  "area1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고성/속초/양양"])},
  "area1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["양구/인제/철원/화천"])},
  "area1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원주/횡성"])},
  "area1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["춘천/홍천"])},
  "area1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평창/정선/영월/태백"])},
  "area1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경기 전지역"])},
  "area1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가평/청평/양평"])},
  "area1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고양/일산/김포/장기/구래/대명항"])},
  "area1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과천/인덕원"])},
  "area1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["광주/여주/이천"])},
  "area1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남양주/구리/의정부/하남"])},
  "area1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병점/오산/동탄"])},
  "area1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부천"])},
  "area1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수원(구운/세류/수원역/장안/팔달문)"])},
  "area1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수원(권선/영통/인계)"])},
  "area1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시흥/광명"])},
  "area1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안산/대부도"])},
  "area1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안양/평촌/군포/의왕/금정/산본"])},
  "area1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파주/금촌/헤이리/통일동산"])},
  "area1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평택/송탄/안성"])},
  "area1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포천/양주/동두천/연천/장흥"])},
  "area1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하남/성남/분당/용인"])},
  "area1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화성/제부도"])},
  "area1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경남 전지역"])},
  "area1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거제/통영/고성"])},
  "area1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거창/함양/합천/산청/창녕"])},
  "area1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["김해/양산/밀양/장유"])},
  "area1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진주/남해/사천/하동"])},
  "area1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창원(마산,진해)"])},
  "area1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창원(명서,팔용,봉곡,북면)"])},
  "area1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창원(상남,용호,중앙)"])},
  "area1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["함안/의령"])},
  "area1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경북 전지역"])},
  "area1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경주/영천/경산/청도"])},
  "area1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["김천/성주/고령/칠곡/구미/군위"])},
  "area1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문경/상주/예천/영주/봉화"])},
  "area1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안동/의성/청송/영덕/영양/울진"])},
  "area1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포항 남구"])},
  "area1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포항 북구"])},
  "area1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전남 전지역"])},
  "area1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강진/해남/완도/진도"])},
  "area1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구례/곡성/담양/장성/영광/함평"])},
  "area1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목포/무안/신안"])},
  "area1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보성/고흥/장흥"])},
  "area1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여수/광양/순천"])},
  "area1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영암/나주/화순"])},
  "area1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전북 전지역"])},
  "area1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["군산/김제"])},
  "area1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무주/진안/장수"])},
  "area1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부안/정읍/고창"])},
  "area1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["임실/남원/순창"])},
  "area1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전주/익산/완주"])},
  "area1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주 전지역"])},
  "area1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서귀포시/마라도"])},
  "area1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애월/협재"])},
  "area1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주시"])},
  "area1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충남 전지역"])},
  "area1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계룡/공주/논산/금산"])},
  "area1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보령/부여/서천/대천"])},
  "area1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예산/홍성/청양"])},
  "area1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["천안/아산"])},
  "area1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충남/세종 전체"])},
  "area1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태안/당진/서산/안면도"])},
  "area1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충북 전지역"])},
  "area1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보은/옥천/영동"])},
  "area1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제천/단양"])},
  "area1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청주/보은/진천"])},
  "area1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충주/음성/괴산/수안보/증평"])},
  "weather00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울·경기"])},
  "weather01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강원"])},
  "weather02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충남"])},
  "weather03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["충북"])},
  "weather04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전남"])},
  "weather05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전북"])},
  "weather06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경북"])},
  "weather07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경남"])},
  "weather08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제주"])},
  "day월": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
  "day화": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화"])},
  "day수": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수"])},
  "day목": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목"])},
  "day금": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금"])},
  "day토": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토"])},
  "day일": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
  "dayMo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
  "dayTu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화"])},
  "dayWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수"])},
  "dayTh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목"])},
  "dayFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금"])},
  "daySa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토"])},
  "daySu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])}
}