import fetch from "./fetch";

const CD086_URL = () => "/PC/WPC000_CD086";
const M0000_URL = () => "/PC/WPC_M0000";
const TA_111 = () => "/TA/WTA111_U101";

export default {
  getCd086: fetch("GET", CD086_URL),
  getM0000: fetch("GET", M0000_URL),
  getTa111: fetch("GET", TA_111),
};
