const state = {
  searchItems: JSON.parse(localStorage.getItem("search.searchItems") || "[]"),
};

const getters = {};

const mutations = {
  ADD_SEARCH_ITEM(state, { searchItem }) {
    if(state.searchItems.find(item => item === searchItem) !== undefined){
      state.searchItems.splice(state.searchItems.indexOf(searchItem), 1);
    }
    state.searchItems = [searchItem, ...state.searchItems.slice(0, 9)];

    localStorage.setItem("search.searchItems", JSON.stringify(state.searchItems));
  },
  REMOVE_SEARCH_ITEM(state, { seq }) {
    state.searchItems.splice(seq, 1);

    localStorage.setItem("search.searchItems", JSON.stringify(state.searchItems));
  },
  CLEAR_SEARCH_ITEM(state) {
    state.searchItems = [];
    
    localStorage.setItem("search.searchItems", "[]");
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
