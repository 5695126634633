//import api from "@/urls/mmb03";
import api from "@/urls/urls_all";

const state = {
  mmb03: [],
  mmb0302: [],
  mmb0303: [],
  phoneNumber: "",
  phoneNumber2: "",
};

const getters = {};

const mutations = {
  SET_MMB03(state, { mmb03 }) {
    state.mmb03 = mmb03;
  },
  SET_MMB0302(state, { mmb0302 }) {
    state.mmb0302 = mmb0302;
  },
  SET_MMB0303(state, { mmb0303 }) {
    state.mmb0303 = mmb0303;
  },
  SET_PHONE_NUMBER(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber;
  },
  SET_PASSWORD_PHONE_NUMBER(state, { phoneNumber2 }) {
    state.phoneNumber2 = phoneNumber2;
  },
};

const actions = {
  async fetchMmb03({ commit }, params) {
    const data = await api.getMmb03(params);
    commit("SET_MMB03", { mmb03: data.data.Res_data });

    return data;
  },
  async fetchMmb0302({ commit, state }) {
    const data = await api.getMmb0302({
      proc_cd: "02",
      hp_no: state.phoneNumber,
    });
    commit("SET_MMB0302", { mmb0302: data.data.Res_data });
  },
  async fetchMmb0303({ commit }, params) {
    const data = await api.getMmb0303(params);
    commit("SET_MMB0303", { mmb0303: data.data.Res_data });

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
