//import api from "@/urls/mmy01";
import api from "@/urls/urls_all";

const state = {
  mmy0101: [],
  mmy010401: [],
  mmy010402: [],
  mmy010403: [],
  mmy0109: [],
  mmy0111: [],
  mmy0112: [],
  mmy0113: [],
  mmy011209: [],
  mmy01121: [],
  mmy0120: [],
  mmy0122: [],
  mmy01421: {},
  reviews: [],
  reviewCodes: [],
  mmy01122: [],
};

const getters = {};

const mutations = {
  SET_MMY0101(state, { mmy0101 }) {
    state.mmy0101 = mmy0101;
  },
  SET_MMY010401(state, { mmy010401 }) {
    state.mmy010401 = mmy010401;
  },
  SET_MMY010402(state, { mmy010402 }) {
    state.mmy010402 = mmy010402;
  },
  SET_MMY010403(state, { mmy010403 }) {
    state.mmy010403 = mmy010403;
  },
  SET_MMY0109(state, { mmy0109 }) {
    state.mmy0109 = mmy0109;
  },
  SET_MMY0111(state, { mmy0111 }) {
    state.mmy0111 = mmy0111;
  },
  SET_MMY0112(state, { mmy0112 }) {
    state.mmy0112 = mmy0112;
  },
  SET_MMY011209(state, { mmy011209 }) {
    state.mmy011209 = mmy011209;
  },
  SET_MMY0113(state, { mmy0113 }) {
    state.mmy0113 = mmy0113;
  },
  SET_MMY01121(state, { mmy01121 }) {
    state.mmy01121 = mmy01121;
  },
  SET_MMY0120(state, { mmy0120 }) {
    state.mmy0120 = mmy0120;
  },
  SET_MMY0122(state, { mmy0122 }) {
    state.mmy0122 = mmy0122;
  },
  SET_MMY01421(state, { mmy01421, proc_cd }) {
    state.mmy01421[proc_cd] = mmy01421;
  },
  SET_REVIEWS(state, { reviews }) {
    state.reviews = reviews;
  },
  SET_REVIEW_CODES(state, { reviewCodes }) {
    state.reviewCodes = reviewCodes;
  },
  SET_MMY01201(state, { mmy01201 }) {
    state.mmy01201 = mmy01201;
  },
  SET_MMY01122(state, { mmy01122 }) {
    state.mmy01122 = mmy01122;
  },
};

const actions = {
  async fetchMmy0101({ commit }, params) {
    const data = await api.getMmy0101(params);
    commit("SET_MMY0101", { mmy0101: data.data.Res_data });
  },
  async fetchMmy010401({ commit }, params) {
    const data = await api.getMmy0104(params);
    commit("SET_MMY010401", { mmy010401: data.data.Res_data });
  },
  async fetchMmy010402({ commit }, params) {
    const data = await api.getMmy0104(params);
    commit("SET_MMY010402", { mmy010402: data.data.Res_data });
  },
  async fetchMmy010403({ commit }, params) {
    const data = await api.getMmy0104(params);
    commit("SET_MMY010403", { mmy010403: data.data.Res_data });
  },
  async fetchMmy0109({ commit }, params) {
    const data = await api.getMmy0109(params);
    commit("SET_MMY0109", { mmy0109: data.data.Res_data });
  },
  async fetchMmy0111({ commit }, params) {
    const data = await api.getMmy0111(params);
    commit("SET_MMY0111", { mmy0111: data.data.Res_data });
  },
  async fetchMmy0112({ commit }, params) {
    const data = await api.getMmy0112(params);
    commit("SET_MMY0112", { mmy0112: data.data.Res_data });
    return data.data;
  },
  async fetchMmy011209({ commit }, params) {
    const data = await api.getMmy0112(params);
    commit("SET_MMY011209", { mmy011209: data.data.Res_data });
  },
  async fetchMmy0113({ commit }, params) {
    const data = await api.getMmy0113(params);
    commit("SET_MMY0113", { mmy0113: data.data.Res_data });
  },
  async fetchMmy01121({ commit }, params) {
    const data = await api.getMmy01121(params);
    commit("SET_MMY01121", { mmy01121: data.data.Res_data });
  },
  async fetchMmy0120({ commit }, params) {
    const data = await api.getMmy0120(params);
    commit("SET_MMY0120", { mmy0120: data.data.Res_data });
  },
  async fetchMmy0122({ commit }, params) {
    const data = await api.getMmy0122(params);
    commit("SET_MMY0122", { mmy0122: data.data.Res_data });
  },
  async fetchMmy01421({ commit }, params) {
    const data = await api.getMmy01421(params);
    commit("SET_MMY01421", {
      mmy01421: data.data.Res_data,
      proc_cd: params.proc_cd,
    });
  },
  async fetchReviews({ commit }, { mem_id, pagesize, gotopage }) {
    const data = await api.getMmy0110({
      proc_cd: "01",
      mem_id,
      pagesize,
      gotopage,
    });
    const reviews = await Promise.all(
      data.data.Res_data.map(async (data) => {
        const images = await api.getMmy0110({
          proc_cd: "02",
          mem_id,
          seq: data.SEQ,
        });
        return {
          ...data,
          images: images.data.Res_data,
        };
      })
    );
    commit("SET_REVIEWS", { reviews });
  },
  async deleteReview(_, { mem_id, seq }) {
    const data = await api.getMmy0110({ proc_cd: "09", mem_id, seq });
    return data.data.Res_cd;
  },
  async fetchReviewCodes({ commit }) {
    const data = await api.getMmy01101({ proc_cd: "01" });
    commit("SET_REVIEW_CODES", { reviewCodes: data.data.Res_data });
  },
  async uploadReview(
    _,
    {
      mem_id,
      hotel_no,
      prod_no,
      booking_no,
      score,
      Review,
      review_cd,
      review_cd2,
    }
  ) {
    const data = await api.getMmy01101({
      proc_cd: "10",
      mem_id,
      hotel_no,
      prod_no,
      booking_no,
      score,
      Review,
      review_cd,
      review_cd2,
    });
    return data.data;
  },
  async updateReview(_, { mem_id, score, Review, review_cd, review_cd2, seq }) {
    const data = await api.getMmy01101({
      proc_cd: "20",
      mem_id,
      score,
      Review,
      review_cd,
      review_cd2,
      seq,
    });
    return data.data.Res_cd;
  },
  async upsertImage(_, { mem_id, seq,hotel_no, images }) {
    const data = await api.getMmy01102({
      proc_cd: "01",
      mem_id,
      seq,
      hotel_no,
      img_nm: images
    });
    return data.data.Res_cd;
  },
  async postMmy0120({ commit }, params) {
    const data = await api.getMmy0120(params);
    console.log(commit, data);
  },
  async fetchMmy01201({ commit }, params) {
    const data = await api.getMmy01201(params);
    commit("SET_MMY01201", { mmy01201: data.data.Res_data });
  },
  async getMmy01122({ commit }) {
    const data = await api.getMmy01122({ proc_cd: "01" });
    commit("SET_MMY01122", { mmy01122: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
