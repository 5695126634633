export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiness"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japaness"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOOKING"])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTEL"])},
  "HO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOTEL"])},
  "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PENSION"])},
  "RT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESORT"])},
  "CP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPING"])},
  "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUESTHOUSE"])},
  "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GLAMPING"])},
  "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangnam Nonhyeon Samsung"])},
  "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation (Stays / Hotel)"])},
  "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure/ Ticket"])},
  "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme Park"])},
  "10006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance/Exhibition"])},
  "10007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Park / Spa"])},
  "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation with coupon discount"])},
  "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10,000 KRW discount coupon"])},
  "10011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic Trampoline Experience!"])},
  "10012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Seoul] Yeoksam Bounce Trampoline"])},
  "10013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 km from my location"])},
  "10014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same-day use <br>Full refund for unused"])},
  "10015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cock"])},
  "10016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonhyeon-dong, Seoul"])},
  "10017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "10018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no leisure/tickets nearby."])},
  "10019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See various leisure tickets of Hotel Salad and <br> Buy leisure tickets!"])},
  "10020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Leisure & Tickets"])},
  "10021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motel"])},
  "10022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
  "10023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension"])},
  "10024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resort"])},
  "10025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping/Glamping"])},
  "10026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no available accommodations nearby"])},
  "10027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet various accommodations in Hotel Salad <br> Book your accommodation!"])},
  "10028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to accommodation"])},
  "10029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest House (GH)"])},
  "10030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Page"])},
  "10031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "10032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "10033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no registered notices"])},
  "10034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership inquiry"])},
  "10035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "10036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "10037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
  "10038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "10039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries"])},
  "10040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to collection and use of personal information (Essential)"])},
  "10041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to collection and use of personal information (Essential)"])},
  "10042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Policies"])},
  "10043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "10044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "10045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Information Use Policy"])},
  "10046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youth Protection Policy"])},
  "10047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Promotion"])},
  "10048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Accommodation Competition Pre-Certification Event"])},
  "10049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Accommodation"])},
  "10050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10,000 KRW discount coupon"])},
  "10051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first-come-first-served discount coupon"])},
  "10052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are there any fantastic rooftops or not?"])},
  "10053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonju Hanok Eco Village Pension"])},
  "10054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["145m from Yeoksam Station Exit 1"])},
  "10055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 5 hours Day Use rooms are available"])},
  "10056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Hours of Day Use"])},
  "10057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night Stay Check-in from 18:00"])},
  "10058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
  "10059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "10060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Check-in"])},
  "10061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular coupon"])},
  "10062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "10063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekday Infinity  Day Use Room Event! up to 10 hours"])},
  "10064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
  "10065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "10066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
  "10067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no current events"])},
  "10068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no current promotions"])},
  "10069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete travel preferences!"])},
  "10070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will recommend a destination that meets your taste."])},
  "10071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go directly to reservation"])},
  "10072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which travel destinations do you like?"])},
  "10073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up to 3 of your favorite destinations!"])},
  "10074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us your travel preferences!"])},
  "10075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up to 5 of my tastes!"])},
  "10076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose at least 1 with whom"])},
  "10077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose at least 1 travel style"])},
  "10078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "10079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby reservation"])},
  "10080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
  "10081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Details"])},
  "10082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-member reservation inquiry"])},
  "10083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Date:"])},
  "10084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product information"])},
  "10085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated reservation number"])},
  "10086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation number"])},
  "10087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
  "10088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRW"])},
  "10089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free cancellation up to"])},
  "10090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booker information"])},
  "10091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "10092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
  "10093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest information"])},
  "10094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Means of visit"])},
  "10095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount and payment information"])},
  "10096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of payment"])},
  "10097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation amount"])},
  "10098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
  "10099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
  "10100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Discount"])},
  "10101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of payment"])},
  "10102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations"])},
  "10103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your reservation information again"])},
  "10104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-member reservation inquiry"])},
  "10105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the reservation number and mobile phone number you received when booking."])},
  "10106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid reservation number. Please check again."])},
  "10107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation inquiry"])},
  "10108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up completed!"])},
  "10109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go directly to reservation"])},
  "10110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your travel preferences"])},
  "10111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's check the information below and prepare for the trip."])},
  "10112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree all"])},
  "10113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use over 14 years old (Essential)"])},
  "10114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service (Essential)"])},
  "10115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See detail"])},
  "10116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to collection and use of personal information (Essential)"])},
  "10117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Information Terms and Conditions (Optional)"])},
  "10118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to receive benefit notifications (optional)"])},
  "10119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "10120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Authentication"])},
  "10121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile phone number to provide smooth service."])},
  "10122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication request"])},
  "10123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-authentication"])},
  "10124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Number"])},
  "10125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Send"])},
  "10126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification numbers do not match."])},
  "10127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity verification completed"])},
  "10128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter information"])},
  "10129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "10130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlap Check"])},
  "10131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID (e-mail) already registered"])},
  "10132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "10133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including English character "])},
  "10134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including Number"])},
  "10135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 to 20 characters"])},
  "10136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "10137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Match"])},
  "10138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
  "10139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlap Check"])},
  "10140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 to 6 characters"])},
  "10141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This nickname is already being used"])},
  "10142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommender code"])},
  "10143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "10144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommender code does not match"])},
  "10145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up completed!"])},
  "10146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragliding Experience Flight Masterpiece"])},
  "10147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Chungbuk] Danyang Paragliding"])},
  "10148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product was picked by 17 users."])},
  "10149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity period: 60 days from the date of purchase"])},
  "10150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same-day use, unused, full refund"])},
  "10151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "10152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount and discount information"])},
  "10153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product usage guide"])},
  "10154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Purchase coupons (purchase required at least 1 day in advance)  Call reservation confirmation required (010-0000-0000)  Use on the reserved date”"])},
  "10155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
  "10157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "10158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Room (Type A) - Night Stay"])},
  "10159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname A"])},
  "10160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“The bed was really big and comfortable, so I slept well. The atmosphere is good and the facilities are good. have a good rest I'll be back on my next vacation.”"])},
  "10161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby"])},
  "10162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby leisure and tickets"])},
  "10163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“[Seoul Yeongdeungpo] Yeouido Hangang Park Lowest price for electric kickboard free pass”"])},
  "10164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
  "10165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby Accommodation"])},
  "10166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingdom Hotel"])},
  "10167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Ocloud"])},
  "10168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
  "10169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "10170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation with coupon discount"])},
  "10171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theme park"])},
  "10172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["performance and exhibition"])},
  "10173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water park <br>Spa"])},
  "10174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals and events"])},
  "10175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation"])},
  "10176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme A"])},
  "10177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme B"])},
  "10178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Leisure Tickets"])},
  "10179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGIF beer party together!"])},
  "10180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Seoul] Han River Beer Party"])},
  "10181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoying the romance of Jeju"])},
  "10182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Jeju] In-Car stay rental set"])},
  "10183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "10184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vivaldi Park Lift Ticket Exclusive Offer"])},
  "10185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited time offer of 57% off"])},
  "10186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shilla Stay Hotel Brand Special Event"])},
  "10187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad MD Highly recommended Fall Accommodation"])},
  "10188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best leisure tickets of the week"])},
  "10189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good to enjoy with family"])},
  "10190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Gangwon] Daegwallyeong Sheep Farm"])},
  "10191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a fantastic rooftop"])},
  "10192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeoksam Ven"])},
  "10193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where is the best place to play?"])},
  "10194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul"])},
  "10195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busan"])},
  "10196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "10197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "10198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "10199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "10200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "10201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full of fantastic art"])},
  "10202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Busan] Museum DAH"])},
  "10203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
  "10204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
  "10205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter is the season for skiing and boarding!"])},
  "10206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to enjoy even for beginners!"])},
  "10207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Pyeongchang] Daewon Resort Ski Lesson"])},
  "10208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended place"])},
  "10209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing Soul Place and Soul Restaurants."])},
  "10210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best 3 domestic sensible pool villas"])},
  "10211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release of dormant members"])},
  "10212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to long-term non-connection, it is in the status of being a dormant member."])},
  "10213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up date"])},
  "10214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
  "10215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dormant date"])},
  "10216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-send"])},
  "10218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release from dormancy"])},
  "10219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release from dormancy completed"])},
  "10220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully released from dormancy."])},
  "10221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your authentication method"])},
  "10222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Authentication"])},
  "10223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS authentication"])},
  "10224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code"])},
  "10225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID search completed"])},
  "10226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the ID (e-mail) information registered."])},
  "10227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are a registered member."])},
  "10228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with easy login."])},
  "10229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find password"])},
  "10230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "10231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID"])},
  "10232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password change complete"])},
  "10233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password change is complete.  Go to the login page."])},
  "10234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“After ID verification, through mobile phone identity authentication, Reset your password.”"])},
  "10235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "10236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "10237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password confirm"])},
  "10238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The withdrawal process has been completed"])},
  "10239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The withdrawal process has been completed"])},
  "10240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "10241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice on withdrawal"])},
  "10242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All account information currently in use will be deleted."])},
  "10243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points and all benefits will expire."])},
  "10245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read all of the above and agree to the withdrawal."])},
  "10247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm sorry, but it's time to say goodbye..."])},
  "10248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for enjoying Hotel Salad.  Please tell us why you're leaving to impove our service."])},
  "10249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "10250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancle"])},
  "10251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on benefits by level"])},
  "10252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the benefits that vary by level."])},
  "10253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "10254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Member"])},
  "10255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All new members"])},
  "10256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP member"])},
  "10257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 500,000 KRW in the previous year and 10 or more purchases"])},
  "10258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% discount on accommodation (2 times in total)"])},
  "10259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight record"])},
  "10260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before visit"])},
  "10261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After visit"])},
  "10262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancled"])},
  "10263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Completed"])},
  "10264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation number R1234"])},
  "10265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
  "10266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongno Lemon Tree"])},
  "10267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation Deluxe"])},
  "10268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "10269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
  "10270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
  "10271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
  "10272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "10273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book again"])},
  "10274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a review"])},
  "10275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of accommodation"])},
  "10276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel reservation"])},
  "10277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product information"])},
  "10278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation number :"])},
  "10279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check-in"])},
  "10280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check out"])},
  "10281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation information"])},
  "10282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product amount"])},
  "10283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point used"])},
  "10284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual payment amount"])},
  "10285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected cancellation fee 0 KRW"])},
  "10286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated refund amount 0 KRW"])},
  "10287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have checked all of the above."])},
  "10288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "10289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shopping basket"])},
  "10290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
  "10291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selection"])},
  "10292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "10293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation deadline for that time"])},
  "10294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total  Item(s)"])},
  "10295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a reservation"])},
  "10296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no products in the shopping cart"])},
  "10297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book multiple accommodations at once!"])},
  "10298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to product"])},
  "10299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon box"])},
  "10300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons available for"])},
  "10301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My coupon "])},
  "10302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "10303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "10304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% discount coupon"])},
  "10305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRW discount coupon"])},
  "10306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to KRW discount"])},
  "10307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])},
  "10308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue until event ends"])},
  "10309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No coupon history"])},
  "10310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participating in the hotel salad coupon issuance event You can get a coupon."])},
  "10311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons available for "])},
  "10312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Coupon"])},
  "10313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign up coupon"])},
  "10314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% discount coupon"])},
  "10315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to KRW 10,000 discount to commemorate membership registration"])},
  "10316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until 21.11.19 (D-3)"])},
  "10317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation Festival Coupon"])},
  "10318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP Coupon"])},
  "10319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit information"])},
  "10320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Contact"])},
  "10321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "10322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Nickname"])},
  "10323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available nickname."])},
  "10324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
  "10325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
  "10326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 inquiry history"])},
  "10327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "10328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your inquiry has been received.  We will respond as soon as possible.  thank you."])},
  "10329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction"])},
  "10330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "10331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "10332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
  "10333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no registered 1:1 inquiry"])},
  "10334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about our services We will respond as soon as possible."])},
  "10335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry type"])},
  "10336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries"])},
  "10337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "10338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure ticket purchase history"])},
  "10339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before use"])},
  "10340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After use"])},
  "10341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "10342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation complete"])},
  "10343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration period :"])},
  "10344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 persons, meal included"])},
  "10345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase again"])},
  "10346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP benefits"])},
  "10347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandation code Total number of invited members 1"])},
  "10348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad Point"])},
  "10349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 coupons"])},
  "10350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stamp 0/5"])},
  "10351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation reservations "])},
  "10352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure/ticket purchase history "])},
  "10353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shopping cart "])},
  "10354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my review"])},
  "10355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently viewed products"])},
  "10356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["travel preferences"])},
  "10357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your travel preferences and receive a 20% coupon"])},
  "10358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 inquiry"])},
  "10359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "10360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad Customer Center"])},
  "10361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone consultation"])},
  "10362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Center Hours of Operation"])},
  "10363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open all year round 10:00 ~ 24:00 (Lunch) 12:30 ~ 13:30"])},
  "10364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Talk Inquiry"])},
  "10365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Talk Customer Center Hours of Operation"])},
  "10366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open all year round 10:00 ~ 19:00 (Lunch) 12:30 ~ 13:30"])},
  "10367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login/Sign Up"])},
  "10368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you sign up as a member, you get 2,000 points instantly!"])},
  "10369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocommandation Code"])},
  "10370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of invited members "])},
  "10371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double points by entering the referral code!"])},
  "10372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my review"])},
  "10373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation evaluation"])},
  "10374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you feel about using it yourself?"])},
  "10375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ The above information is only disclosed to the relevant company."])},
  "10376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "10377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no reviews written"])},
  "10378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit various accommodations at Hotel Salad and write a review!"])},
  "10379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of canceled reservation"])},
  "10380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of accommodation used"])},
  "10381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no reservation history"])},
  "10382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items in your shopping cart"])},
  "10383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no coupons available"])},
  "10384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "10385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no canceled leisure tickets"])},
  "10386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of leisure/ticket used"])},
  "10387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet the various leisure and ticket lists of Hotel Salad Reserve your leisure ticket now!"])},
  "10388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of leisure and ticket purchases"])},
  "10389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "10390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'s available points"])},
  "10391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" remaining points"])},
  "10392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 days"])},
  "10393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 days"])},
  "10394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90 days"])},
  "10395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180 days"])},
  "10396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no point list that can be earned/used"])},
  "10397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you recommend hotel salad now, you can get points."])},
  "10398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My recommandation code:"])},
  "10399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no recently viewed accommodations."])},
  "10400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet various accommodations in Hotel Salad and book your accommodation now!"])},
  "10401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no recently viewed leisure tickets."])},
  "10402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet the various leisure tickets of Hotel Salad and buy your leisure tickets now!"])},
  "10403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member I invited"])},
  "10404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of members invited by  people"])},
  "10405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have no members invited"])},
  "10406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite your friends and get 1000 points!"])},
  "10407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no history of used coupons"])},
  "10408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation is required to safely change your valuable information."])},
  "10409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain/Use Date"])},
  "10410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No point history"])},
  "10411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
  "10412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Gangwon] Daegwallyeong Sheep Ranch"])},
  "10413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["891-35 Daechi-dong, Gangnam-gu, Seoul"])},
  "10414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
  "10415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member since"])},
  "10416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "10417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version information V1.0"])},
  "10418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["push notifications"])},
  "10419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set access rights"])},
  "10420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS connection settings"])},
  "10421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "10422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select ID to log in"])},
  "10423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an ID to connect to your SNS account."])},
  "10424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your social media account"])},
  "10425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Login"])},
  "10426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connect"])},
  "10427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disconnect"])},
  "10428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naver login"])},
  "10429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Login"])},
  "10430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamp"])},
  "10431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stamp card"])},
  "10432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earned history"])},
  "10433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'s stamp status /5"])},
  "10434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["round"])},
  "10435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get 5000P when you earn 5 stamps!"])},
  "10436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get stamps"])},
  "10437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After 24 hours from your stay, you will automatically earn 1 stamp!"])},
  "10438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice on stamps"])},
  "10439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Stamps are automatically accrued after check-out  (Accumulation time: applied at 00:00 the next day from the departure date)"])},
  "10440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Earn 5000P when you earn 5 stamps!"])},
  "10441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Stamps are accrued only when using accommodation products."])},
  "10442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Points are valid for 180 days."])},
  "10443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Stamp policy may vary depending on the situation."])},
  "10444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulated accumulation stamps "])},
  "10445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulated points p"])},
  "10446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earned date"])},
  "10447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3,000 accumulated stamps"])},
  "10448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product information "])},
  "10449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRW"])},
  "10450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["102 (individual swimming pool + duplex)"])},
  "10451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021.12.13 (Mon) 11:00 ~ 16:00"])},
  "10452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to get a discount!"])},
  "10453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor information"])},
  "10454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the actual visitor information"])},
  "10455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is the same as the reservation information."])},
  "10456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Means of visit"])},
  "10457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "10458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select means of visit"])},
  "10459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongro Lemon Tree"])},
  "10460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On foot"])},
  "10461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
  "10462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongju Villa"])},
  "10463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This property does not provide car parking."])},
  "10464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount and discount information"])},
  "10465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in and get discounts!"])},
  "10466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payment amount"])},
  "10467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a payment method"])},
  "10468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit / check card"])},
  "10469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account transfer"])},
  "10470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])},
  "10471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])},
  "10472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payco payment benefits"])},
  "10473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant discount of 5,000 KRW on purchases of 50,000 KRW or more!"])},
  "10474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax reduction"])},
  "10475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenditure proof"])},
  "10476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublished"])},
  "10477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cash receipt card number"])},
  "10478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using points or coupons, only the amount paid in real money will be issued a cash receipt."])},
  "10479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this payment method again later"])},
  "10481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Cancellation and Fees"])},
  "10482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the cancellation and refund policy, cancellation is not possible and a fee may occur."])},
  "10483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minors and legal representatives required"])},
  "10484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minors are not allowed to stay without a legal representative."])},
  "10485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fully agree to the terms and conditions"])},
  "10486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept the rules for using accommodation products (essential)"])},
  "10487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept cancellation/refund policy (essential)"])},
  "10488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to provide personal information to third parties (essential)"])},
  "10489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number you entered will be changed to a secure number and delivered to the hotel.<br>However, only in cases where it is difficult to process the secure number, the actual mobile phone number may be delivered based on the consent to provide personal information on a limited basis."])},
  "10490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAY"])},
  "10491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page"])},
  "10492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change authentication"])},
  "10493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number you entered will be changed to a secure number and delivered to the hotel.<br>However, only in cases where it is difficult to process the secure number, the actual mobile phone number may be delivered based on the consent to provide personal information on a limited basis."])},
  "10494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total 0 KRW discount"])},
  "10495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total KRW discount"])},
  "10496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point available"])},
  "10497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad's special discount"])},
  "10498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount for Korean reserve, former, active-duty soldiers/executives certified!"])},
  "10499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% discount for Nara Sarang members"])},
  "10500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even if you choose not to issue, a cash receipt will be issued voluntarily."])},
  "10501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Date"])},
  "10502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete cancellation history"])},
  "10503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservation complete"])},
  "10504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation is complete."])},
  "10505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected gain"])},
  "10506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Points/stamps will be issued 24 hours after check-out."])},
  "10507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Stamps are not awarded for Day use)"])},
  "10508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation information"])},
  "10509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete reservation history"])},
  "10510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing day use hours"])},
  "10511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation and Refund Policy"])},
  "10512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "10513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
  "10514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book now"])},
  "10515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a new leisure ticket"])},
  "10516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can meet a trip full of pleasure."])},
  "10517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See new leisure tickets"])},
  "10518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find the accommodation you are interested in even faster!"])},
  "10519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the new accommodation"])},
  "10520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a new accommodation"])},
  "10521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All photos"])},
  "10522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main photo"])},
  "10523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk"])},
  "10524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk"])},
  "10525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant discount coupon"])},
  "10526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max KRW coupon confirmation"])},
  "10527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon for regular"])},
  "10528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change the date"])},
  "10529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
  "10530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "10531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation summary"])},
  "10532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "10533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities and Services"])},
  "10534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation notice"])},
  "10535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no registered reviews"])},
  "10536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "10537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select room"])},
  "10538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollywood Room"])},
  "10539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room facilities"])},
  "10540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fitness"])},
  "10541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sauna"])},
  "10542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spa"])},
  "10543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming pool"])},
  "10544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["golf practice"])},
  "10545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salon"])},
  "10546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conference room"])},
  "10547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["air cleaner"])},
  "10548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bar"])},
  "10549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restaurant"])},
  "10550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
  "10551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this room cannot be reserved for customers using a car."])},
  "10552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
  "10554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight"])},
  "10556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours of use"])},
  "10557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operating hours"])},
  "10558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day use"])},
  "10559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "10560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till here"])},
  "10561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the location, date, or number of people"])},
  "10562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accommodations that match the conditions"])},
  "10563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommand"])},
  "10564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "10565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended nearby hotels by Hotel Salad"])},
  "10566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The perfect place to dream"])},
  "10567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended accommodations by location"])},
  "10568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We introduce places recommended by Hotel Salad every month."])},
  "10569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full review"])},
  "10570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please visit this place and be the first to review!"])},
  "10571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View photo reviews only"])},
  "10572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["button area"])},
  "10573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular searches"])},
  "10574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recent searches"])},
  "10575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hanok"])},
  "10576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find the results you're looking for?"])},
  "10577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How about these search words?"])},
  "10578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "10579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeo su"])},
  "10580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water skiing"])},
  "10581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonju"])},
  "10582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water play"])},
  "10583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sokcho"])},
  "10584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", if you want to heal privately"])},
  "10585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation location"])},
  "10586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date of stay"])},
  "10587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of guests"])},
  "10588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
  "10589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])},
  "10590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your own accommodation"])},
  "10591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrow"])},
  "10592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "10593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "10594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "10595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "10596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "10597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "10598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "10599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "10600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "10601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunny"])},
  "10602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloudy"])},
  "10603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blur"])},
  "10604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rain"])},
  "10605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow"])},
  "10606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow/Rain"])},
  "10607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per night"])},
  "10608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Throw away everything and leave to #Busan"])},
  "10609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's In-Car campling time"])},
  "10610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide for in-car campling beginners"])},
  "10611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongchang Cafe Barcelo"])},
  "10612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotional Cafe Tour! Romantic restaurant"])},
  "10613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the romance of the night to you"])},
  "10614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A town only I know / Chungju"])},
  "10615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water play in autumn"])},
  "10616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best 3 domestic emotional pool villas"])},
  "10617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRW't you go see the stars with me?"])},
  "10618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astronomical observatories nationwide"])},
  "10619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The star of gourmet in Jeju"])},
  "10620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju Island's Star Chef's Restaurant"])},
  "10621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even if it's cold, it's too cold!"])},
  "10622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How about lounging around while watching Netflix?"])},
  "10623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History tour for history buffs"])},
  "10624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miryang Historic Site Wolyeonjeong"])},
  "10625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's time travel to Gunsan!"])},
  "10626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mysterious Gunsan Time Travel Village"])},
  "10627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuncheon's specialty potato bread"])},
  "10628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No one ate it just once!"])},
  "10629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a destination"])},
  "10630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is the weather this week when your steps are lighter?"])},
  "10631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Stays Now"])},
  "10632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military identity verification inquiry"])},
  "10633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After registering as a member of the Nara Sarang portal  Discounts will be applied only upon completion."])},
  "10634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nara Sarang portal Certification"])},
  "10635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male members aged 20-35 years"])},
  "10636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive Certification"])},
  "10637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active duty executives, military officials, public officials of the Ministry of National Defense"])},
  "10638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single pass"])},
  "10639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video recording not included"])},
  "10640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Now"])},
  "10641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the coupon code again."])},
  "10642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Coupon Close"])},
  "10643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply coupon"])},
  "10644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General coupon"])},
  "10645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no general coupons available"])},
  "10646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unchecked"])},
  "10647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount "])},
  "10648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate coupon"])},
  "10649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no duplicate coupons available"])},
  "10650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Coupon"])},
  "10651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation coupon information"])},
  "10652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This coupon can be used immediately when making a reservation."])},
  "10653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon notice"])},
  "10654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This coupon cannot be used for payment in person or for non-members."])},
  "10655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This coupon remains as an immediate discount coupon that can be used at the time of reservation.  If there is a quantity left, you can select it on the reservation page and use it."])},
  "10656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the number of coupons generated, it may close early."])},
  "10657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This benefit may be changed/cancelled depending on the circumstances of affiliated stores and hotel salads."])},
  "10658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Coupon Application"])},
  "10659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon application details"])},
  "10660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Coupon Discount"])},
  "10661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
  "10662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby reservation"])},
  "10663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
  "10664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latest"])},
  "10665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot place"])},
  "10666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel"])},
  "10667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restaurant"])},
  "10668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filter"])},
  "10669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select themes"])},
  "10670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Amenities"])},
  "10671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "10672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filters"])},
  "10673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Reset"])},
  "10674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guest room"])},
  "10675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close image"])},
  "10676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
  "10677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
  "10678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "10679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select number of guests"])},
  "10680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the number of people to stay."])},
  "10681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "10682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
  "10683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event·Promotion"])},
  "10684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer center"])},
  "10685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "10686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "10687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the previous screen?"])},
  "10688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information you entered will be lost."])},
  "10689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "10690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has been canceled."])},
  "10691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A payment error has occurred.  please try again."])},
  "10692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete?"])},
  "10693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The record will be deleted from the list."])},
  "10694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please try again after logging in"])},
  "10695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to use the service"])},
  "10696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
  "10697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to deselect?"])},
  "10698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product will be removed from the pick list."])},
  "10699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deselect"])},
  "10700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report completed."])},
  "10701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a review"])},
  "10702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report it"])},
  "10703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to connect SNS login with this account?"])},
  "10704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connect"])},
  "10705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no coupons selected"])},
  "10706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea Accommodation Fastival"])},
  "10707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with self-authentication"])},
  "10709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't want to proceed with self-authentication"])},
  "10710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID: Accommodation competition discount coupon has been issued to "])},
  "10711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are already issued coupons."])},
  "10712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go to reservation"])},
  "10713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter coupon issuance information"])},
  "10714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※Please select the area where you currently reside"])},
  "10715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province / City"])},
  "10716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
  "10717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/County/Gu"])},
  "10718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to use of personal information"])},
  "10719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirming and agreeing to the terms and conditions regarding the provision of personal information is essential for issuance of the Accommodation Competition coupon."])},
  "10720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fully agree to the terms and conditions"])},
  "10721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get first-come-first-served"])},
  "10722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share"])},
  "10723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share KakaoTalk"])},
  "10724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share SMS"])},
  "10725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link copy"])},
  "10726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad Recommendations"])},
  "10727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By distance"])},
  "10728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By reviews"])},
  "10729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By random"])},
  "10730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By low price (Day use)"])},
  "10731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By high price (Day use)"])},
  "10732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By low price (Stay)"])},
  "10733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By high price (Stay)"])},
  "10734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to personal Information Collection and Usage"])},
  "10735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to cancellation/refund policy"])},
  "10736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
  "10737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Policy"])},
  "10738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point policy"])},
  "10739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept the rules for using accommodation products"])},
  "10740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent to provide personal information to third parties"])},
  "10741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can refuse to consent to the provision of personal information to a third party, and in this case, you cannot use the service."])},
  "10742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day use time (maximum hours)"])},
  "10743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close means of visit"])},
  "10744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Means of visit"])},
  "10745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On foot"])},
  "10746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
  "10747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking is currently unavailable."])},
  "10748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business information"])},
  "10749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROONET Inc."])},
  "10750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C.E.O : Park Ki-Hyun"])},
  "10751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business registration number: 215-87-14179"])},
  "10752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online sales lisence number: 2021-Seoul Mapo-2542"])},
  "10753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address: #513, 5th floor, K Square, 161 Yanghwa-ro, Mapo-gu, Seoul (Donggyo-dong)"])},
  "10754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Center : 02-407-8640"])},
  "10755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Hours: 09:00 ~ 18:00"])},
  "10756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail: csroonet.co"])},
  "10757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting service provider: ROONET Inc."])},
  "10758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "10759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisement/Affiliate Inquiry"])},
  "10760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "10761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open/Close menu"])},
  "10762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "10763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEARBY"])},
  "10764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "10765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY SALAD"])},
  "10766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An item has been added to your shopping cart."])},
  "10767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcuts"])},
  "10768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select conditions"])},
  "10769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to pick list"])},
  "10770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "10771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your ID."])},
  "10772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password."])},
  "10773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old and above"])},
  "10774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "10775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "10776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected all  "])},
  "10777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your reservation No.  "])},
  "10778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile phone No.  "])},
  "10779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleae enter the verification code.  "])},
  "10780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID(email).  "])},
  "10781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter password  "])},
  "10782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code  "])},
  "10783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password again  "])},
  "10784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us why you want to delete your account.  "])},
  "10785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 1 million KRW in the previous year <br> AND 20 or more purchases  "])},
  "10786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50% discount on accommodation (5 times in total)  "])},
  "10787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ All membership levels are maintained for one year.  "])},
  "10788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available coupons  "])},
  "10790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ID or password is not valid. Please enter it again."])},
  "10791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to all the terms and conditions."])},
  "10792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed."])},
  "10793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to all the terms and conditions."])},
  "10794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your inquiry has been received."])},
  "10795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to all necessary agreements."])},
  "10796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication number does not match. Please check again."])},
  "10797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The input time has been exceeded. Please press the Resend button."])},
  "10798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication number has been re-sent. If the authentication number does not come, please check the information you entered요."])},
  "10799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I sent the authentication number again."])},
  "10800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your membership has been withdrawn."])},
  "10801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You failed to withdraw your membership."])},
  "10802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact authentication number does not match."])},
  "10803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the duplicate nicknames."])},
  "10804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already using it."])},
  "10805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
  "10806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password between 8 and 20 characters including English and numeric characters.."])},
  "10807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral code copied."])},
  "10808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name of the reservation."])},
  "10809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your mobile number."])},
  "10810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the visitor name."])},
  "10811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the visitor's mobile number."])},
  "10812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a method of visiting the property."])},
  "10813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a payment method."])},
  "10814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to the collection of cash receipt information."])},
  "10815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to all terms and conditions."])},
  "10816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purchase cost is 0 KRW or less."])},
  "10817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to make a reservation?"])},
  "10818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication succeeded."])},
  "10819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authentication failed."])},
  "10820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no area. Please contact the manager."])},
  "10821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation time is not available."])},
  "10822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem has occurred."])},
  "10823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to stay on the same date."])},
  "10824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search term."])},
  "10825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the coupon number"])},
  "10826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write at least 10 characters"])},
  "10827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your cell phone number"])},
  "10828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password."])},
  "10829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell me why you want to delete your account."])},
  "10830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your nickname"])},
  "10831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password again"])},
  "10832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the inquiry type"])},
  "10833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title"])},
  "10834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write down your honest opinion. <br><br> When writing a text review: 30P immediate payment<br> When writing a photo review: 50P immediate payment"])},
  "10835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a name"])},
  "10836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by region and product name"])},
  "10837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people are leaving?"])},
  "10838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you going to travel?"])},
  "10839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you leave?"])},
  "10840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business information"])},
  "10841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
  "10842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business registration number"])},
  "10843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail order number"])},
  "10844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "10845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "10846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operating time"])},
  "10847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
  "10848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hosting service provider"])},
  "10849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "10850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "10851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Location Info"])},
  "10852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["youth protection policy"])},
  "10853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD / partnership inquiries"])},
  "10854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Salad is a mail order broker and is not a party to mail order,<br>Each seller is responsible for the reservation, use, and refund of the product."])},
  "10855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "10856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a<br>new accommodation"])},
  "10857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find the accommodation you are interested in even faster!"])},
  "10858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the new accommodation"])},
  "10859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit"])},
  "area00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "area01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul"])},
  "area02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busan"])},
  "area03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon"])},
  "area04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju"])},
  "area05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daejeon"])},
  "area06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daegu"])},
  "area07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulsan"])},
  "area10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "area11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["match"])},
  "area12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "area13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "area14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "area15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "area16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "area17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "area18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "area0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "area0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Seoul"])},
  "area0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangnam/Yeoksam/Seocho/Samsung"])},
  "area0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangdong/Cheonho/Gildong/Dunchon/Bangi/Shincheon"])},
  "area0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangbuk/Mia/Seongbuk/Suyu/Wolgok"])},
  "area0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo/Kkachisan Station"])},
  "area0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwanak/Dongjak/Sadang/Seoul National University/Silrim/"])},
  "area0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guro/Geumcheon/Sindorim/Error"])},
  "area0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeonmok / Sangbong / Jungnang / Taereung / Nowon / Dobong / Changdong"])},
  "area0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeongdong/Hoehyeon/Itaewon/Yongsan/Jung-gu"])},
  "area0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokdong/Hwagok/Yangcheon"])},
  "area0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seodaemun/Mapo/Eunpyeong"])},
  "area0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seolleung/Shinsa/Nonhyeon/Bangbae"])},
  "area0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songpa/Jamsil/Saenae"])},
  "area0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinchon/Hapjeong/Hongdae/Bulgwang/Yeonsinnae/Eungam"])},
  "area0114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeouido/Yeongdeungpo"])},
  "area0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wangsimni/Seongsu/Geumho/Chungmuro/Sindang/Yaksu"])},
  "area0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoegi/Anam/Cheongnyangni/Sinseol/Dongdaemun"])},
  "area0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan-dong/Dapsimni/Kondae/Gunja/Guui/Gwangjin"])},
  "area0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongno / Daehakro / Sungshin Women's University"])},
  "area0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Busan area"])},
  "area0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo-gu (Myeongji, Shinho) / Buk-gu (Deokcheon, Mandeok, Gupo, Hwahwa, Buk-gu)"])},
  "area0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geumjeong-gu (Busan National University, Guseo, Yeonsan, Togok) / Dongnae-gu (Oncheonjang, Sajik)"])},
  "area0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captain (Captain/Cheonggwan)"])},
  "area0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Gyeongseong University, Daeyeon, Yongho, Munhyeon) / Jung-gu (Nampo-dong) / Dong-gu (Busan Station, Beomil-dong) / Yeongdo-do (Yeongdo)"])},
  "area0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busanjin-gu (Seomyeon, Yangjeong, Choeup)/Sasang/Yeonje"])},
  "area0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Songdo)/Saha-gu (bottom, Saha)"])},
  "area0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haeundae-gu (Songjeong/Suyeong-gu (Gwangalli, Millak)/Resong"])},
  "area0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Incheon"])},
  "area0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganghwa/Ongjin/Jung-gu/Baengnyeongdo"])},
  "area0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu/Michuhol/Namdong-gu"])},
  "area0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongam/tidal stone"])},
  "area0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bupyeong/Gyeyang/Seogu/Juan/Geomdan"])},
  "area0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songdo/Yeonsu"])},
  "area0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolmi Island/Chinatown/Sinpo/Dongincheon"])},
  "area0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon Airport/Eulwang-ri"])},
  "area0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gwangju area"])},
  "area0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine area"])},
  "area0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangsan-gu Hanam/Songjeong Station"])},
  "area0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu"])},
  "area0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu"])},
  "area0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu"])},
  "area0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western"])},
  "area0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Daejeon"])},
  "area0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daedeok-gu (Shintanjin/Jungri)"])},
  "area0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Yongjeon/Complex Terminal)"])},
  "area0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Dunsan/Yongmun/Wolpyeong)"])},
  "area0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteor sphere (Meteor Seal/Design/Pole)"])},
  "area0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Jung-gu Bank/Daeheung/Seonhwa/Yucheon)"])},
  "area0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Daegu area"])},
  "area0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Daemyung/Bongdeok/Seobu Bus Stop)"])},
  "area0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseo-gu (Duryu Park/Duryu/Bonri/Jukjeon/Gamsam/Seongseo Industrial Complex/Kyemyung University)"])},
  "area0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseong-gun"])},
  "area0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Dongdaegu Station/Shinam/Shincheon/Deungchon Amusement Park/Daegu Airport/Innovation City)"])},
  "area0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu (Daegu Station/EXCO/Chilgok 3 District/Taejeon-dong)"])},
  "area0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Pyeongri/Naedang/Bisan/Wondae)"])},
  "area0607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suseong-gu (Deulan-gil/Suseong-mot)"])},
  "area0608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Kyungbuk National University/Dongseong-ro/City Hall/Seomun Market)"])},
  "area0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Ulsan"])},
  "area0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu/Dong-gu"])},
  "area0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulju-gun"])},
  "area0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu/Buk-gu"])},
  "area1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gangwon area"])},
  "area1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangneung/Donghae/Samcheok"])},
  "area1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goseong/Sokcho/Yangyang"])},
  "area1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanggu/Inje/Cheorwon/Hwacheon"])},
  "area1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonju/Hoengseong"])},
  "area1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuncheon/Hongcheon"])},
  "area1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongchang/Jeongseon/Youngwol/Taebaek"])},
  "area1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All match area"])},
  "area1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gapyeong/Cheongpyeong/Yangpyeong"])},
  "area1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goyang/Ilsan/Gimpo/Janggi/Gurae/Daemyeong Port"])},
  "area1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwacheon/Indeokwon"])},
  "area1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju/Yeoju/Icheon"])},
  "area1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namyangju/Guri/Uijeongbu/Hanam"])},
  "area1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byeongjeom/Osan/Dongtan"])},
  "area1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bucheon"])},
  "area1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gyuun/Seryu/Suwon Station/Jangan/Paldalmun)"])},
  "area1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gwonseon/Youngtong/Ingye)"])},
  "area1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siheung/Gwangmyeong"])},
  "area1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansan/Daebudo"])},
  "area1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyang/Pyeongchon/Gunpo/Uiwang/Geumjeong/Sanbon"])},
  "area1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paju/Geumchon/Heyri/Unification Garden"])},
  "area1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongtaek/Songtan/Anseong"])},
  "area1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pocheon/Yangju/Dongducheon/Yeoncheon/Jangheung"])},
  "area1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanam/Seongnam/Bundang/Yongin"])},
  "area1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hwaseong/Jebudo"])},
  "area1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongnam"])},
  "area1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geoje/Tongyeong/Goseong"])},
  "area1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geochang/Hamyang/Hapcheon/Sancheong/Changnyeong"])},
  "area1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimhae/Yangsan/Miryang/Jangyu"])},
  "area1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinju/Namhae/Sacheon/Hadong"])},
  "area1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Masan, Jinhae)"])},
  "area1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Myeongseo, Palyong, Bonggok, Buk-myeon)"])},
  "area1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Sangnam, Yongho, Jungang)"])},
  "area1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haman/Uiryeong"])},
  "area1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongbuk"])},
  "area1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongju/Yeongcheon/Gyeongsan/Cheongdo"])},
  "area1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimcheon/Seongju/Goryeong/Chilgok/Gumi/Gunwi"])},
  "area1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mungyeong/Sangju/Yecheon/Yeongju/Bonghwa"])},
  "area1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andong / Uiseong / Cheongsong / Yeongdeok / Yeongyang / Uljin"])},
  "area1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu, Pohang"])},
  "area1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu, Pohang"])},
  "area1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeollanam-do"])},
  "area1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangjin/Haenam/Wando/Jindo"])},
  "area1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gurye/Gokseong/Damyang/Jangseong/Yeonggwang/Hampyeong"])},
  "area1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokpo/Muan/Shinan"])},
  "area1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boseong/Goheung/Jangheung"])},
  "area1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeosu/Gwangyang/Suncheon"])},
  "area1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youngam/Naju/Hwasun"])},
  "area1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All regions of Jeollabuk-do"])},
  "area1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunsan/Gimje"])},
  "area1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muju/Jinan/Jangsu"])},
  "area1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buan/Jeongeup/Gochang"])},
  "area1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imsil/Namwon/Sunchang"])},
  "area1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonju/Iksan/Wanju"])},
  "area1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeju"])},
  "area1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seogwipo/Marado"])},
  "area1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aewol/Hyupjae"])},
  "area1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju City"])},
  "area1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Chungcheongnam-do"])},
  "area1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeryong/Gongju/Nonsan/Geumsan"])},
  "area1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boryeong/Buyeo/Seocheon/Daecheon"])},
  "area1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesan/Hongseong/Cheongyang"])},
  "area1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheonan/Asan"])},
  "area1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Chungnam/Sejong"])},
  "area1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taean/Dangjin/Seosan/Anmyeondo"])},
  "area1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the whole of Chungbuk"])},
  "area1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeun/Okcheon/Youngdong"])},
  "area1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jecheon/Danyang"])},
  "area1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheongju/Boeun/Jincheon"])},
  "area1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungju/Eumseong/Goesan/Suanbo/Jungpyeong"])},
  "weather00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul Gyeonggi"])},
  "weather01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "weather02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "weather03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "weather04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "weather05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "weather06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "weather07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "weather08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "day월": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "day화": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "day수": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "day목": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "day금": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "day토": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "day일": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "dayMo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "dayTu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "dayWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "dayTh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "dayFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "daySa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "daySu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
}