// import api from "@/urls/mhm02";
import api from "@/urls/urls_all";
const state = {
  mhm02: [],
  // mhm021: [],
};

const getters = {};

const mutations = {
  SET_MHM02(state, { mhm02 }) {
    state.mhm02 = mhm02;
  },
  // SET_MHM021(state, { mhm021 }) {
  //   state.mhm021 = mhm021;
  // },
};

const actions = {
  async fetchMhm02({ commit }, params) {
    const data = await api.getMhm02(params);
    commit("SET_MHM02", { mhm02: data.data.Res_data });
    return data;
  },
  // async fetchMhm021({ commit }, input) {
  //   const data = await api.getMhm021(input);
  //   commit("SET_MHM021", { mhm021: data.data.Res_data });
  //   return data;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
