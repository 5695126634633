<template>
	<div id="navigation" class="navigation">
		<div class="navigation-menu">
			<!-- [D] 활성화된 메뉴에 active 클래스 추가 -->
			<router-link to="/" :class="{active: path === '/' || path === '/rooms' || path === '/feed'}">
				<i class="icon icon-home icon-home--light"></i>
				<span>{{t('10762')}}</span>
			</router-link>
			<router-link to="/around/rooms" :class="{active: path === '/around/rooms'}">
				<i class="icon icon-location icon-location--light"></i>
				<span>{{t('10763')}}</span>
			</router-link>
			<router-link to="/search" :class="{active: path === '/search'}">
				<i class="icon icon-search icon-search--light"></i>
				<span>{{t('10764')}}</span>
			</router-link>
			<a @click="handlePick" :class="{active: path === '/pick/rooms'}">
				<i class="icon icon-pick icon-pick--light"></i>
				<span>{{t('10015')}}</span>
			</a>
			<router-link to="/mypage" :class="{active: path === '/mypage'}">
				<i class="icon icon-member icon-member--light"></i>
				<span>{{t('10765')}}</span>
			</router-link>
		</div>
	</div>
	<MessageLogin v-if="openLogin" :handleClose="handleCloseLogin" :handleSubmit="handleSubmit" />
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
import MessageLogin from '@/components/layers/Message_login';

export default {
	components: {
		MessageLogin,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const openLogin = ref(false);
		const { t }= useI18n() //번역필수 모듈
		const handlePick = () => {
			if(!isLogin.value) {
				openLogin.value = true;
			}else {
				router.push("/pick/rooms");
			}
		}
		return {
			path: computed(() => route.path),
			handleCloseLogin() {
				openLogin.value = false;
			},
			openLogin,
			handlePick,
			i18n,
			handleSubmit: async () => {
				router.push("/layers/login");
			},
			t
		}
	},
}
</script>