const state = {
  orderItems: [],
};

const getters = {};

const mutations = {
  SET_ORDER_ITEMS(state, { orderItems }) {
    state.orderItems = orderItems;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
