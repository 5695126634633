//import api from "@/urls/mmb02";
import api from "@/urls/urls_all";

const state = {
  mmb02: [],
  mmb0201: [],
};

const getters = {};

const mutations = {
  SET_MMB02(state, { mmb02 }) {
    state.mmb02 = mmb02;
  },
  SET_MMB02_01(state, { mmb02_01 }) {
    state.mmb02_01 = mmb02_01;
  },
};

const actions = {
  async fetchMmb02({ commit }) {
    const data = await api.getMmb02();
    commit("SET_MMB02", { mmb02: data.data.Res_data });
  },
  async fetchMmb02_01({ commit }, input) {
    const data = await api.getMmb0201(input);
    commit("SET_MMB02_01", { mmb0201: data.data.Res_data });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
