import { createLogger, createStore } from "vuex";
import uuidv4 from "../utils/uuid";
import modules from "./modules";
import createPersistedState from 'vuex-persistedstate'

const debug = process.env.NODE_ENV !== "production";

const state = {
  sessionId : 
    localStorage.getItem("sessionId") || 
    localStorage.setItem("sessionId", uuidv4()) || 
    localStorage.getItem("sessionId"),
  userData: JSON.parse(localStorage.getItem("userData") || "{}")
};

const getters = {};

const mutations = {
  SET_USERDATA(state, { userData }) {
    localStorage.setItem("userData", JSON.stringify(userData))
    state.userData = userData;
  },
};

const actions = {};

const store = createStore({
  state,
  getters,
  actions,
  mutations,
  modules,
  strict: debug,
  plugins: debug ? [createLogger(),createPersistedState()] : [createPersistedState()],
});

export default store;
