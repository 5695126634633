// import api from "@/urls/mhm01";
import dayjs from "dayjs";
import api from "@/urls/urls_all";

const state = {
  mhm0101: [],
  mhm0102: [],
  mhm0103: [],
  mhm0104: [],
  selectedLocation: null,
  selectedDateRange: null,
  selectedMember: null,
  weather: [],
  mhm0101Loading: true,
  mhm0102Loading: true,
  mhm0104Loading: true,
};

const getters = {};

const mutations = {
  SET_MHM0101(state, { mhm0101 }) {
    state.mhm0101 = mhm0101;
    state.mhm0101Loading = false;
  },
  SET_MHM0102(state, { mhm0102 }) {
    state.mhm0102 = mhm0102;
    state.mhm0102Loading = false;
  },
  SET_MHM0104(state, { mhm0104 }) {
    state.mhm0104 = mhm0104;
    state.mhm0104Loading = false;
  },
  SET_MHM0103(state, { mhm0103 }) {
    state.mhm0103 = mhm0103;
  },
  SET_SELECT_LOCATION(state, { location }) {
    state.selectedLocation = location;
  },
  SET_SELECT_DATE(state, { dateRange }) {
    state.selectedDateRange = dateRange;
  },
  SET_MEMBER(state, { member }) {
    state.selectedMember = member;
  },
  SET_WEATHER(state, { weather }) {
    state.weather = weather;
  },
  RESET_ALL(state) {
    state.mhm0101 = [];
    state.mhm0102 = [];
    state.mhm0103 = [];
    state.mhm0104 = [];
    state.selectedLocation = null;
    state.selectedDateRange = null;
    state.selectedMember = null;
    state.weather = [];
    state.mhm0101Loading = true;
    state.mhm0102Loading = true;
    state.mhm0104Loading = true;
  },
};

const actions = {
  async fetchMhm0101({ commit },{ lang }) {
    const data = await api.getMhm0101({ proc_cd: "01",lang });
    commit("SET_MHM0101", { mhm0101: data.data.Res_data });
  },
  async fetchMhm0102({ commit }, { proc_cd, login_id,lang }) {
    const data = await api.getMhm0102({ proc_cd, login_id,lang });
    commit("SET_MHM0102", { mhm0102: data.data.Res_data });
  },
  async fetchMhm0104({ commit }, { proc_cd, login_id }) {
    const data = await api.getMhm0104({ proc_cd, login_id });
    commit("SET_MHM0104", { mhm0104: data.data.Res_data });
  },
  async fetchWeather({ commit }, { regId }) {
    let date = dayjs();
    if (date.hour() < 6) {
      date = date.subtract(1, "day");
    }
    const formatedDate = date.format("YYYYMMDD");
    const time = date.hour() > 18 || date.hour() < 6 ? "1800" : "0600";
    const tmFc = formatedDate + time;
    //console.log(tmFc);
    const data = await api.getWeather({ regId, tmFc });

    const weatherKeys = ["wf3Pm", "wf4Pm", "wf5Pm", "wf6Pm", "wf7Pm", "wf8"];
    const getWeatherId = (text) => {
      switch (text) {
        case "맑음":
          return 1;
        case "구름많음":
          return 2;
        case "흐림":
          return 3;
        case "소나기":
        case "흐리고 비":
        case "흐리고 소나기":
        case "구름많고 비":
        case "구름많고 소나기":
          return 4;
        case "구름많고 눈":
        case "흐리고 눈":
          return 5;
        case "구름많고 비/눈":
        case "흐리고 비/눈":
          return 6;
        default:
          return 6;
      }
    };
    const weather = weatherKeys.map((key, index) => ({
      id: getWeatherId(data.data.response.body.items.item[0][key]),
      date: date.add(index, "day"),
    }));
    commit("SET_WEATHER", { weather });
  },
  async fetchMhm0103({ commit }, { proc_cd = "01" }) {
    const data = await api.getMhm0103({ proc_cd });
    commit("SET_MHM0103", { mhm0103: data.data.Res_data });
    return data.data.Res_data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
