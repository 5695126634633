export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国語"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊"])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTEL"])},
  "HO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOTEL"])},
  "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PENSION"])},
  "RT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESORT"])},
  "CP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPING"])},
  "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUESTHOUSE"])},
  "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GLAMPING"])},
  "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンナム / ノンヒョン / サムソン"])},
  "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊"])},
  "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジャー/チケット"])},
  "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティビティー"])},
  "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマパーク"])},
  "10006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公演/展示"])},
  "10007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォーターパーク/スパ"])},
  "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン割引が可能な宿所"])},
  "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿所"])},
  "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1万ウォン割引クーポン"])},
  "10011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダイナミックトランポリン体験"])},
  "10012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ソウル] ヨクサムバウンストランポリン"])},
  "10013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の位置から15km"])},
  "10014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当日使用可能 未使用全額払い戻し"])},
  "10015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コック"])},
  "10016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソウルノンヒョン洞"])},
  "10017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター "])},
  "10018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺にレジャー/チケットがありません。"])},
  "10019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの様々なレジャー·チケットに出会い、 レジャー·チケットを購入してみてください！"])},
  "10020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジャー·チケットを見る"])},
  "10021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モーテル"])},
  "10022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテル"])},
  "10023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペンション"])},
  "10024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リゾート"])},
  "10025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンピング/グランピング"])},
  "10026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺に宿所がありません。"])},
  "10027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの様々な宿所に出会い、 宿所を予約してみてください!"])},
  "10028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿所を見る"])},
  "10029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲストハウス"])},
  "10030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前のページ"])},
  "10031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "10032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告知"])},
  "10033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録された告知がありません。"])},
  "10034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提携のお問い合わせ"])},
  "10035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
  "10036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
  "10037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "10038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
  "10039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
  "10040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報の収集及び利用に同意(必須)"])},
  "10042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約款および政策"])},
  "10043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用約款"])},
  "10044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報処理方針"])},
  "10045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報利用約款"])},
  "10046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青少年保護政策"])},
  "10047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント企画展"])},
  "10048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韓民国宿泊大展事前認証イベント"])},
  "10049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連宿所"])},
  "10050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1万ウォン割引クーポン"])},
  "10051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先着順割引クーポン"])},
  "10052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幻想的なルーフトップはありますか,ありませんか"])},
  "10053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全州韓屋エコビレッジペンション"])},
  "10054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヨクサム駅1番出口から145m"])},
  "10055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大貸室5時間客室保有"])},
  "10056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室5時間"])},
  "10057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊18時から"])},
  "10058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
  "10059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンナムモーテル"])},
  "10060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セルフチェックイン"])},
  "10061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行きつけのクーポン"])},
  "10062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
  "10063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平日無限貸室イベント! 最長10時間"])},
  "10064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン"])},
  "10065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
  "10066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企画展"])},
  "10067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在実施中のイベントがありません。"])},
  "10068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在実施中の企画展がありません。"])},
  "10069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の好み入力完了！"])},
  "10070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの好みを狙撃する旅行地をおすすめします。"])},
  "10071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに宿を予約します。"])},
  "10072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どんな旅行地が好きですか。"])},
  "10073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好きな旅行先を3つまで、ピック！"])},
  "10074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の好みを教えてください"])},
  "10075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の好みを5つまで、ピック！"])},
  "10076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰と、最低1つを選択"])},
  "10077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行スタイル、最低1つ選択"])},
  "10078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "10079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺の予約"])},
  "10080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["良い"])},
  "10081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約詳細"])},
  "10082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非会員の予約照会"])},
  "10083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日時"])},
  "10084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品情報"])},
  "10085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合予約番号"])},
  "10086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号"])},
  "10087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿所"])},
  "10088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォン"])},
  "10089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで無料キャンセル可能"])},
  "10090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者情報"])},
  "10091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
  "10092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "10093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用者情報"])},
  "10094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿の訪問手段"])},
  "10095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額及び決済情報"])},
  "10096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済手段"])},
  "10097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約金額"])},
  "10098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン"])},
  "10099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント"])},
  "10100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提携割引"])},
  "10101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済金額"])},
  "10102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約履歴がありません。"])},
  "10103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約情報をもう一度ご確認ください"])},
  "10104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非会員の予約照会"])},
  "10105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文の際、受け取った予約番号と電話番号を入力してください。"])},
  "10106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効な予約番号です。 もう一度確認をお願いします。"])},
  "10107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約照会"])},
  "10108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録完了!"])},
  "10109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに宿を予約します。"])},
  "10110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の好み入力"])},
  "10111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の内容を確認して旅行を準備してみましょう。"])},
  "10112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体同意"])},
  "10113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["満14歳以上使用(必須)"])},
  "10114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用約款(必須)"])},
  "10115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見る"])},
  "10116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報の収集及び利用に同意(必須)"])},
  "10117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報利用約款(選択)"])},
  "10118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特典通知受信に同意(選択)"])},
  "10119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次"])},
  "10120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話認証"])},
  "10121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["円滑なサービス提供のため、電話番号を入力してください。"])},
  "10122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証要請"])},
  "10123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人認証"])},
  "10124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号"])},
  "10125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再転送"])},
  "10126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号が一致しません。"])},
  "10127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人認証完了"])},
  "10128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報入力"])},
  "10129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイディー"])},
  "10130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複確認"])},
  "10131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに登録されたID(メール)です。"])},
  "10132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "10133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文含む"])},
  "10134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字含む"])},
  "10135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8~20文字以内"])},
  "10136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード確認"])},
  "10137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード一致"])},
  "10138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム"])},
  "10139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複確認"])},
  "10140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2~6文字以内"])},
  "10141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに登録されたニックネームです。"])},
  "10142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦人コード"])},
  "10143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "10144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦人コードが一致しません。"])},
  "10145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入完了"])},
  "10146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パラグライダー体験飛行の名家"])},
  "10147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[チュンブク]  タニャンパラグライダー"])},
  "10148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17人がピックした商品です。"])},
  "10149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期間:ご購入日より60日"])},
  "10150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当日使用可能 未使用全額払い戻し"])},
  "10151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細情報"])},
  "10152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額及び割引情報"])},
  "10153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品のご使用について"])},
  "10154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンのご購入(最低1日前には購入必須)  電話予約確認必須(010-0000-0000)  予約した日に利用"])},
  "10155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留意事項のご案内"])},
  "10157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理容後期"])},
  "10158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般室(Aタイプ)-宿泊"])},
  "10159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームA"])},
  "10160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドが本当に大きくて楽でよく休みました。  雰囲気もいいし施設もいいです。 ゆっくり休んでいきます。  次の休暇にも来ようと思ってます。"])},
  "10161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺情報"])},
  "10162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺レジャー·チケット"])},
  "10163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ソウルヨンドゥンポ]ヨンドゥンポ漢江公園  電動キックボードフリーパス最安値"])},
  "10164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊"])},
  "10165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺の宿"])},
  "10166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キングダムホテル"])},
  "10167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルオークラウド"])},
  "10168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入する"])},
  "10169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チュンブク全体"])},
  "10170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン割引が可能な宿泊"])},
  "10171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマパーク"])},
  "10172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公演/展示"])},
  "10173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォーターパーク/スパ"])},
  "10174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["祭り/イベント"])},
  "10175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通"])},
  "10176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマA"])},
  "10177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマB"])},
  "10178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレジャー·チケット"])},
  "10179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃える金曜日はみんなでビールパーティー"])},
  "10180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ソウル]漢江ビールパーティー"])},
  "10181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェジュのロマンを楽しみながら"])},
  "10182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[チェジュ]車泊レンタルセット"])},
  "10183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前"])},
  "10184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビバルディパークリフト券単独特価"])},
  "10185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間限定最大57%割引"])},
  "10186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新羅ステイホテルブランド特価イベント"])},
  "10187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダMDおすすめ秋の宿泊施設"])},
  "10188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週刊人気レジャー·チケット"])},
  "10189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家族と一緒に楽しめる"])},
  "10190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[カンウォン] 大関嶺羊牧場"])},
  "10191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファンタスティックなループトップがあります。"])},
  "10192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヨクサムバン"])},
  "10193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一番遊びやすい地域は？"])},
  "10194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソウル"])},
  "10195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プサン"])},
  "10196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェジュ"])},
  "10197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンウォン"])},
  "10198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キョンブク"])},
  "10199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キョンナム"])},
  "10200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チョルナム"])},
  "10201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幻想的な芸術作品がいっぱい"])},
  "10202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[プサン] ミュージアムDAH"])},
  "10203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企画展"])},
  "10204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企画展"])},
  "10205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冬はスキー·ボードの季節！"])},
  "10206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初歩者も気軽に楽しむ"])},
  "10207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ピョンチャン] デウォンリゾートスキー講習"])},
  "10208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すすめプレイス"])},
  "10209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソウルプレイス、ソウルの行列店をご紹介します。"])},
  "10210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内感性フルビラベスト3"])},
  "10211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠会員解除"])},
  "10212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長期未接続のため休眠会員転換状態です。"])},
  "10213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初加入日"])},
  "10214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終接続日"])},
  "10215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠処理日"])},
  "10217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再転送"])},
  "10218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠状態解除"])},
  "10219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠会員解除完了"])},
  "10220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常に休眠解除処理されました。"])},
  "10221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人認証方法選択"])},
  "10222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール認証"])},
  "10223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS認証"])},
  "10224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号を送信"])},
  "10225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID検索完了"])},
  "10226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員様が登録したID(メール)情報です。"])},
  "10227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入会員です。"])},
  "10228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簡単にログインしてください。"])},
  "10229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード探し"])},
  "10230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインする"])},
  "10231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID探し "])},
  "10232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更完了"])},
  "10233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの変更が完了しました。 ログインページに移動します。"])},
  "10234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDを確認した後、携帯電話本人認証を通じて パスワードを設定します"])},
  "10235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更"])},
  "10236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
  "10237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード確認"])},
  "10238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会処理が完了しました。"])},
  "10240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームへ"])},
  "10241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脱退時の注意事項"])},
  "10242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在使用中のアカウント情報はすべて削除されます。"])},
  "10244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント及びすべての特典が消滅します。"])},
  "10246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記の内容をすべて熟知し、脱退に同意します。"])},
  "10247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残念ですが、 別れる時間ですか。"])},
  "10248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これまでホテルサラダを楽しんでいただきありがとうございます。 より良いサービスを提供するために脱退する理由を教えてください"])},
  "10249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出"])},
  "10250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "10251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等級別特典のご案内"])},
  "10252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等級別に変わる特典を確認してみてください。"])},
  "10253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
  "10254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般会員"])},
  "10255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規加入したすべての会員"])},
  "10256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP会員"])},
  "10257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前年度の利用金額50万ウォン以上、そして購入件数10件以上"])},
  "10258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設20%割引特典(計2回)"])},
  "10259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設の予約履歴"])},
  "10260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問前"])},
  "10261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問後"])},
  "10262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル"])},
  "10263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル完了"])},
  "10264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号R1234"])},
  "10265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細内訳"])},
  "10266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鐘路レモンツリー"])},
  "10267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊デラックス"])},
  "10268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "10269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "10270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン"])},
  "10271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト"])},
  "10272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用完了"])},
  "10273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再予約"])},
  "10274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー作成"])},
  "10275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舎の内訳がありません。"])},
  "10276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約キャンセル"])},
  "10277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品情報"])},
  "10278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約番号:"])},
  "10279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン"])},
  "10280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックアウト"])},
  "10281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル情報"])},
  "10282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品金額"])},
  "10283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント使用"])},
  "10284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実際の決済金額"])},
  "10285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予想キャンセル料0ウォン"])},
  "10286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予想払い戻し金額0ウォン"])},
  "10287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記の内容を全て確認しました。"])},
  "10288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取り消す"])},
  "10289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物かご"])},
  "10290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体選択"])},
  "10291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択削除"])},
  "10292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基準"])},
  "10293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当時間の予約締切"])},
  "10294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計件"])},
  "10295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約する"])},
  "10296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物かごに入った商品がありません。"])},
  "10297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様々な宿泊施設を一度に予約してみてください!"])},
  "10298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を見る"])},
  "10299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン箱"])},
  "10300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様の使用可能なクーポン"])},
  "10301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のクーポン枚"])},
  "10302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能"])},
  "10303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用完了"])},
  "10304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%割引クーポン"])},
  "10305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワン割引クーポン"])},
  "10306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大ウォン割引"])},
  "10307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日まで"])},
  "10308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント終了時まで継続"])},
  "10309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの内訳がありません。"])},
  "10310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「ホテルサラダクーポン発行イベントに参加し、 クーポンをもらうことができます。"])},
  "10311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様の使用可能なクーポン"])},
  "10312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン登録"])},
  "10313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入会祝いクーポン"])},
  "10314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15%割引クーポン"])},
  "10315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録記念最大1万ウォン割引"])},
  "10316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.11.19日まで(D-3)"])},
  "10317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊対戦クーポン"])},
  "10318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIPクーポン"])},
  "10319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報修正"])},
  "10320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先変更"])},
  "10321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更"])},
  "10322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム変更"])},
  "10323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能なニックネームです。"])},
  "10324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードエラーです。"])},
  "10325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員脱退"])},
  "10326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1お問い合わせ内容"])},
  "10327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答弁"])},
  "10328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ事項が受け付けられました。 早いうちにご返事します。 ありがとうございます」"])},
  "10329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修整"])},
  "10330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
  "10331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目録へ"])},
  "10332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計件"])},
  "10333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録された1:1お問い合わせがありません"])},
  "10334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用中のご不明な点についてお問い合わせいただければ、早いうちにお答えいたします。"])},
  "10335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問い合わせタイプ"])},
  "10336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ内容"])},
  "10337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
  "10338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジャー·チケット購入内訳"])},
  "10339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用前"])},
  "10340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用後"])},
  "10341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入取り消し"])},
  "10342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル完了"])},
  "10343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期間:"])},
  "10344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2人、食事付き"])},
  "10345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再購入"])},
  "10346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP等級特典"])},
  "10347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おすすめコード[]計招待会員数1人"])},
  "10348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダポイント"])},
  "10349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン15枚"])},
  "10350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプ0/5"])},
  "10351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊予約内訳"])},
  "10352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジャー/チケット購入内訳"])},
  "10353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート"])},
  "10354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のレビュー。"])},
  "10355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近見た商品"])},
  "10356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行好み設定"])},
  "10357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の好みを入力して20%クーポンをもらう。"])},
  "10358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1お問い合わせ"])},
  "10359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["環境設定"])},
  "10360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダカスタマーセンター"])},
  "10361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話相談"])},
  "10362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーセンターの営業時間"])},
  "10363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年中無休10時~24時(ランチ)12時30分~13時30分"])},
  "10364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトークでのお問い合わせ"])},
  "10365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトーク顧客センター営業時間"])},
  "10366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年中無休10時~19時(ランチ)12時30分~13時30分"])},
  "10367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン/会員登録"])},
  "10368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録すると2,000ポイントすぐ支給！"])},
  "10369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦者コード"])},
  "10370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計招待会員数名"])},
  "10371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦者コードを入力するとポイントダブルを貯める！"])},
  "10372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のレビュー"])},
  "10373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿の評価"])},
  "10374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接利用してみてどうでしたか"])},
  "10375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 上記の内容は、該当業者にのみ公開されます。"])},
  "10376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
  "10377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成したレビュー履歴がありません。"])},
  "10378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの様々な宿泊施設を利用し、 レビューを作成してみてください！"])},
  "10379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルした宿の内訳がありません。"])},
  "10380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用した宿の内訳がありません。"])},
  "10381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約された宿の内訳がありません。"])},
  "10382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物かごに入った商品がありません。"])},
  "10383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能なクーポン履歴がありません。"])},
  "10384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問い合わせる"])},
  "10385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルしたレジャー·チケットがありません"])},
  "10386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご利用のレジャー·チケットの内訳がありません"])},
  "10387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの多様なレジャー·チケットリストを見て今レジャー·チケットを予約してみてください！"])},
  "10388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご購入いただいたレジャー·チケットの内訳がありません。"])},
  "10389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体"])},
  "10390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様の使用可能なポイント"])},
  "10391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残りポイント"])},
  "10392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7日"])},
  "10393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30日"])},
  "10394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90日"])},
  "10395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180日"])},
  "10396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["積立/使用可能なポイント履歴がありません。"])},
  "10397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今、ホテルサラダをおすすめするとポイントを得ることができます。"])},
  "10398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の推薦人コード:"])},
  "10399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近見た宿舎がありません。"])},
  "10400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの様々な宿に出会い今宿泊施設を予約してみてください！"])},
  "10401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近見たレジャー·チケットがありません。"])},
  "10402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダの様々なレジャー·チケット出会い今レジャー·チケットを購入してみてください！"])},
  "10403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私が招待した会員"])},
  "10404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様がご招待した会員数名"])},
  "10405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私が招待した会員がいません。"])},
  "10406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待して1000ポイントもらってください！"])},
  "10407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用完了したクーポン履歴がありません。"])},
  "10408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員様の大切な情報を安全に変更するためにパスワードの確認が必要です"])},
  "10409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["積立/使用日"])},
  "10410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントの内訳がありません。"])},
  "10411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全削除"])},
  "10412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[江原]大関嶺羊牧場"])},
  "10413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソウル江南区大峙洞891-35"])},
  "10414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
  "10415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入日"])},
  "10416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
  "10417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バージョン情報V1.0"])},
  "10418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プッシュ通知"])},
  "10419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセス権限設定"])},
  "10420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNS接続設定"])},
  "10421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "10422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続するID選択"])},
  "10423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNSアカウントを接続するIDを選択してください。"])},
  "10424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNSアカウントに接続する"])},
  "10425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオログイン"])},
  "10426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結"])},
  "10427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除する"])},
  "10428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネイバーログイン"])},
  "10429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップルログイン"])},
  "10430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプ"])},
  "10431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプカード"])},
  "10432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["積立内訳"])},
  "10433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様のスタンプ現況/5個"])},
  "10434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回"])},
  "10435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプ5回積立時5000P積立!"])},
  "10436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプの獲得方法"])},
  "10437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊退室24時間後、スタンプ1回自動積立！"])},
  "10438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタンプ注意事項のご案内"])},
  "10439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.退室後、スタンプが自動的に積み立てられます"])},
  "10440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(積立時点:退室日基準翌日00時適用)"])},
  "10441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.スタンプ5回積立時5000P積立!"])},
  "10442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.宿泊商品利用時のみスタンプが貯まります。"])},
  "10443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.支給されたポイントの有効期間は180日です。"])},
  "10444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.スタンプポリシーは状況によって異なることがあります。"])},
  "10445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累積積立ポイント[]p"])},
  "10446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["積立日"])},
  "10447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累積積立スタンプ3,000個"])},
  "10448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品情報件"])},
  "10449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワン"])},
  "10450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["102(個別プール+メゾネットタイプ)"])},
  "10451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021年12月13日(月)11:00~16:00"])},
  "10452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン後に割引特典を受けてください！"])},
  "10453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問者情報"])},
  "10454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実際にご来店の方情報を入力"])},
  "10455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者情報と同じです。"])},
  "10456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿の訪問手段"])},
  "10457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択する"])},
  "10458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊先の訪問手段を選択してください。"])},
  "10459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鐘路レモンツリー"])},
  "10460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徒歩"])},
  "10461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車両"])},
  "10462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慶州ビラ"])},
  "10463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["駐車が不可能な宿泊施設です。"])},
  "10464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額及び割引情報"])},
  "10465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインして割引特典を受けてください！"])},
  "10466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計決済金額"])},
  "10467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済手段の選択"])},
  "10468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット/デビットカード"])},
  "10469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["軽便な口座振替"])},
  "10470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恩恵"])},
  "10471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード"])},
  "10472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペイコ決済特典"])},
  "10473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5万ウォン以上決済の場合、5千ウォンすぐ割引!"])},
  "10474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所得控除"])},
  "10475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出証憑"])},
  "10476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未発行"])},
  "10477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金領収書カード番号"])},
  "10478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイントやクーポンをご利用の際、実際に現金で決済された金額のみ現金領収書が発行されます。"])},
  "10480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この決済手段を次にも使用"])},
  "10481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル不可と手数料"])},
  "10482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル及び払い戻しポリシーに基づいてキャンセル不可、手数料が発生することがあります。"])},
  "10483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未成年者及び法定代理人必須"])},
  "10484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未成年者は法定代理人の同行なしに宿泊が不可能です"])},
  "10485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用約款に全体同意します。"])},
  "10486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊商品の利用規則に同意(必須)"])},
  "10487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル/払い戻しポリシーに同意(必須)"])},
  "10488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報第3者提供に同意(必須)"])},
  "10489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご入力いただいた番号は安心番号に変更され、宿泊先へお届けします。ただし、安心番号の処理が困難な場合に限り、制限的に個人情報提供同意に基づき、実際の携帯番号がお伝達することがあります。"])},
  "10490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAY"])},
  "10491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枚"])},
  "10492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証変更"])},
  "10493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご入力いただいた番号は安心番号に変更され、宿泊先へお届けします。ただし、安心番号の処理が困難な場合に限り、制限的に個人情報提供同意に基づき、実際の携帯番号がお伝達することがあります。"])},
  "10494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計0ウォン割引"])},
  "10495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計ウォン割引"])},
  "10496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P保有"])},
  "10497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダだけの特別割引特典"])},
  "10498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韓民国の予備、前、現役兵士/幹部認証時に割引！"])},
  "10499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愛国会員10%割引"])},
  "10500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未発行を選択された場合でも現金領収書が自主発行されます。"])},
  "10501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日時"])},
  "10502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル内訳の削除"])},
  "10503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約完了"])},
  "10504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が完了しました。"])},
  "10505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["積立予定内訳"])},
  "10506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 退室24時間後にポイント/スタンプが支給されます。"])},
  "10507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(貸室商品はスタンプの支給ができません。)"])},
  "10508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内訳に"])},
  "10509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約内容の削除"])},
  "10510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室利用時間を変更する"])},
  "10511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル及び払い戻しポリシー"])},
  "10512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を見る"])},
  "10513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物かご入れ"])},
  "10514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐ予約する"])},
  "10515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレジャー·チケットをピックしてみてください。"])},
  "10516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楽しさに満ちた旅に出会えます。"])},
  "10517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいレジャー·チケットを見に行く。"])},
  "10518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より早く関心のある宿に出会うことができます！"])},
  "10519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい宿舎を見に行く。"])},
  "10520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい宿舎をピックしてみてください。"])},
  "10521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体写真"])},
  "10522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表写真"])},
  "10523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトーク"])},
  "10524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトーク"])},
  "10525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即時割引クーポン"])},
  "10526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大ワンクーポン確認"])},
  "10527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行きつけのクーポン"])},
  "10528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日字変更"])},
  "10529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室選択"])},
  "10530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舎情報"])},
  "10531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舎紹介"])},
  "10532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を見る"])},
  "10533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["便宜施設及びサービス"])},
  "10534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約のお知らせ"])},
  "10535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されたレビューがありません。"])},
  "10536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申告"])},
  "10537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の選択する"])},
  "10538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハリウッドルーム"])},
  "10539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメニティ"])},
  "10540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィットネス"])},
  "10541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウナ"])},
  "10542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スパ"])},
  "10543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プール"])},
  "10544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゴルフ練習場"])},
  "10545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サロン"])},
  "10546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンファレンスルーム"])},
  "10547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空気清浄機"])},
  "10548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バー"])},
  "10549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レストラン"])},
  "10550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])},
  "10551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この客室は車両利用のお客様はご予約ができませんので、ご参考ください。"])},
  "10553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消規定"])},
  "10555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊予約"])},
  "10556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用時間"])},
  "10557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運営時間"])},
  "10558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室予約"])},
  "10559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約締切"])},
  "10560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここまで"])},
  "10561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域や日付、人数を変更してみてください。"])},
  "10562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件に合う宿舎がありません。"])},
  "10563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲストハウス"])},
  "10564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見る"])},
  "10565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダをおすすめする周辺の宿泊施設"])},
  "10566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夢見た完璧な宿舎"])},
  "10567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域別おすすめの宿"])},
  "10568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月ホテルサラダがおすすめするプレイスをご紹介します。"])},
  "10569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体レビュー"])},
  "10570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当宿泊施設を利用して最初のレビューを書いてください!"])},
  "10571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォトレビューだけ見る。"])},
  "10572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボタン領域"])},
  "10573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気検索語"])},
  "10574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近の検索語"])},
  "10575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓屋"])},
  "10576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しの結果はありませんか。"])},
  "10577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こういう検索語はどうですか？"])},
  "10578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["済州島"])},
  "10579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["麗水"])},
  "10580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水上スキー"])},
  "10581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全州"])},
  "10582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水遊び"])},
  "10583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["束草"])},
  "10584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様、プライベートにヒーリングしたいなら"])},
  "10585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿の位置"])},
  "10586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊日"])},
  "10587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊人数"])},
  "10588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
  "10589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["児童"])},
  "10590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分だけの宿探し"])},
  "10591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矢印"])},
  "10592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠北"])},
  "10593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠南"])},
  "10594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全北"])},
  "10595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "10596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火"])},
  "10597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "10598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
  "10599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金"])},
  "10600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土"])},
  "10601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晴れ"])},
  "10602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲多さ"])},
  "10603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曇り"])},
  "10604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雨"])},
  "10605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雪"])},
  "10606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雪/雨"])},
  "10607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一泊基準"])},
  "10608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行きましょう#釜山のすべてを捨てて"])},
  "10609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今は車泊時代"])},
  "10610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車泊初心者のためのガイド"])},
  "10611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平昌カフェバセロ"])},
  "10612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感性カフェツアー！ 雰囲気の行列店"])},
  "10613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜のロマンを君に"])},
  "10614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私だけが知っている町/忠州"])},
  "10615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秋の水遊び"])},
  "10616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内感性フルビラベスト3"])},
  "10617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私と星を見に行かない？"])},
  "10618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星見よがしの全国の天文台"])},
  "10619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["済州に浮かぶ美食の星"])},
  "10620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["済州島スターシェフのグルメ店"])},
  "10621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寒くても寒すぎる！"])},
  "10622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットフリックスを見ながらゴロゴロするのはどうですか？"])},
  "10623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歴史オタクのための歴史ツアー"])},
  "10624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密陽遺跡地月蓮亭"])},
  "10625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群山にタイムスリップしよう！"])},
  "10626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["神秘的な群山時間旅行村"])},
  "10627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["春川名物ポテトパン"])},
  "10628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一度だけ食べてみた人はいない！"])},
  "10629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅先探し"])},
  "10630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["足取りが軽くなる今週の天気は？"])},
  "10631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今人気の宿"])},
  "10632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["軍人身分証明照会"])},
  "10633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愛国ポータル会員登録後、本人認証が 完了する必要があります"])},
  "10634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愛国認証"])},
  "10635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20歳~35歳の男性会員"])},
  "10636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幹部認証"])},
  "10637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現役幹部、軍務員、国防部公務員"])},
  "10638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1人利用券"])},
  "10639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["映像撮影未包含"])},
  "10640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに購入する"])},
  "10641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン番号をもう一度確認してください。"])},
  "10642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの適用を閉じる"])},
  "10643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン適用"])},
  "10644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般クーポン"])},
  "10645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能な一般クーポンがありません。"])},
  "10646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択せず"])},
  "10647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
  "10648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複クーポン"])},
  "10649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能な重複クーポンがありません。"])},
  "10650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの適用する"])},
  "10651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊クーポン情報"])},
  "10652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約の際、すぐに使用可能なクーポンです。"])},
  "10653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン注意事項のご案内"])},
  "10654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本クーポンは、訪問決済及び非会員使用が不可となります。"])},
  "10655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本クーポンは予約時に使用可能な即時割引クーポンで残り 数量が残っている場合は予約ページから選択して使用可能です"])},
  "10656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン生成数量によっては早期締め切りとなることがあります。"])},
  "10657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本特典は提携店及びホテルサラダの事情により変更/中止となる場合があります"])},
  "10658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの適用内訳を閉じる"])},
  "10659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポンの適用内訳"])},
  "10660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計クーポン割引額"])},
  "10661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付選択"])},
  "10662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺予約"])},
  "10663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦順"])},
  "10664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新順"])},
  "10665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホットプレイス"])},
  "10666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行"])},
  "10667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行列店"])},
  "10668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター適用"])},
  "10669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマ"])},
  "10670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["便益施設"])},
  "10671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
  "10672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを適用する"])},
  "10673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター初期化"])},
  "10674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室"])},
  "10675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イメージを閉じる"])},
  "10676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イメージ"])},
  "10677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域選択"])},
  "10678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員加入"])},
  "10679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊人数選択"])},
  "10680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊する人数を選択してください。"])},
  "10681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
  "10682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィード"])},
  "10683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント·企画展"])},
  "10684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーセンター"])},
  "10685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "10686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よくある質問"])},
  "10687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の画面に戻りますか？"])},
  "10688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した情報がすべて消えます。"])},
  "10689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "10690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済がキャンセルされました"])},
  "10691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済エラーが発生しました。 もう一度試してください。"])},
  "10692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除しますか？"])},
  "10693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当履歴がリストから削除されます。"])},
  "10694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン後ご利用ください。"])},
  "10695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用のためログインしてください。"])},
  "10696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインする"])},
  "10697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除しますか？"])},
  "10698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当商品がピックリストから解除されます。"])},
  "10699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除"])},
  "10700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申告が完了しました。"])},
  "10701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビュー申告"])},
  "10702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申告する"])},
  "10703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当アカウントでSNSログイン 接続しますか？"])},
  "10704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連結"])},
  "10705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択されたクーポンがありません。"])},
  "10706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韓民国宿泊大展"])},
  "10708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人認証進行"])},
  "10709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人認証を行いません。"])},
  "10710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID:に宿泊大展割引クーポンが発行完了しました。"])},
  "10711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに発行されているクーポンがあります。"])},
  "10712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約に行く"])},
  "10713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン発行情報入力"])},
  "10714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※現在お住まいの地域をお選びください"])},
  "10715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["道/市"])},
  "10716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択してください。"])},
  "10717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市/郡/区"])},
  "10718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊大展個人情報活用同意"])},
  "10719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊大展クーポン発行のための個人情報提供に関する 約款の確認および同意が必須です"])},
  "10720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用約款に全体同意します。"])},
  "10721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先着順で発給してもらう"])},
  "10722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有する"])},
  "10723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトーク共有"])},
  "10724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNSを共有"])},
  "10725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクコピー"])},
  "10726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダのおすすめ順"])},
  "10727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距離順"])},
  "10728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューの多い順"])},
  "10729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランダム順"])},
  "10730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室低価格順"])},
  "10731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室の高い値段順"])},
  "10732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊低価格順"])},
  "10733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊高い値段順"])},
  "10734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報の収集及び利用同意"])},
  "10735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル/払い戻しポリシーに同意"])},
  "10736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取り消し規定"])},
  "10737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン政策"])},
  "10738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント政策"])},
  "10739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊商品の利用規則に同意"])},
  "10740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報第3者提供同意"])},
  "10741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報三者提供に同意拒否可能であり、この場合サービス利用が不可能です"])},
  "10742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貸室利用時間"])},
  "10743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舎訪問手段を閉じる"])},
  "10744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿の訪問手段"])},
  "10745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徒歩訪問"])},
  "10746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車の訪問"])},
  "10747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、駐車はできません。"])},
  "10748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事業者情報"])},
  "10749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(株)ルーネット"])},
  "10750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表取締:パク·ギヒョン"])},
  "10751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事業者登録番号:215-87-14179"])},
  "10752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通信販売番号:2021-ソウル麻浦-2542号"])},
  "10753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所:ソウル特別市麻浦区洋路161、ケーススクエア5階513号(東橋洞)"])},
  "10754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーセンター:02-407-8640"])},
  "10755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["営業時間:09:00~18:00"])},
  "10756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール:roonet.co"])},
  "10757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホスティングサービス提供者:株式会社（株）ルーネット"])},
  "10758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報処理方針"])},
  "10759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["広告/提携のお問い合わせ"])},
  "10761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニューを開く/閉じる"])},
  "10762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
  "10763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周辺"])},
  "10764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "10765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイサラダ"])},
  "10766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い物かごに商品が入っております。"])},
  "10767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まっすぐ行く"])},
  "10768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件を選択してください。"])},
  "10769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピックリストに追加されました。"])},
  "10770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個選択"])},
  "10789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用約款にすべて同意してください。  "])},
  "10790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDまたはパスワードが正しくありません。 もう一度入力してください。  "])},
  "10791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証失敗しました。  "])},
  "10792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証失敗しました。  "])},
  "10793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用約款にすべて同意してください。  "])},
  "10794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせが受け付けられました。  "])},
  "10795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須同意事項すべて同意してください。  "])},
  "10796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号が一致しません。 もう一度確認をお願いします。  "])},
  "10797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力時間を超過しました。 再転送ボタンを押してください  "])},
  "10798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号を再送信しました。 認証番号が来ない場合は、入力した情報を確認してください。  "])},
  "10799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号を再送信しました。  "])},
  "10800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員脱退が完了しました。  "])},
  "10801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員脱退に失敗しました。  "])},
  "10802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先認証番号が一致しません。  "])},
  "10803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームの重複確認をしてください。  "])},
  "10804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中のニックネームです。  "])},
  "10805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません。  "])},
  "10806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは英語、数字を含めて8~20桁で入力してください。  "])},
  "10807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推薦コードがコピーされました。  "])},
  "10808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約者名を入力してください。  "])},
  "10809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号を認証してください。  "])},
  "10810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問者名を入力してください。  "])},
  "10811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問者の携帯番号を入力してください。  "])},
  "10812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿への訪問手段を選択してください。  "])},
  "10813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済手段を選択してください。  "])},
  "10814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金領収書の情報収集に同意してください。  "])},
  "10815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用約款にすべて同意してください。  "])},
  "10816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入費用が0ウォン以下です。  "])},
  "10817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご予約なさいますか。  "])},
  "10818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証成功しました。  "])},
  "10819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証失敗しました。  "])},
  "10820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ない地域です。 管理者にお問い合わせください。  "])},
  "10821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約可能時間がありません。  "])},
  "10822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。  "])},
  "10823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同一の日付の宿泊はできません。  "])},
  "10824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索ワードを入力してください。  "])},
  "10825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーポン番号を入力してください。  "])},
  "10826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少なくとも10文字以上作成してください。  "])},
  "10827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯番号を入力してください。  "])},
  "10828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをご入力ください  "])},
  "10829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを削除しようとする理由を教えてください。  "])},
  "10830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームを入力してください。  "])},
  "10831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをもう一度入力してください  "])},
  "10832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせタイプを選択してください。  "])},
  "10833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトルを入力してください。  "])},
  "10834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の率直な評価を書いてください。 <br><br> テキストレビュー作成時:30P即時支給<br> フォトレビュー作成時:50P即時支給<br>  "])},
  "10835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前を入力してください。  "])},
  "10836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域、商品名で検索してみてください  "])},
  "10837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅立つ人数は何人ですか。  "])},
  "10838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅立つ旅先はどこですか。  "])},
  "10839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅立つ日はいつですか。  "])},
  "10840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事業者情報"])},
  "10841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表理事"])},
  "10842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事業者登録番号"])},
  "10843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通販番号"])},
  "10844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "10845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーセンター"])},
  "10846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["営業時間"])},
  "10847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
  "10848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホスティングサービスプロバイダー"])},
  "10849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用規約"])},
  "10850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報処理方針"])},
  "10851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報利用約款"])},
  "10852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青少年保護政策"])},
  "10853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["広告/提携に関するお問い合わせ"])},
  "10854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホテルサラダは通信販売仲介者として通信販売の当事者ではなく、<br>商品の予約、利用及び払い戻しなどに関する義務と責任は各販売者にあります."])},
  "10855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "10856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい宿泊施設を<br>ピックアップ"])},
  "10857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より早く興味のある宿泊施設に出会えます！"])},
  "10858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい宿泊施設を見る"])},
  "10859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit"])},
  "area00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "area01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul"])},
  "area02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busan"])},
  "area03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon"])},
  "area04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju"])},
  "area05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daejeon"])},
  "area06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daegu"])},
  "area07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulsan"])},
  "area10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "area11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["match"])},
  "area12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "area13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "area14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "area15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "area16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "area17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "area18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "area0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "area0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Seoul"])},
  "area0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangnam/Yeoksam/Seocho/Samsung"])},
  "area0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangdong/Cheonho/Gildong/Dunchon/Bangi/Shincheon"])},
  "area0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangbuk/Mia/Seongbuk/Suyu/Wolgok"])},
  "area0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo/Kkachisan Station"])},
  "area0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwanak/Dongjak/Sadang/Seoul National University/Silrim/"])},
  "area0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guro/Geumcheon/Sindorim/Error"])},
  "area0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeonmok / Sangbong / Jungnang / Taereung / Nowon / Dobong / Changdong"])},
  "area0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeongdong/Hoehyeon/Itaewon/Yongsan/Jung-gu"])},
  "area0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokdong/Hwagok/Yangcheon"])},
  "area0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seodaemun/Mapo/Eunpyeong"])},
  "area0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seolleung/Shinsa/Nonhyeon/Bangbae"])},
  "area0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songpa/Jamsil/Saenae"])},
  "area0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinchon/Hapjeong/Hongdae/Bulgwang/Yeonsinnae/Eungam"])},
  "area0114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeouido/Yeongdeungpo"])},
  "area0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wangsimni/Seongsu/Geumho/Chungmuro/Sindang/Yaksu"])},
  "area0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoegi/Anam/Cheongnyangni/Sinseol/Dongdaemun"])},
  "area0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan-dong/Dapsimni/Kondae/Gunja/Guui/Gwangjin"])},
  "area0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongno / Daehakro / Sungshin Women's University"])},
  "area0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Busan area"])},
  "area0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo-gu (Myeongji, Shinho) / Buk-gu (Deokcheon, Mandeok, Gupo, Hwahwa, Buk-gu)"])},
  "area0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geumjeong-gu (Busan National University, Guseo, Yeonsan, Togok) / Dongnae-gu (Oncheonjang, Sajik)"])},
  "area0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captain (Captain/Cheonggwan)"])},
  "area0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Gyeongseong University, Daeyeon, Yongho, Munhyeon) / Jung-gu (Nampo-dong) / Dong-gu (Busan Station, Beomil-dong) / Yeongdo-do (Yeongdo)"])},
  "area0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busanjin-gu (Seomyeon, Yangjeong, Choeup)/Sasang/Yeonje"])},
  "area0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Songdo)/Saha-gu (bottom, Saha)"])},
  "area0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haeundae-gu (Songjeong/Suyeong-gu (Gwangalli, Millak)/Resong"])},
  "area0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Incheon"])},
  "area0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganghwa/Ongjin/Jung-gu/Baengnyeongdo"])},
  "area0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu/Michuhol/Namdong-gu"])},
  "area0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongam/tidal stone"])},
  "area0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bupyeong/Gyeyang/Seogu/Juan/Geomdan"])},
  "area0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songdo/Yeonsu"])},
  "area0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolmi Island/Chinatown/Sinpo/Dongincheon"])},
  "area0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon Airport/Eulwang-ri"])},
  "area0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gwangju area"])},
  "area0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine area"])},
  "area0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangsan-gu Hanam/Songjeong Station"])},
  "area0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu"])},
  "area0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu"])},
  "area0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu"])},
  "area0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western"])},
  "area0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Daejeon"])},
  "area0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daedeok-gu (Shintanjin/Jungri)"])},
  "area0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Yongjeon/Complex Terminal)"])},
  "area0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Dunsan/Yongmun/Wolpyeong)"])},
  "area0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteor sphere (Meteor Seal/Design/Pole)"])},
  "area0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Jung-gu Bank/Daeheung/Seonhwa/Yucheon)"])},
  "area0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Daegu area"])},
  "area0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Daemyung/Bongdeok/Seobu Bus Stop)"])},
  "area0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseo-gu (Duryu Park/Duryu/Bonri/Jukjeon/Gamsam/Seongseo Industrial Complex/Kyemyung University)"])},
  "area0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseong-gun"])},
  "area0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Dongdaegu Station/Shinam/Shincheon/Deungchon Amusement Park/Daegu Airport/Innovation City)"])},
  "area0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu (Daegu Station/EXCO/Chilgok 3 District/Taejeon-dong)"])},
  "area0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Pyeongri/Naedang/Bisan/Wondae)"])},
  "area0607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suseong-gu (Deulan-gil/Suseong-mot)"])},
  "area0608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Kyungbuk National University/Dongseong-ro/City Hall/Seomun Market)"])},
  "area0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Ulsan"])},
  "area0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu/Dong-gu"])},
  "area0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulju-gun"])},
  "area0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu/Buk-gu"])},
  "area1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gangwon area"])},
  "area1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangneung/Donghae/Samcheok"])},
  "area1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goseong/Sokcho/Yangyang"])},
  "area1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanggu/Inje/Cheorwon/Hwacheon"])},
  "area1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonju/Hoengseong"])},
  "area1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuncheon/Hongcheon"])},
  "area1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongchang/Jeongseon/Youngwol/Taebaek"])},
  "area1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All match area"])},
  "area1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gapyeong/Cheongpyeong/Yangpyeong"])},
  "area1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goyang/Ilsan/Gimpo/Janggi/Gurae/Daemyeong Port"])},
  "area1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwacheon/Indeokwon"])},
  "area1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju/Yeoju/Icheon"])},
  "area1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namyangju/Guri/Uijeongbu/Hanam"])},
  "area1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byeongjeom/Osan/Dongtan"])},
  "area1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bucheon"])},
  "area1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gyuun/Seryu/Suwon Station/Jangan/Paldalmun)"])},
  "area1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gwonseon/Youngtong/Ingye)"])},
  "area1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siheung/Gwangmyeong"])},
  "area1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansan/Daebudo"])},
  "area1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyang/Pyeongchon/Gunpo/Uiwang/Geumjeong/Sanbon"])},
  "area1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paju/Geumchon/Heyri/Unification Garden"])},
  "area1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongtaek/Songtan/Anseong"])},
  "area1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pocheon/Yangju/Dongducheon/Yeoncheon/Jangheung"])},
  "area1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanam/Seongnam/Bundang/Yongin"])},
  "area1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hwaseong/Jebudo"])},
  "area1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongnam"])},
  "area1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geoje/Tongyeong/Goseong"])},
  "area1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geochang/Hamyang/Hapcheon/Sancheong/Changnyeong"])},
  "area1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimhae/Yangsan/Miryang/Jangyu"])},
  "area1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinju/Namhae/Sacheon/Hadong"])},
  "area1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Masan, Jinhae)"])},
  "area1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Myeongseo, Palyong, Bonggok, Buk-myeon)"])},
  "area1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Sangnam, Yongho, Jungang)"])},
  "area1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haman/Uiryeong"])},
  "area1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongbuk"])},
  "area1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongju/Yeongcheon/Gyeongsan/Cheongdo"])},
  "area1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimcheon/Seongju/Goryeong/Chilgok/Gumi/Gunwi"])},
  "area1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mungyeong/Sangju/Yecheon/Yeongju/Bonghwa"])},
  "area1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andong / Uiseong / Cheongsong / Yeongdeok / Yeongyang / Uljin"])},
  "area1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu, Pohang"])},
  "area1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu, Pohang"])},
  "area1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeollanam-do"])},
  "area1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangjin/Haenam/Wando/Jindo"])},
  "area1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gurye/Gokseong/Damyang/Jangseong/Yeonggwang/Hampyeong"])},
  "area1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokpo/Muan/Shinan"])},
  "area1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boseong/Goheung/Jangheung"])},
  "area1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeosu/Gwangyang/Suncheon"])},
  "area1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youngam/Naju/Hwasun"])},
  "area1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All regions of Jeollabuk-do"])},
  "area1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunsan/Gimje"])},
  "area1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muju/Jinan/Jangsu"])},
  "area1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buan/Jeongeup/Gochang"])},
  "area1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imsil/Namwon/Sunchang"])},
  "area1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonju/Iksan/Wanju"])},
  "area1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeju"])},
  "area1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seogwipo/Marado"])},
  "area1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aewol/Hyupjae"])},
  "area1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju City"])},
  "area1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Chungcheongnam-do"])},
  "area1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeryong/Gongju/Nonsan/Geumsan"])},
  "area1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boryeong/Buyeo/Seocheon/Daecheon"])},
  "area1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesan/Hongseong/Cheongyang"])},
  "area1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheonan/Asan"])},
  "area1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Chungnam/Sejong"])},
  "area1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taean/Dangjin/Seosan/Anmyeondo"])},
  "area1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the whole of Chungbuk"])},
  "area1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeun/Okcheon/Youngdong"])},
  "area1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jecheon/Danyang"])},
  "area1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheongju/Boeun/Jincheon"])},
  "area1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungju/Eumseong/Goesan/Suanbo/Jungpyeong"])},
  "weather00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul Gyeonggi"])},
  "weather01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "weather02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "weather03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "weather04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "weather05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "weather06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "weather07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "weather08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "day월": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "day화": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "day수": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "day목": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "day금": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "day토": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "day일": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "dayMo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "dayTu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "dayWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "dayTh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "dayFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "daySa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "daySu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
}