import { createApp } from "vue";
import router from "./router/routes";
import App from "./App";

/* component */
import {
  TheHeader,
  TheFooter,
  TheNavigation,
  TheTop,
} from "./components/navigation";
import Menu from "./components/layers/Menu";
import Sorting from "./components/layers/Sorting";
import Filter from "./components/layers/Filter";
import MapRooms from "./components/layers/map/Rooms";
import Loading from "./components/layers/Loading";
import SwiperCore, { Autoplay, Scrollbar, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import smoothscroll from "smoothscroll-polyfill";

SwiperCore.use([Autoplay, Scrollbar, Navigation]);

import "swiper/swiper-bundle.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
import "@/design/common.css";
import store from "./store";
import i18n from "./i18n";
smoothscroll.polyfill();

window.__forceSmoothScrollPolyfill__ = true;

const app = createApp(App);
app.use(router);
app.use(store);
app.component("TheHeader", TheHeader);
app.component("TheFooter", TheFooter);
app.component("TheNavigation", TheNavigation);
app.component("Swiper", Swiper);
app.component("SwiperSlide", SwiperSlide);
app.component("Menu", Menu);
app.component("RoomSorting", Sorting);
app.component("RoomFilter", Filter);
app.component("MapRooms", MapRooms);
app.component("Loading", Loading);
app.component("TheTop", TheTop);
app.use(i18n)
app.mount("#app");
//const urlhost = "www.hellostay.co.kr"
const urlhost = window.location.hostname

//console.log(urlhost)
store.commit("app/SET_URL", {
  urlhost: urlhost
});
if(urlhost === "www.hellostay.co.kr"){
  store.commit("app/SET_USELANG", {
    uselang: {
      ko: "Y",
      en: "N",
      jp: "N",
      cn: "N",
    }
  });
  store.commit("app/SET_USESELECTLANG", {
    useselectlang: "N"
  });
  store.commit("app/SET_USEFEED", {
    usefeed: "N"
  });
  store.commit("app/SET_USELOGO", {
    logo: "hellostay"
  });
  store.commit("app/SET_LOC_NAME", {
    loc_name: "top"
  });
}else{
  store.commit("app/SET_USELANG", {
    uselang: {
      ko: "Y",
      en: "Y",
      jp: "Y",
      cn: "Y",
    }
  });
  store.commit("app/SET_USESELECTLANG", {
    useselectlang: "Y"
  });
  store.commit("app/SET_USEFEED", {
    usefeed: "Y"
  });
  store.commit("app/SET_USELOGO", {
    logo: "hotelsalad"
  });
  store.commit("app/SET_LOC_NAME", {
    loc_name: "right"
  });
}