//import api from "@/urls/pay01";
import api from "@/urls/urls_all";

const state = {
  pay03: [],
};

const getters = {};

const mutations = {
  SET_PAY03(state, { pay03 }) {
    state.pay03 = pay03;
  },
};

const actions = {
  async fetchPay03({ commit }, params) {
    const data = await api.getPay03(params);
    commit("SET_PAY03", { pay03: data.data.Res_data });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
