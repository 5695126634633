<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10009')}}
					</h3>
					<button type="button" class="btn-close" @click="handleClose()">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<div class="layer__box">
						<ul class="layer__list">
							<!-- [D] 선택시 active 클래스 추가 -->
							<li><button type="button" :class="mode === 0 && 'active'" @click="() =>{changeMode(0); handleClose()}">{{t('10726')}}</button></li>
							<li><button type="button" :class="mode === 1 && 'active'" @click="() =>{changeMode(1); handleClose()}">{{t('10727')}}</button></li>
							<li><button type="button" :class="mode === 2 && 'active'" @click="() =>{changeMode(2); handleClose()}">{{t('10728')}}</button></li>
							<li><button type="button" :class="mode === 3 && 'active'" @click="() =>{changeMode(3); handleClose()}">{{t('10729')}}</button></li>
							<li><button type="button" :class="mode === 4 && 'active'" @click="() =>{changeMode(4); handleClose()}">{{t('10730')}}</button></li>
							<li><button type="button" :class="mode === 5 && 'active'" @click="() =>{changeMode(5); handleClose()}">{{t('10731')}}</button></li>
							<li><button type="button" :class="mode === 6 && 'active'" @click="() =>{changeMode(6); handleClose()}">{{t('10732')}}</button></li>
							<li><button type="button" :class="mode === 7 && 'active'" @click="() =>{changeMode(7); handleClose()}">{{t('10733')}}</button></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
	export default {
		props: ['handleClose', 'mode', 'changeMode'],
		setup(props) {
			 const { t }= useI18n() 
			onBeforeRouteLeave(() => {
				props.handleClose();
				return false;
			})
			return{
				t,  //번역필수 모듈
		  i18n,
			}
		}
}
</script>