export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩国语"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日语"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿"])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTEL"])},
  "HO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOTEL"])},
  "PS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PENSION"])},
  "RT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESORT"])},
  "CP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMPING"])},
  "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUESTHOUSE"])},
  "GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GLAMPING"])},
  "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["江南 论岘 三成"])},
  "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿"])},
  "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休闲/门票"])},
  "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题公园"])},
  "10006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["演出/展览"])},
  "10007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水上乐园/水疗"])},
  "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券折扣宿舍"])},
  "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1万韩元优惠券"])},
  "10011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动态蹦床体验!"])},
  "10012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[首尔] 驿三弹跳蹦床"])},
  "10013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离我的位置15公里"])},
  "10014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当日可使用<br>未使用全额退款"])},
  "10015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挑选"])},
  "10016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首尔论岘洞"])},
  "10017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤"])},
  "10018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边没有休闲·门票"])},
  "10019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与酒店沙拉的多种休闲·门票相遇<br> 请购买休闲·门票!"])},
  "10020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看休闲·门票"])},
  "10021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽车旅馆"])},
  "10022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒店"])},
  "10023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家庭旅馆"])},
  "10024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度假村"])},
  "10025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["野营/露营"])},
  "10026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周围没有宿舍"])},
  "10027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与沙拉酒店的多种宿舍相遇<br> 请预定一下宿舍吧!"])},
  "10028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去看宿舍"])},
  "10029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宾馆"])},
  "10030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
  "10031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
  "10032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告事项"])},
  "10033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有登记公告事项"])},
  "10034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作咨询"])},
  "10035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "10036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重启表单"])},
  "10037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
  "10038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "10039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询内容"])},
  "10040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意收集及使用个人信息(必须)"])},
  "10042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款与政策"])},
  "10043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务使用条款"])},
  "10044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息处理指南"])},
  "10045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置信息使用条款"])},
  "10046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青少年保护政策"])},
  "10047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动企划展"])},
  "10048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韩民国住宿大战事前认证活动"])},
  "10049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关宿舍"])},
  "10050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1万元优惠券"])},
  "10051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先着順优惠券"])},
  "10052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有没有梦幻般的屋顶?"])},
  "10053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全州韩屋环保村家庭旅馆"])},
  "10054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驿三站1号出口145米"])},
  "10055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多保有5小時时租房"])},
  "10056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时租5小時"])},
  "10057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入宿18点开始"])},
  "10058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
  "10059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["江南汽车旅馆"])},
  "10060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自助登记"])},
  "10061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常客优惠券"])},
  "10062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "10063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周中无限時租活动! 最长10小时"])},
  "10064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券"])},
  "10065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
  "10066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企划展"])},
  "10067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在没有正在进行的活动"])},
  "10068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在没有正在进行的企划展"])},
  "10069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行取向输入完毕!"])},
  "10070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐取向狙击你喜好的旅行地"])},
  "10071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接去预约宿舍"])},
  "10072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喜欢什么样的旅行地?"])},
  "10073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择3个喜欢的旅行地!"])},
  "10074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请告诉我您的旅行取向!"])},
  "10075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择5个我的喜好!"])},
  "10076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少选择一个"])},
  "10077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行风格至少选择1个"])},
  "10078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "10079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边预约"])},
  "10080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赞"])},
  "10081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约详情"])},
  "10082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非会员预约查询"])},
  "10083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约日期:"])},
  "10084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品信息"])},
  "10085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统一预约号码"])},
  "10086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约号码"])},
  "10087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舍"])},
  "10088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元"])},
  "10089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为止可以免费取消"])},
  "10090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约者资料"])},
  "10091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "10092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号码"])},
  "10093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户资料"])},
  "10094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问宿舍手段"])},
  "10095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额及结算信息"])},
  "10096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结账方法"])},
  "10097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约金额"])},
  "10098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券"])},
  "10099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分"])},
  "10100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作优惠"])},
  "10101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结账金额"])},
  "10102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有预约明细"])},
  "10103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次确认预约信息"])},
  "10104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非会员预约查询"])},
  "10105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订购时请输入收到的预约号码和手机号码"])},
  "10106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的预约号码. 请再次确认."])},
  "10107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约查询"])},
  "10108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注册完毕!"])},
  "10109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接去预约宿舍"])},
  "10110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入旅行取向"])},
  "10111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认以下内容后准备旅行吧"])},
  "10112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体同意"])},
  "10113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满14周岁以上使用(必需)"])},
  "10114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务使用条款 (必需)"])},
  "10115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例子"])},
  "10116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息收集及使用同意(必需)"])},
  "10117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置信息使用条款(选择)"])},
  "10118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意接收优惠通知(选择)"])},
  "10119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
  "10120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机认证"])},
  "10121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了提供顺畅的服务,请输入手机号码。"])},
  "10122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求验证"])},
  "10123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人认证"])},
  "10124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
  "10125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重传"])},
  "10126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码不一致。"])},
  "10127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人认证完毕"])},
  "10128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入信息"])},
  "10129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "10130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复确认"])},
  "10131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加入的用户名(电子邮件)"])},
  "10132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "10133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括英文"])},
  "10134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括数字"])},
  "10135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8~20字以内"])},
  "10136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
  "10137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码一致 "])},
  "10138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绰号"])},
  "10139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复确认"])},
  "10140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2~6字以内"])},
  "10141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经在使用的绰号"])},
  "10142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐人代码"])},
  "10143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认 "])},
  "10144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐人代码不一致"])},
  "10145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册完毕"])},
  "10146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滑翔伞体验飞行名家"])},
  "10147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[忠北] 丹阳 滑翔伞"])},
  "10148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17名选择的商品"])},
  "10149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期:自购买日起60天"])},
  "10150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当日可使用未使用全额退款"])},
  "10151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
  "10152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额及折扣信息"])},
  "10153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品使用指南"])},
  "10154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买优惠券(至少1天前必须购买) 必须确认有线预约 (010-0000-0000) 使用在预定日期"])},
  "10155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注意事项指南"])},
  "10157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用平价"])},
  "10158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通房间( A 类型) - 住宿"])},
  "10159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称A"])},
  "10160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["床很大很舒服,非常满足的休息. 气氛很好,设施也很好.  下次休假的时候也想来~"])},
  "10161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边信息"])},
  "10162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边休闲·门票"])},
  "10163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[首尔永登浦] 汝矣岛汉江公园 电动滑板车自由使用券最低价"])},
  "10164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿"])},
  "10165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边宿舍"])},
  "10166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["王国酒店"])},
  "10167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奥云酒店"])},
  "10168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
  "10169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全忠北"])},
  "10170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券折扣宿舍"])},
  "10171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题公园"])},
  "10172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表演·展览"])},
  "10173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水上乐园·水疗"])},
  "10174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庆典·活动"])},
  "10175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交通"])},
  "10176": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题A"])},
  "10177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题B"])},
  "10178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新休闲·门票"])},
  "10179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火热的星期五大家一起开啤酒派对!"])},
  "10180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[首尔] 汉江啤酒派对"])},
  "10181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["享受着济州的浪漫"])},
  "10182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[济州] 租借车用 套装"])},
  "10183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
  "10184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维瓦尔第公园缆车票单独特价"])},
  "10185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限限定最大优惠57%"])},
  "10186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新罗时代酒店品牌特价活动"])},
  "10187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙拉酒店MD强烈推荐秋季住宿"])},
  "10188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一周人气休闲·门票"])},
  "10189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适合和家人一起享受的"])},
  "10190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[江原] 大关岭羊群牧场"])},
  "10191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有梦幻般的屋顶"])},
  "10192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驛三 本"])},
  "10193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最适合玩的地区是?"])},
  "10194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首尔"])},
  "10195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釜山"])},
  "10196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["济州"])},
  "10197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["江原"])},
  "10198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庆北"])},
  "10199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庆南"])},
  "10200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全南"])},
  "10201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充满梦幻般艺术作品的"])},
  "10202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[釜山] 博物馆 DAH"])},
  "10203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企划展"])},
  "10204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企划展"])},
  "10205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冬天是滑雪·滑板的季节!"])},
  "10206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新手也毫无负担地享受!"])},
  "10207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[平昌] 大元度假村 滑雪培训"])},
  "10208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐场所"])},
  "10209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介紹场所,美食"])},
  "10210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内感性泳池别墅BEST3"])},
  "10211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除休眠会员"])},
  "10212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长期未连接,处于休眠会员转换状态"])},
  "10213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初註冊日"])},
  "10214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后登入日"])},
  "10215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠处理日"])},
  "10217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重传"])},
  "10218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除休眠状态"])},
  "10219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休眠会员解除完毕"])},
  "10220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已正常进行休眠处理."])},
  "10221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择本人认证方法"])},
  "10222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件认证"])},
  "10223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS认证"])},
  "10224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证码"])},
  "10225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐号搜索完成"])},
  "10226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是会员加入的账号(电子邮件)"])},
  "10227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已經是加入会员"])},
  "10228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请进行简便登录"])},
  "10229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找密码"])},
  "10230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "10231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索账号"])},
  "10232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码变更完成"])},
  "10233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码变更已完成. 重新设置密码."])},
  "10234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认账号后通过手机本人认证 重新设置密码"])},
  "10235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变更密码"])},
  "10236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
  "10237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认新密码"])},
  "10238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出处理已完成"])},
  "10240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "10241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出时的注意事项"])},
  "10242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前使用的账户信息将被全部删除"])},
  "10244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分及所有优惠都将消失."])},
  "10246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经熟知了上述内容,同意退出"])},
  "10247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很可惜,是离别的时间…."])},
  "10248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢您这段时间喜欢沙拉酒店. 為了提供更好得服務请,请告诉离开理由."])},
  "10249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "10250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "10251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级优惠通知"])},
  "10252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认不同等级的优惠"])},
  "10253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
  "10254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般会员"])},
  "10255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有新加入的会员"])},
  "10256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP会员"])},
  "10257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前年使用金额50万韩元以上AND购买件数10件以上"])},
  "10258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿优惠20%(共2次)"])},
  "10259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿预约明细"])},
  "10260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问前"])},
  "10261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问后"])},
  "10262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消预约"])},
  "10263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消完毕"])},
  "10264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约号码R1234"])},
  "10265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细內容"])},
  "10266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钟路 柠檬树"])},
  "10267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿 豪华"])},
  "10268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "10269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "10270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住时间"])},
  "10271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房"])},
  "10272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用完毕"])},
  "10273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新预订"])},
  "10274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写意見"])},
  "10275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有宿舍明细"])},
  "10276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消预约"])},
  "10277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品信息"])},
  "10278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约号码:"])},
  "10279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入住"])},
  "10280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退房"])},
  "10281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消信息"])},
  "10282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品金额"])},
  "10283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用积分"])},
  "10284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结算金额"])},
  "10285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["估计取消手续费0元"])},
  "10286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["估计退款金额0元"])},
  "10287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以上内容已全部确认."])},
  "10288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "10289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
  "10290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部选择"])},
  "10291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除选择"])},
  "10292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公鸡"])},
  "10293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相应时间预约截止"])},
  "10294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总  件"])},
  "10295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订"])},
  "10296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车里没有商品"])},
  "10297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请一次性预约各种宿舍!"])},
  "10298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看商品"])},
  "10299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券盒"])},
  "10300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用优惠券"])},
  "10301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的优惠券 张"])},
  "10302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
  "10303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用完毕"])},
  "10304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% 优惠券"])},
  "10305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元 优惠券"])},
  "10306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大折扣"])},
  "10307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["截止日期"])},
  "10308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一直持续到活动结束"])},
  "10309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有优惠券明细"])},
  "10310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加酒店沙拉优惠券发放活动 可以领取优惠券."])},
  "10311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人气包子可使用的优惠券"])},
  "10312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记优惠券"])},
  "10313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入会祝贺优惠券"])},
  "10314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15%折扣优惠券"])},
  "10315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注册纪念最高优惠1万元"])},
  "10316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.11.19日为止(D-3)"])},
  "10317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿优惠券"])},
  "10318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP 优惠券"])},
  "10319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改信息"])},
  "10320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改联系人"])},
  "10321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变更"])},
  "10322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改昵称"])},
  "10323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是可以使用的昵称"])},
  "10324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不一致"])},
  "10325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出会员"])},
  "10326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1咨询明细"])},
  "10327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答复"])},
  "10328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询事项已受理. 我会尽快答复您. 谢谢."])},
  "10329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
  "10330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "10331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目录"])},
  "10332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总"])},
  "10333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已登记的1:1咨询不存在"])},
  "10334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中如有疑问,请咨询. 我会尽快答复."])},
  "10335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询类型"])},
  "10336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询内容"])},
  "10337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "10338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休闲·门票购买明细"])},
  "10339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用前"])},
  "10340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用后"])},
  "10341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消购买"])},
  "10342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消完毕"])},
  "10343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期:"])},
  "10344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2人,包括吃饭"])},
  "10345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新购买"])},
  "10346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP等级优惠"])},
  "10347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐代码 总邀请会员数1名"])},
  "10348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙拉酒店积分"])},
  "10349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券 15张"])},
  "10350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图章 0/5"])},
  "10351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿预约明细"])},
  "10352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休闲/门票购买明细"])},
  "10353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车 "])},
  "10354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的评论"])},
  "10355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近看商品"])},
  "10356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设定旅行取向"])},
  "10357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入旅行喜好后获得20%优惠券"])},
  "10358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1咨询"])},
  "10359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置环境"])},
  "10360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙拉酒店客服中心"])},
  "10361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话咨询"])},
  "10362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服中心运营时间"])},
  "10363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全年无休10点~24点(午餐)12点30分~13点30分"])},
  "10364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kakao talk咨询"])},
  "10365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Talk客服中心运营时间"])},
  "10366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全年无休10点~19点(午餐)12点30分~13点30分"])},
  "10367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录/注册会员"])},
  "10368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册会员后立即支付2000积分!"])},
  "10369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐人代码"])},
  "10370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总特邀会员数 名"])},
  "10371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入推荐人代码即可双倍积分!"])},
  "10372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的评论"])},
  "10373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舍评估"])},
  "10374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亲自使用后感觉怎么样?"])},
  "10375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 以上内容只向相关企业公开."])},
  "10376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照片"])},
  "10377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您填写的评论明细不存在"])},
  "10378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用沙拉酒店的多种宿舍 请写一下评论!"])},
  "10379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有取消的宿舍明细"])},
  "10380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有使用的宿舍明细"])},
  "10381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有预约的宿舍明细"])},
  "10382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有装进购物车里的商品"])},
  "10383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的优惠券明细"])},
  "10384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询"])},
  "10385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有您取消的休闲·门票"])},
  "10386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用的休闲·门票明细不存在"])},
  "10387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙拉酒店的多种休闲·门票清单 现在请预约一下休闲·门票吧!"])},
  "10388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买的休闲·门票明细不存在"])},
  "10389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体"])},
  "10390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用的积分"])},
  "10391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余积分 "])},
  "10392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7日"])},
  "10393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30日"])},
  "10394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90日"])},
  "10395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180日"])},
  "10396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可累积/使用的积分明细"])},
  "10397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在推荐酒店沙拉的话可以获得积分"])},
  "10398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的推荐人代码:"])},
  "10399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近没有查看的宿舍"])},
  "10400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在沙拉酒店见面各种宿舍 现在预定宿舍吧!"])},
  "10401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近没有看过的休闲·门票"])},
  "10402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在沙拉酒店见面各种休闲门票 现在预定休闲门票吧!"])},
  "10403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我邀请的会员"])},
  "10404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 邀请的会员数 名"])},
  "10405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我没有邀请的会员"])},
  "10406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请朋友,获得1000积分!"])},
  "10407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用完的优惠券明细不存在"])},
  "10408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要安全地获取会员们宝贵的信息 需要变更並确认密码."])},
  "10409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计/使用日期"])},
  "10410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有积分明细"])},
  "10411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部删除"])},
  "10412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[江原] 大关岭羊群牧场"])},
  "10413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首尔江南区大峙洞891-35"])},
  "10414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约"])},
  "10415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入日"])},
  "10416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设定"])},
  "10417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本信息 V1.0"])},
  "10418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推送通知"])},
  "10419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置访问权限"])},
  "10420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置 SNS 连接"])},
  "10421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
  "10422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要连接的帐号"])},
  "10423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择连接SNS的账号"])},
  "10424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接SNS账号"])},
  "10425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kakao登录"])},
  "10426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
  "10427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除"])},
  "10428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAVER 登录"])},
  "10429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苹果 登录"])},
  "10430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图章"])},
  "10431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图章卡"])},
  "10432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积明细"])},
  "10433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的印章现状/5个"])},
  "10434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回"])},
  "10435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计5次图章时累计5000P!"])},
  "10436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图章获取方法"])},
  "10437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿退房24小时后,自动累积1次图章!"])},
  "10438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图章注意事项介绍"])},
  "10439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 退房后自动累积图章. (累计时间:以退房日为准,次日00时适用)"])},
  "10440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 累计5次图章时累计5000P!"])},
  "10441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 只有在住宿商品使用时才能累积图章."])},
  "10442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. 已支付的积分有效期为180天."])},
  "10443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. 印章政策会根据情况而有所不同"])},
  "10444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积图章 个"])},
  "10445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积积分 p"])},
  "10446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积日"])},
  "10447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累积图章 3,000 个"])},
  "10448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品信息"])},
  "10449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元"])},
  "10450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["102(个别游泳池+双层)"])},
  "10451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021.12.13(一) 11:00~16:00"])},
  "10452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录后享受打折优惠!"])},
  "10453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问者信息"])},
  "10454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入实际访问者信息"])},
  "10455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与预约者信息相同."])},
  "10456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿访问方法"])},
  "10457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
  "10458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择访问宿舍的方法."])},
  "10459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钟路柠檬树"])},
  "10460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徒步"])},
  "10461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆"])},
  "10462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["庆州住宅"])},
  "10463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法停车的宿舍"])},
  "10464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额及折扣信息"])},
  "10465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录后享受打折优惠!"])},
  "10466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总结算金额"])},
  "10467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择结算方式"])},
  "10468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用/扣账卡"])},
  "10469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简便转账"])},
  "10470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠"])},
  "10471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡"])},
  "10472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payco结算优惠"])},
  "10473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付5万元以上时立即优惠5000元!"])},
  "10474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣除和豁免"])},
  "10475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出凭证"])},
  "10476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未发行"])},
  "10477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金收据卡号"])},
  "10478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用积分或优惠券时,实际现金结算的 只发行金额的现金收据."])},
  "10480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此付款方式下次继续使用"])},
  "10481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可取消及手续费"])},
  "10482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据取消及退款规定,可能会发生不可取消、手续费"])},
  "10483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未成年人和法定代理人必须"])},
  "10484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未成年人如果没有法定代理人同行,就不能入住."])},
  "10485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部同意使用条款"])},
  "10486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意住宿商品使用规则(必须)"])},
  "10487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意取消/退款规定(必须)"])},
  "10488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意向第3方提供个人信息(必须)"])},
  "10489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您输入的号码将变更为安心号码,并转交给宿舍.但是,仅限于难以处理安心号码的情况根据个人信息提供同意,可以传达实际手机号码."])},
  "10490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRW"])},
  "10491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["张"])},
  "10492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改认证"])},
  "10493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您输入的号码将变更为安心号码,并转交给宿舍.但是,仅限于难以处理安心号码的情况根据个人信息提供同意,可以传达实际手机号码."])},
  "10494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总0元 打折"])},
  "10495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总 打折"])},
  "10496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P 保有"])},
  "10497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙拉酒店独有的特别优惠"])},
  "10498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韩民国预备,战,现役士兵/干部认证时打折!"])},
  "10499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["爱国会员优惠10%"])},
  "10500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择未发行时也会自动发放现金收据"])},
  "10501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约日期"])},
  "10502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除取消明细"])},
  "10503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约完毕"])},
  "10504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约已结束 "])},
  "10505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计预定明细"])},
  "10506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 退房24小时后将发放积分/图章"])},
  "10507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(时租商品不提供图章.)"])},
  "10508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以预约明细"])},
  "10509": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除预约明细"])},
  "10510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改时租使用时间"])},
  "10511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消及退款规定"])},
  "10512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细查看"])},
  "10513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装购物车"])},
  "10514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即预约"])},
  "10515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新的休闲·门票吧"])},
  "10516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以见到充满欢乐的旅行."])},
  "10517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去看新的休闲·门票"])},
  "10518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更快地见到关心地宿舍!"])},
  "10519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去看新宿舍"])},
  "10520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选一下新的宿舍吧"])},
  "10521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有照片"])},
  "10522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表照片"])},
  "10523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Talk"])},
  "10524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakao Talk"])},
  "10525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即折扣优惠券"])},
  "10526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大 元 优惠券"])},
  "10527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常客优惠券"])},
  "10528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改日期"])},
  "10529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择客房"])},
  "10530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿信息"])},
  "10531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舍介绍"])},
  "10532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细查看"])},
  "10533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["便利设施及服务"])},
  "10534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约公告"])},
  "10535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有上传的评论"])},
  "10536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申报"])},
  "10537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择客房"])},
  "10538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好莱坞房"])},
  "10539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客房设施"])},
  "10540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健身"])},
  "10541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桑拿"])},
  "10542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水疗"])},
  "10543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游泳池"])},
  "10544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高尔夫练习场"])},
  "10545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙龙"])},
  "10546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会议厅"])},
  "10547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空气净化器"])},
  "10548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒吧"])},
  "10549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西餐厅"])},
  "10550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
  "10551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该客房不能预约车辆使用顾客,敬请参考使用."])},
  "10553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消规定"])},
  "10555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿预约"])},
  "10556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用时间"])},
  "10557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业时间"])},
  "10558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時租预约"])},
  "10559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约截止"])},
  "10560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到此为止"])},
  "10561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请更改地区、日期或人员"])},
  "10562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有符合条件的宿舍"])},
  "10563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐"])},
  "10564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "10565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐酒店沙拉的周边宿舍"])},
  "10566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["梦寐以求的完美宿舍"])},
  "10567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各地区推荐宿舍"])},
  "10568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍每月酒店沙拉推荐的场所"])},
  "10569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整个评论"])},
  "10570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请使用该宿舍并填写第一个评价!"])},
  "10571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看图片评价"])},
  "10572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按钮区域"])},
  "10573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门检索词"])},
  "10574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近搜索词"])},
  "10575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩屋"])},
  "10576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有找到的结果吗?"])},
  "10577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这样的搜索词怎么样?"])},
  "10578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["济州島"])},
  "10579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丽水"])},
  "10580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滑水"])},
  "10581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全州"])},
  "10582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩水"])},
  "10583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["束草"])},
  "10584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果想私人休息的話"])},
  "10585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿舍位置"])},
  "10586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿日期"])},
  "10587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿人员"])},
  "10588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
  "10589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儿童"])},
  "10590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找自己的宿舍"])},
  "10591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["箭头"])},
  "10592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠北"])},
  "10593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠南"])},
  "10594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全北"])},
  "10595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "10596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火"])},
  "10597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水"])},
  "10598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
  "10599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金"])},
  "10600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土"])},
  "10601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晴"])},
  "10602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多云"])},
  "10603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阴"])},
  "10604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雨"])},
  "10605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雪"])},
  "10606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雨/雪"])},
  "10607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1夜基准"])},
  "10608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开吧 #把釜山的一切都抛开"])},
  "10609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在是车营时代"])},
  "10610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车宿新手导游"])},
  "10611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平昌咖啡厅巴塞罗那"])},
  "10612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探访感性咖啡店! 气氛美食店"])},
  "10613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜晚的浪漫给你"])},
  "10614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有我知道的邻里 / 忠州"])},
  "10615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秋日的戏水"])},
  "10616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内感性泳池别墅BEST3"])},
  "10617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要不要和我去看星星?"])},
  "10618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全国星空圣地天文台"])},
  "10619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["济州美食之星"])},
  "10620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["济州岛明星厨师的美食店"])},
  "10621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冷也太冷了!"])},
  "10622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看着Netflix打滚怎么样?"])},
  "10623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为历史御宅们准备的历史之旅"])},
  "10624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密阳遗址月渊亭"])},
  "10625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去群山做一次时间旅行吧!"])},
  "10626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["神秘的群山时间旅行村"])},
  "10627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["春川特产土豆面包"])},
  "10628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有人只吃过一次!"])},
  "10629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找旅行地"])},
  "10630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脚步轻盈的这周天气是?"])},
  "10631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在很受欢迎的住宿"])},
  "10632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["军人身份认证查询"])},
  "10633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入爱国门户网站会员后进行本人认证 必须完成才能适用折扣."])},
  "10634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["爱国认证"])},
  "10635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20岁~35岁男性会员"])},
  "10636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干部认证"])},
  "10637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役干部、军务员、国防部公务员"])},
  "10638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1人使用卷"])},
  "10639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含视频拍摄"])},
  "10640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
  "10641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请重新确认优惠券号码"])},
  "10642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭使用优惠券"])},
  "10643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用优惠券"])},
  "10644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般优惠券"])},
  "10645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的一般优惠券"])},
  "10646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择"])},
  "10647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打折"])},
  "10648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复优惠券"])},
  "10649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的重复优惠券"])},
  "10650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用优惠券"])},
  "10651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿优惠券信息"])},
  "10652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约时可立即使用的优惠券."])},
  "10653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券注意事项介绍"])},
  "10654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本优惠券无法使用访问结算及非会员使用"])},
  "10655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本优惠券可在预约时立即使用,剩余折扣优惠券数 数量剩余时可在预约页面选择使用."])},
  "10656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据优惠券生成数量可能会提前截止."])},
  "10657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本优惠可能会因合作店及酒店沙拉的情况而变更/终止."])},
  "10658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭优惠券使用明细"])},
  "10659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券使用明细"])},
  "10660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠券总折扣额"])},
  "10661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "10662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周边预约"])},
  "10663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐顺序"])},
  "10664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新顺序"])},
  "10665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门场所"])},
  "10666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行"])},
  "10667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美食店"])},
  "10668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用过滤器"])},
  "10669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
  "10670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["便利设施 个 选择"])},
  "10671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
  "10672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用过滤器"])},
  "10673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置过滤器"])},
  "10674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室"])},
  "10675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭图像"])},
  "10676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图像"])},
  "10677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择区域"])},
  "10678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册会员"])},
  "10679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择住宿人员"])},
  "10680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择住宿人员"])},
  "10681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
  "10682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广播"])},
  "10683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动·企划展"])},
  "10684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服中心"])},
  "10685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "10686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
  "10687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回到以前的画面吗?"])},
  "10688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您输入的信息将全部消失"])},
  "10689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后页"])},
  "10690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结算已取消."])},
  "10691": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发生结算错误. 请再试一次."])},
  "10692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定要删除吗?"])},
  "10693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相应明细将从列表中删除"])},
  "10694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请登录后使用"])},
  "10695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了使用服务请登录"])},
  "10696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "10697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定要解除吗?"])},
  "10698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该产品将从选择目录中解除"])},
  "10699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除"])},
  "10700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申报已完成."])},
  "10701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申报评论"])},
  "10702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["举报"])},
  "10703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用相应账号登录SNS 确定要连接吗?"])},
  "10704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
  "10705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有选择的优惠券"])},
  "10706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大韩民国住宿大战"])},
  "10708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行本人认证"])},
  "10709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不进行本人认证"])},
  "10710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID : 的住宿大战优惠券已发放完毕"])},
  "10711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有已发放的优惠券"])},
  "10712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去预约"])},
  "10713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入优惠券发放信息"])},
  "10714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※请选择现在居住的地区"])},
  "10715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都/市"])},
  "10716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
  "10717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市/郡/区"])},
  "10718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意利用住宿大战的个人信息"])},
  "10719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为发放住宿大战优惠券提供个人信息 必须确认条款并同意"])},
  "10720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部同意使用条款"])},
  "10721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按先后顺序领取"])},
  "10722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享"])},
  "10723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享Kakao Talk"])},
  "10724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享SMS"])},
  "10725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
  "10726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒店沙拉推荐顺序"])},
  "10727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离顺序"])},
  "10728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论多的顺序"])},
  "10729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随机顺序"])},
  "10730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時租价格低顺序"])},
  "10731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時租价格高顺序"])},
  "10732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿价格低顺序"])},
  "10733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿价格高顺序"])},
  "10734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意用户协议,收集及使用个人信息"])},
  "10735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意取消/退款规定"])},
  "10736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消规定"])},
  "10737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠卷政策"])},
  "10738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分政策"])},
  "10739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意住宿商品使用规则"])},
  "10740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意向第三方提供个人信息"])},
  "10741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供个人信息第三方可以拒绝同意 这种情况下不能使用服务."])},
  "10742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时租使用时间"])},
  "10743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭宿舍访问方法"])},
  "10744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿访问方法 "])},
  "10745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徒步访问"])},
  "10746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车辆访问"])},
  "10747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前无法停车"])},
  "10748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业执照信息"])},
  "10749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(株)ROONET"])},
  "10750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表理事:朴基贤"])},
  "10751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业执照号:215-87-14179"])},
  "10752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通讯销售编号:2021-首尔麻浦-2542号"])},
  "10753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址:首尔特别市麻浦区杨花路161, K-SQUARE 5楼513号 (东桥洞)"])},
  "10754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服中心:02-407-8640"])},
  "10755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运营时间:09:00~18:00"])},
  "10756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件:roonet.co"])},
  "10757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托管服务提供商:股份公司(株)ROONET"])},
  "10758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息处理指南"])},
  "10759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告/合作咨询"])},
  "10761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菜单打开/ 关闭"])},
  "10762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "10763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我周围"])},
  "10764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检索"])},
  "10765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的沙拉"])},
  "10766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车里装了商品"])},
  "10767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快捷键"])},
  "10768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择条件"])},
  "10769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加到 Pick 列表中"])},
  "10770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个 选择"])},
  "10771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入帐号"])},
  "10772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码"])},
  "10773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["岁 以上"])},
  "10774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择  "])},
  "10775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用  "])},
  "10776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都选好了  "])},
  "10777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入预约号码  "])},
  "10778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入手机号码  "])},
  "10779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入认证号码  "])},
  "10780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入帐号(电子邮件)  "])},
  "10781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新输入密码  "])},
  "10782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["传送认证号码  "])},
  "10783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再输入一次密码  "])},
  "10784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请告诉我想要删除账号的理由  "])},
  "10785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前年度使用金额100万元以上<br>购买件数20件以上  "])},
  "10786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宿优惠50%(共 5次)  "])},
  "10787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 所有会员等级维持期限为1年  "])},
  "10788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用的优惠券  "])},
  "10790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 ID 或密码不正确。请重新输入。"])},
  "10791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请同意所有条款和条件。"])},
  "10792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证失败."])},
  "10793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请同意所有条款和条件"])},
  "10794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的询问已收到"])},
  "10795": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请同意所有必要的同意"])},
  "10796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码不匹配。请再检查一次."])},
  "10797": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已超过输入时间。请点击重新发送按钮."])},
  "10798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码已重新发送。如果您没有收到验证码，请检查您输入的信息."])},
  "10799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码已重新发送."])},
  "10800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注销已完成."])},
  "10801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消会员资格失败."])},
  "10802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人验证号码不匹配."])},
  "10803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请仔细检查您的昵称."])},
  "10804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此昵称已在使用."])},
  "10805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配"])},
  "10806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入 8~20 个字符的密码，包括英文和数字字符."])},
  "10807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐码已复制."])},
  "10808": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入预订名称."])},
  "10809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请验证您的手机号码."])},
  "10810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入访客姓名."])},
  "10811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入访客的手机号码."])},
  "10812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择参观住宿的方式"])},
  "10813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择付款方式."])},
  "10814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请同意收集现金收据信息."])},
  "10815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请同意所有条款和条件."])},
  "10816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买费用为0韩元以下."])},
  "10817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你想预约吗?"])},
  "10818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证成功."])},
  "10819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证失败."])},
  "10820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有区域。请联系管理员."])},
  "10821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约时间不可用."])},
  "10822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出现问题."])},
  "10823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可能保持在同一日期."])},
  "10824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入一个搜索词."])},
  "10825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的优惠券代码"])},
  "10826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请写至少10个字符"])},
  "10827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的手机号码"])},
  "10828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码"])},
  "10829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请告诉我们您为什么要删除您的帐户"])},
  "10830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的昵称"])},
  "10831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入你的密码"])},
  "10832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择查询类型"])},
  "10833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入主题"])},
  "10834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请写下您的诚实评价  <br><br>  写文评时:30P立即支付<br>  写照片评论时 : 50P立即支付"])},
  "10835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的姓名"])},
  "10836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按地区或产品名称搜索"])},
  "10837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有多少人要离开?"])},
  "10838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你要去哪里?"])},
  "10839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的出发日期是什么时候?"])},
  "10840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业信息"])},
  "10841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首席执行官"])},
  "10842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司注册号码"])},
  "10843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮购号"])},
  "10844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "10845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服中心"])},
  "10846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业时间"])},
  "10847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
  "10848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托管服务提供商"])},
  "10849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务使用条款"])},
  "10850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息处理方针"])},
  "10851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置信息使用条款"])},
  "10852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青少年保护政策"])},
  "10853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告/合作咨询"])},
  "10854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒店沙拉作为邮购经纪人，并非邮购方，预订、使用及退款<br>商品等相关义务和责任由各卖家承担."])},
  "10855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "10856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个<br>新的住宿"])},
  "10857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以更快地找到您感兴趣的住宿！"])},
  "10858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい宿泊施設を見る"])},
  "10859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit"])},
  "area00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "area01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul"])},
  "area02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busan"])},
  "area03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon"])},
  "area04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju"])},
  "area05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daejeon"])},
  "area06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daegu"])},
  "area07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulsan"])},
  "area10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "area11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["match"])},
  "area12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "area13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "area14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "area15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "area16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "area17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "area18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "area0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "area0100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Seoul"])},
  "area0101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangnam/Yeoksam/Seocho/Samsung"])},
  "area0102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangdong/Cheonho/Gildong/Dunchon/Bangi/Shincheon"])},
  "area0103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangbuk/Mia/Seongbuk/Suyu/Wolgok"])},
  "area0104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo/Kkachisan Station"])},
  "area0105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwanak/Dongjak/Sadang/Seoul National University/Silrim/"])},
  "area0106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guro/Geumcheon/Sindorim/Error"])},
  "area0107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeonmok / Sangbong / Jungnang / Taereung / Nowon / Dobong / Changdong"])},
  "area0108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myeongdong/Hoehyeon/Itaewon/Yongsan/Jung-gu"])},
  "area0109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokdong/Hwagok/Yangcheon"])},
  "area0110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seodaemun/Mapo/Eunpyeong"])},
  "area0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seolleung/Shinsa/Nonhyeon/Bangbae"])},
  "area0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songpa/Jamsil/Saenae"])},
  "area0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinchon/Hapjeong/Hongdae/Bulgwang/Yeonsinnae/Eungam"])},
  "area0114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeouido/Yeongdeungpo"])},
  "area0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wangsimni/Seongsu/Geumho/Chungmuro/Sindang/Yaksu"])},
  "area0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoegi/Anam/Cheongnyangni/Sinseol/Dongdaemun"])},
  "area0117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan-dong/Dapsimni/Kondae/Gunja/Guui/Gwangjin"])},
  "area0118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongno / Daehakro / Sungshin Women's University"])},
  "area0200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Busan area"])},
  "area0201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangseo-gu (Myeongji, Shinho) / Buk-gu (Deokcheon, Mandeok, Gupo, Hwahwa, Buk-gu)"])},
  "area0202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geumjeong-gu (Busan National University, Guseo, Yeonsan, Togok) / Dongnae-gu (Oncheonjang, Sajik)"])},
  "area0203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captain (Captain/Cheonggwan)"])},
  "area0204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Gyeongseong University, Daeyeon, Yongho, Munhyeon) / Jung-gu (Nampo-dong) / Dong-gu (Busan Station, Beomil-dong) / Yeongdo-do (Yeongdo)"])},
  "area0206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busanjin-gu (Seomyeon, Yangjeong, Choeup)/Sasang/Yeonje"])},
  "area0207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Songdo)/Saha-gu (bottom, Saha)"])},
  "area0208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haeundae-gu (Songjeong/Suyeong-gu (Gwangalli, Millak)/Resong"])},
  "area0300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Incheon"])},
  "area0301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganghwa/Ongjin/Jung-gu/Baengnyeongdo"])},
  "area0302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu/Michuhol/Namdong-gu"])},
  "area0303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dongam/tidal stone"])},
  "area0304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bupyeong/Gyeyang/Seogu/Juan/Geomdan"])},
  "area0305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Songdo/Yeonsu"])},
  "area0306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolmi Island/Chinatown/Sinpo/Dongincheon"])},
  "area0307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incheon Airport/Eulwang-ri"])},
  "area0400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gwangju area"])},
  "area0401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine area"])},
  "area0402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangsan-gu Hanam/Songjeong Station"])},
  "area0403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu"])},
  "area0404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu"])},
  "area0405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu"])},
  "area0406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Western"])},
  "area0500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Daejeon"])},
  "area0501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daedeok-gu (Shintanjin/Jungri)"])},
  "area0502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Yongjeon/Complex Terminal)"])},
  "area0503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Dunsan/Yongmun/Wolpyeong)"])},
  "area0504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteor sphere (Meteor Seal/Design/Pole)"])},
  "area0505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Jung-gu Bank/Daeheung/Seonhwa/Yucheon)"])},
  "area0600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Daegu area"])},
  "area0601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu (Daemyung/Bongdeok/Seobu Bus Stop)"])},
  "area0602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseo-gu (Duryu Park/Duryu/Bonri/Jukjeon/Gamsam/Seongseo Industrial Complex/Kyemyung University)"])},
  "area0603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalseong-gun"])},
  "area0604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-gu (Dongdaegu Station/Shinam/Shincheon/Deungchon Amusement Park/Daegu Airport/Innovation City)"])},
  "area0605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu (Daegu Station/EXCO/Chilgok 3 District/Taejeon-dong)"])},
  "area0606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo-gu (Pyeongri/Naedang/Bisan/Wondae)"])},
  "area0607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suseong-gu (Deulan-gil/Suseong-mot)"])},
  "area0608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu (Kyungbuk National University/Dongseong-ro/City Hall/Seomun Market)"])},
  "area0700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Ulsan"])},
  "area0701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu/Dong-gu"])},
  "area0702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulju-gun"])},
  "area0703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jung-gu/Buk-gu"])},
  "area1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Gangwon area"])},
  "area1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangneung/Donghae/Samcheok"])},
  "area1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goseong/Sokcho/Yangyang"])},
  "area1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanggu/Inje/Cheorwon/Hwacheon"])},
  "area1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wonju/Hoengseong"])},
  "area1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuncheon/Hongcheon"])},
  "area1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongchang/Jeongseon/Youngwol/Taebaek"])},
  "area1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All match area"])},
  "area1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gapyeong/Cheongpyeong/Yangpyeong"])},
  "area1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goyang/Ilsan/Gimpo/Janggi/Gurae/Daemyeong Port"])},
  "area1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwacheon/Indeokwon"])},
  "area1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gwangju/Yeoju/Icheon"])},
  "area1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namyangju/Guri/Uijeongbu/Hanam"])},
  "area1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byeongjeom/Osan/Dongtan"])},
  "area1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bucheon"])},
  "area1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gyuun/Seryu/Suwon Station/Jangan/Paldalmun)"])},
  "area1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suwon (Gwonseon/Youngtong/Ingye)"])},
  "area1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siheung/Gwangmyeong"])},
  "area1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansan/Daebudo"])},
  "area1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyang/Pyeongchon/Gunpo/Uiwang/Geumjeong/Sanbon"])},
  "area1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paju/Geumchon/Heyri/Unification Garden"])},
  "area1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyeongtaek/Songtan/Anseong"])},
  "area1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pocheon/Yangju/Dongducheon/Yeoncheon/Jangheung"])},
  "area1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanam/Seongnam/Bundang/Yongin"])},
  "area1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hwaseong/Jebudo"])},
  "area1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongnam"])},
  "area1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geoje/Tongyeong/Goseong"])},
  "area1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geochang/Hamyang/Hapcheon/Sancheong/Changnyeong"])},
  "area1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimhae/Yangsan/Miryang/Jangyu"])},
  "area1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinju/Namhae/Sacheon/Hadong"])},
  "area1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Masan, Jinhae)"])},
  "area1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Myeongseo, Palyong, Bonggok, Buk-myeon)"])},
  "area1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changwon (Sangnam, Yongho, Jungang)"])},
  "area1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haman/Uiryeong"])},
  "area1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All areas of Gyeongbuk"])},
  "area1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongju/Yeongcheon/Gyeongsan/Cheongdo"])},
  "area1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimcheon/Seongju/Goryeong/Chilgok/Gumi/Gunwi"])},
  "area1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mungyeong/Sangju/Yecheon/Yeongju/Bonghwa"])},
  "area1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andong / Uiseong / Cheongsong / Yeongdeok / Yeongyang / Uljin"])},
  "area1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nam-gu, Pohang"])},
  "area1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buk-gu, Pohang"])},
  "area1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeollanam-do"])},
  "area1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangjin/Haenam/Wando/Jindo"])},
  "area1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gurye/Gokseong/Damyang/Jangseong/Yeonggwang/Hampyeong"])},
  "area1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokpo/Muan/Shinan"])},
  "area1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boseong/Goheung/Jangheung"])},
  "area1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeosu/Gwangyang/Suncheon"])},
  "area1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youngam/Naju/Hwasun"])},
  "area1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All regions of Jeollabuk-do"])},
  "area1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunsan/Gimje"])},
  "area1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muju/Jinan/Jangsu"])},
  "area1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buan/Jeongeup/Gochang"])},
  "area1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imsil/Namwon/Sunchang"])},
  "area1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonju/Iksan/Wanju"])},
  "area1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Jeju"])},
  "area1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seogwipo/Marado"])},
  "area1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aewol/Hyupjae"])},
  "area1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju City"])},
  "area1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All over Chungcheongnam-do"])},
  "area1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeryong/Gongju/Nonsan/Geumsan"])},
  "area1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boryeong/Buyeo/Seocheon/Daecheon"])},
  "area1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesan/Hongseong/Cheongyang"])},
  "area1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheonan/Asan"])},
  "area1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of Chungnam/Sejong"])},
  "area1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taean/Dangjin/Seosan/Anmyeondo"])},
  "area1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the whole of Chungbuk"])},
  "area1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeun/Okcheon/Youngdong"])},
  "area1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jecheon/Danyang"])},
  "area1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheongju/Boeun/Jincheon"])},
  "area1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungju/Eumseong/Goesan/Suanbo/Jungpyeong"])},
  "weather00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoul Gyeonggi"])},
  "weather01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangwon"])},
  "weather02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungnam"])},
  "weather03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chungbuk"])},
  "weather04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonnam"])},
  "weather05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeonbuk"])},
  "weather06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongbuk"])},
  "weather07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyeongnam"])},
  "weather08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeju"])},
  "day월": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "day화": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "day수": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "day목": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "day금": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "day토": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "day일": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "dayMo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "dayTu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "dayWe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "dayTh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "dayFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "daySa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "daySu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
}