<template>
	<a class="btn-top" v-on:click="goTop()" v-if="isShowTopButton">
		<i class="icon icon-top">TOP</i>
	</a>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, onUnmounted } from '@vue/runtime-core';
export default {
	setup() {
		const isShowTopButton = ref(false);
		const handleScroll = () => {
			if (window.pageYOffset > 0) {
				isShowTopButton.value = true;
			} else {
				isShowTopButton.value = false;
			}
		};

		onMounted(() => {
			window.addEventListener("scroll", handleScroll);
		});

		onUnmounted(()=>{
			window.removeEventListener("scroll", handleScroll);
		});

		return {
			isShowTopButton,
			goTop() {
			// cancel if already on top
				// if (document.scrollingElement.scrollTop === 0) return;

				// const cosParameter = document.scrollingElement.scrollTop / 2;
				// let scrollCount = 0, oldTimestamp = null;

				// function step (newTimestamp) {
				// 	if (oldTimestamp !== null) {
				// 		// if duration is 0 scrollCount will be Infinity
				// 		scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
				// 		if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
				// 		document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
				// 	}
				// 	oldTimestamp = newTimestamp;
				// 	window.requestAnimationFrame(step);
				// }
				// window.requestAnimationFrame(step);
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		}
	},
}
</script>