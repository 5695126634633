// import api from "@/urls/mev01";
import api from "@/urls/urls_all";
const state = {
  mev0101: [],
  mev0102: [],
  mev0104: [],
};

const getters = {};

const mutations = {
  SET_MEV0101(state, { mev0101 }) {
    state.mev0101 = mev0101;
  },
  SET_MEV0102(state, { mev0102 }) {
    state.mev0102 = mev0102;
  },
  SET_MEV0104(state, { mev0104 }) {
    state.mev0104 = mev0104;
  },
};

const actions = {
  async fetchMev0101({ commit }) {
    const data = await api.getMev0101({ proc_cd: "01" });
    commit("SET_MEV0101", { mev0101: data.data.Res_data });
  },
  async fetchMev0102({ commit }, params) {
    const data = await api.getMev0102(params);
    commit("SET_MEV0102", { mev0102: data.data.Res_data });
  },
  async fetchMev0104({ commit }, params) {
    const data = await api.getMev0104(params);
    commit("SET_MEV0104", { mev0104: data.data.Res_data });
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};