<template>
	<router-view/>
</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from "@/urls/common";

export default {
	name: "App",
	setup: () => {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const onClick = () => {
			if(Object.keys(userData.value).length !== 0) {
				api.getM0000({mem_id: userData.value.mem_token, page_id: route.path});
			}
		}

		store.watch(() => [
			store.state.mmb01.mmb0121,
		], ([mmb0121]) => {
			if(mmb0121 !== null && mmb0121.length > 0) {
				store.commit("SET_USERDATA", {userData: {}});
				store.commit('mhm01/RESET_ALL');
				router.push("/member/dormant");
			}
		}, {immediate: true});
		onMounted(() => {
			store.dispatch('app/initialize');
			document.addEventListener('click', onClick);
			if(isLogin.value && store.state.mmb01.mmb0121 === null) {
				store.dispatch("mmb01/fetchMmb0121", { mem_id: userData.value.mem_id, hp_no: userData.value.hp_no, proc_cd: "02" });
			}
		})
		onUnmounted(() =>{
			document.removeEventListener('click', onClick);
		});
	},
};
</script>