//import api from "@/urls/mmb01";
import api from "@/urls/urls_all";

const state = {
  mmb01: [],
  email: null,
  snsJoinFlag: null,
  snsCi: null,
  snsNickname: null,
  phoneNumber: "",
  snsPhoneNumber: null,
  mmb0121: null,
};

const getters = {};

const mutations = {
  SET_MMB0121(state, { mmb0121 }) {
    state.mmb0121 = mmb0121;
  },
  SET_MMB01(state, { mmb01 }) {
    state.mmb01 = mmb01;
  },
  SET_SNS_INFO(
    state,
    { email, snsJoinFlag, snsCi, snsNickname, snsPhoneNumber }
  ) {
    state.email = email;
    state.snsJoinFlag = snsJoinFlag;
    state.snsCi = snsCi;
    state.snsNickname = snsNickname;
    state.snsPhoneNumber = snsPhoneNumber;
  },
  SET_PHONE_NUMBER(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber;
  },
};

const actions = {
  async fetchMmb0121({ commit }, params) {
    const data = await api.getMmb0121(params);
    commit("SET_MMB0121", { mmb0121: data.data.Res_data });

    return data.data;
  },
  async fetchMmb01({ commit }, params) {
    const data = await api.postMmb01(params);
    if (data.data.Res_msg === "20" || data.data.Res_msg === 20) {
      const mmb0121 = await api.getMmb0121({
        proc_cd: "02",
        mem_id: data.data.Res_data[0].mem_id,
        hp_no: data.data.Res_data[0].hp_no,
      });
      commit("SET_MMB0121", { mmb0121: mmb0121.data.Res_data });
    } else {
      commit("SET_MMB01", { mmb01: data.data.Res_data });
    }

    return data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
