// import api from "@/urls/mhm00";
import api from "@/urls/urls_all";
const state = {
  mhm00: [],
};

const getters = {};

const mutations = {
  SET_MHM00(state, { mhm00 }) {
    state.mhm00 = mhm00;
  },
};

const actions = {
  async fetchMhm00({ commit }) {
    const data = await api.getMhm00({ proc_cd: "01" });
    commit("SET_MHM00", { mhm00: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
