<template>
	<div id="container" class="container">
		<div id="sub" class="sub map">
			<!-- [D] 지역 선택 -->
			<div style="display:block" class="layer">
				<div class="layer__full layer__map">
					<div class="layer__fullbox">
						<div class="layer__fullheader">
							<nav class="nav nav--fit">
								<div class="nav__wrap">
									<a class="nav__btn" @click="handleClose()">
										<i class="icon icon-close"></i>
									</a>
									<p class="nav__item">
										<span class="nav__text">
											숙소 표시
										</span>
									</p>
									<a class="nav__btn">
									</a>
								</div>
							</nav>
						</div>
						<div class="layer__fullbody" style="height: 100%">
							<div class="map__wrap" style="height: 100%">
                                <!-- 지도 -->
                                <div id="map" class="map__box" style="width: 100%" />
								<!-- 맵 마커 (스타일형 - 활성화시 active 클래스 추가) -->
								<!-- <button type="button" class="map__target active">
									50,000원
								</button> -->
								<!-- 맵 마커 (스타일형 - 활성화시 active 클래스 추가) -->
								<!-- <button type="button" class="map__target map__target--2">
									60,000원
								</button> -->
								<!-- 맵 마커 (인라인형) -->
								<!-- <div type="button" style="position:absolute;left:40%;top:40%;padding:2px 8px;line-height:18px;background-color:#ffffff;color:#000000;font-size:12px;border-radius:12px;box-shadow:1px 3px 3px rgba(0,0,0,0.2);border:1px solid #B0B8DB;box-sizing:border-box">
									70,000원
								</div> -->
								<!-- 맵 마커 (인라인형 - 활성화 상태) -->
								<!-- <button type="button" style="position:absolute;left:70%;top:70%;padding:2px 8px;line-height:18px;background-color:#733CE6;color:#FFFFFF;font-size:12px;border-radius:12px;border:1px solid #7235DF;box-shadow:1px 3px 3px rgba(0,0,0,0.2);box-sizing:border-box">
									80,000원
								</button> -->
								<!-- 내 위치 아이콘 -->
								<span class="mylocation" @click="getMyLocation">
									<i class="icon icon-mylocation"></i>
								</span>
								<!-- 선택된 숙소 정보 -->
                                <div class="map__inbox" style="z-index: 9999" v-if="hotel !== undefined">
                                    <a :href="'/rooms/'+hotel.HOTEL_NO">
                                        <div class="summary">
                                            <!-- summary image -->
                                            <div class="summary__thumbnail noneimage">
	                                            <div class="imagebg" :style="{'background-image': 'url(\'' + hotel.IMG_URL + '\')'}" />
                                            </div>
                                            <!-- summary item -->
                                            <div class="summary__itembox">
                                                <div class="summary__itemdata">
                                                    <h3 class="summary__title info-title ellipsis">
                                                        {{hotel.HOTEL_NM}}
                                                    </h3>
                                                    <div class="summary__grad info-grad" v-if="hotel.REVIEWS !== null && hotel.REVIEWS > 0">
                                                        <span class="list__icon info-icon">
                                                            <i class="icon icon-star"></i>
                                                        </span>
                                                        <span class="summary__gradnum info-gradnum">
                                                            {{hotel.SCORE.toFixed(1)}}
                                                        </span>
                                                        <span class="summary__gradcounter info-gradcounter" v-if="hotel.REVIEWS !== null &&  hotel.REVIEWS > 0">
                                                            ({{hotel.REVIEWS.toLocaleString("en-US")}})
                                                        </span>
                                                    </div>
                                                </div>
                                                <ul class="summary__info">
                                                    <li>
                                                        <div class="list__info-type">
                                                            <span class="list__badge">대실</span>
                                                        </div>
                                                        <div class="list__info-price info-price" v-if="hotel.AVAIL_YN_DU === 'N'">
                                                            <div class="info-price__current">
                                                                <span class="grey1">예약마감</span>
                                                            </div>
                                                        </div>
                                                        <div class="list__info-price info-price" v-else>
                                                            <div class="info-price__sale" v-if="(100 - (hotel.SALES_DAYUSE / hotel.STD_DAYUSE * 100)).toFixed(0) > 0">
                                                                <strong v-if="(100 - (hotel.SALES_DAYUSE / hotel.STD_DAYUSE * 100)).toFixed(0) > 0">{{(100 - (hotel.SALES_DAYUSE / hotel.STD_DAYUSE * 100)).toFixed(0) + "%"}}</strong>
                                                                <span>{{hotel.STD_DAYUSE}}</span>
                                                            </div>
                                                            <div class="info-price__current">
                                                                <strong>{{hotel.SALES_DAYUSE ? hotel.SALES_DAYUSE.toLocaleString("en-US") : 0}}</strong>원
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="list__info-type">
                                                            <span class="list__badge list__badge--strong">숙박</span>
                                                        </div>
                                                        <div class="list__info-price info-price" v-if="hotel.AVAIL_YN === 'N'">
                                                            <div class="info-price__current">
                                                                <span class="grey1">예약마감</span>
                                                            </div>
                                                        </div>
                                                        <div class="list__info-price info-price" v-else>
                                                            <div class="info-price__sale" v-if="(100 - (hotel.SALES_AMT / hotel.STD_AMT * 100)).toFixed(0) > 0">
                                                                <strong v-if="(100 - (hotel.SALES_AMT / hotel.STD_AMT * 100)).toFixed(0) > 0">{{(100 - (hotel.SALES_AMT / hotel.STD_AMT * 100)).toFixed(0) + "%"}}</strong>
                                                                <span>{{hotel.STD_AMT}}</span>
                                                            </div>
                                                            <div class="info-price__current">
                                                                <strong>{{hotel.SALES_AMT ? hotel.SALES_AMT.toLocaleString("en-US") : 0}}</strong>원
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex';
import { onBeforeRouteLeave } from 'vue-router';
export default {
	props: ['handleClose', 'list', 'mylocation'], 
    setup(props) {
        let map = null;
        const store = useStore();
        const activedHotelNo = ref(null);
        const onClick = (event) => {
            const target = event.target;
            const prevActive = document.querySelector(".map__target.active");
            if (prevActive) {
                prevActive.classList.remove("active")
            }
            target.classList.add("active");
            activedHotelNo.value = target.dataset.id;
        };

        store.watch(
            () => [store.state.app.location.lat, store.state.app.location.lng], 
            ([lat, lng]) => {
                map.setCenter(new window.kakao.maps.LatLng(lat, lng)); 
            },
        );

        onMounted(() => {
            //console.log("11")
            const container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
                const options = { //지도를 생성할 때 필요한 기본 옵션
                    center: new window.kakao.maps.LatLng(37.5663, 126.9779), //지도의 중심좌표.
                    level: 3 //지도의 레벨(확대, 축소 정도)
                };
            map = new window.kakao.maps.Map(container, options);
            const filteredList = props.list.filter(hotel => hotel.LOC_LATITUDE.length !== 0 && hotel.LOC_LONGITUDE.length !== 0);
            navigator.geolocation.getCurrentPosition(
		        function(position) {
		        	var posi_lat = position.coords.latitude;
		        	var posi_longi = position.coords.longitude;
                    //console.log(position)
		        	store.commit("app/SET_LOCATION", {
		        		location: {
		        			lat: posi_lat,
		        			lng: posi_longi
		        		}
		        	});
		    	}, 
		    );
            
            const lat = store.state.app.location.lat;
            const lng = store.state.app.location.lng;
            
                if(props.mylocation === true && lat !== undefined) {
                    map.setCenter(new window.kakao.maps.LatLng(lat, lng)); 
                }else {
                    map.setCenter(new window.kakao.maps.LatLng(filteredList[0].LOC_LATITUDE, filteredList[0].LOC_LONGITUDE));  
                }
            if(filteredList.length > 0){
                filteredList.forEach((room) => {
                    const content = document.createElement('button');
                    content.className = 'map__target';
                    content.innerHTML = (room.SALES_AMT ? room.SALES_AMT.toLocaleString("en-US") : 0) + '원';
                    content.dataset.id = room.HOTEL_NO;
                    content.type = "button"
                    content.id = `room-overlay-${room.HOTEL_NO}`;
                    content.addEventListener('click', onClick);

                    new window.kakao.maps.CustomOverlay({
                        map: map,
                        position: new window.kakao.maps.LatLng(room.LOC_LATITUDE, room.LOC_LONGITUDE), // 마커를 표시할 위치
                        content,
                    })
                });
            }
         })

        const getMyLocation = () => {
            try {
				store.dispatch("app/requestLocation");
			} catch(e) {
				navigator.geolocation.getCurrentPosition(
					function(position) {
					var posi_lat = position.coords.latitude;
					var posi_longi = position.coords.longitude;
					store.commit("app/SET_LOCATION", {
						location: {
							lat: posi_lat,
							lng: posi_longi
						}
					});
					}, 
				);
			}
        }

        onUnmounted(() => {
            document.querySelectorAll(".map__target").forEach((target) => {
                target.removeEventListener('click', onClick);
            });
        });

        onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

        return {
            hotel: computed(() => {
                return props.list.find((item) => {
                    return item.HOTEL_NO === activedHotelNo.value;
                });
            }),
            getMyLocation,
        }

    },
}
</script>