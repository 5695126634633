<template>
  <header id="header" class="header">
    <!-- [D] 확인사항 있을 시 active 클래스 추가 -->
    <button
      type="button"
      class="btn-menu"
      @click="handleOpenMenu()"
    >
      <span class="blind">메뉴 열기/닫기</span>
      <span class="btn-menu__wrap">
        <span class="btn-menu__bar btn-menu__bar--top"></span>
        <span class="btn-menu__bar btn-menu__bar--middle"></span>
        <span class="btn-menu__bar btn-menu__bar--bottom"></span>
      </span>
    </button>
    <img src="@/assets/images/logo_main.png" style="height:18px;margin-top:2px;" onerror="@/assets/images/logo_salad.png" alt="" >
    <div class="header__menu">
      <nav class="gnb">
       <select @change="change_locale($event)" v-model="selected" class="select_lang" v-if="useselectlang === 'Y'">
          <option class="ko" :class="lang === 'ko'? 'active':''" value="ko" v-if="lang_ko === 'Y'">한국어</option>
          <option class="en" :class="lang === 'en'? 'active':''" value="en" v-if="lang_en === 'Y'">English</option>
          <option class="cn" :class="lang === 'cn'? 'active':''"  value="cn" v-if="lang_cn === 'Y'">中文</option>
          <option class="jp" :class="lang === 'jp'? 'active':''" value="jp" v-if="lang_jp === 'Y'">日本語</option>
        </select>
        <!-- [D] 확인사항 있을 시 active 클래스 추가 -->
      <nav
        class="snb"
        :class="{ active: mmy0101.length > 0 && mmy0101[0].basket_cnt > 0 }"
      >
        <a @click="handlePick">
          <i class="icon icon-cart"></i>
        </a>
        <span
          class="snb__number"
          v-if="mmy0101.length > 0 && mmy0101[0].basket_cnt > 0"
        >
          {{ mmy0101[0].basket_cnt > 99 ? "99+" : mmy0101[0].basket_cnt }}
        </span>
      </nav>
        <!-- [D] 해당 페이지 일 때 gnb__item--active 클래스 추가 -->
        <router-link
          to="/"
          class="gnb__item"
          :class="{ 'gnb__item--active': path === '/' }"
        >
          {{t('5')}}
        </router-link>
        <router-link
          to="/rooms"
          class="gnb__item"
          :class="{ 'gnb__item--active': path === '/rooms' }"
        >
          {{t('6')}}
        </router-link>
        <!-- <router-link
          to="/feed"
          class="gnb__item"
          :class="{ 'gnb__item--active': path === '/feed' }"
        >
          피드
        </router-link> -->
      </nav>
      
    </div>
  </header>
  <Transition name="menu">
    <Menu
      v-if="openMenu"
      :handleClose="handleCloseMenu"
    />
  </Transition>
  
	<MessageLogin v-if="openLogin" :handleClose="handleCloseLogin" :handleSubmit="handleSubmit" />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";
import i18n from "@/i18n"; 
import { useI18n } from 'vue-i18n' //번역필수 모듈
import MessageLogin from '@/components/layers/Message_login';
export default {
	components: {
		MessageLogin,
	},
  setup() {
    const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const openLogin = ref(false);
    const openMenu = ref(null);
    const lang = ref();
    const logo = ref();
    const useselectlang = ref();
    const lang_ko = ref();
    const lang_en = ref();
    const lang_cn = ref();
    const lang_jp = ref();
    const selected = store.state.app.lang
    const { t }= useI18n() //번역필수 모듈

    const handleOpenMenu = () => {
      openMenu.value = true;
    };
    const handleCloseMenu = () => {
      openMenu.value = false;
    };
    const handlePick = () => {
			if(!isLogin.value) {
				openLogin.value = true;
			}else {
				router.push("/mypage/cart");
			}
		}
    const change_locale = (event) =>{
      if(event.target.value === 'ko'){
        store.commit("app/SET_LANG", {
			    		lang: 'ko'
				});
        i18n.global.locale.value = 'ko'
        location.reload();
      }else if(event.target.value === 'en'){
        store.commit("app/SET_LANG", {
			    		lang: 'en'
				});
        i18n.global.locale.value = 'en'
        location.reload();
      }else if(event.target.value === 'cn'){
        store.commit("app/SET_LANG", {
			    		lang: 'cn'
				});
        i18n.global.locale.value = 'cn'
        location.reload();
      }else if(event.target.value === 'jp'){
        store.commit("app/SET_LANG", {
			    		lang: 'jp'
				});
        i18n.global.locale.value = 'jp'
        location.reload();
      }
    }
    onMounted(() => {
      if (store.state.mhm01.mhm0101.length === 0) {
        store.dispatch("mhm01/fetchMhm0101");
      }
      if (isLogin.value && store.state.mmy01.mmy0101.length === 0) {
        store.dispatch("mmy01/fetchMmy0101", {
          proc_cd: "01",
          mem_id: userData.value.mem_token,
        });
      }
      lang.value = store.state.app.lang
      logo.value = store.state.app.logo
      //console.log(logo.value)
      useselectlang.value = store.state.app.useselectlang
      
      lang_ko.value = store.state.app.uselang.ko
      lang_en.value = store.state.app.uselang.en
      lang_cn.value = store.state.app.uselang.cn
      lang_jp.value = store.state.app.uselang.jp
    });

    return {
			path: computed(() => route.path),
			handleCloseLogin() {
				openLogin.value = false;
			},
			openLogin,
			handlePick,
			handleSubmit: async () => {
				router.push("/layers/login");
			},
      openMenu,
      isLogin,
      i18n, 
      selected,
      lang_ko,
      lang_en,
      lang_cn,
      lang_jp,
      logo,
      useselectlang,
      t,  //번역필수 모듈
      change_locale,
      handleOpenMenu,
      handleCloseMenu,
      mhm0101: computed(() => store.state.mhm01.mhm0101),
      mmy0101: computed(() => store.state.mmy01.mmy0101),
    };
  },
};
</script>