//import api from "@/urls/mrm02";
//import mrm01Api from "@/urls/mrm01";
import api from "@/urls/urls_all";

const state = {
  mrm0201: {},
  mrm02022: {},
  hotel: {},
  rooms: {},
  mrm0203: {},
  mrm0204: {},
  roomMrm0204: {},
  mrm0205: {},
  mrm0206: {},
  roomsImages: {},
  room: {},
  around: [],
};

const getters = {};

const mutations = {
  SET_MRM0201(state, { mrm0201, hotelNo }) {
    state.mrm0201[hotelNo] = mrm0201;
  },
  SET_MRM02022(state, { mrm02022, hotelNo, prodNo }) {
    if (state.mrm02022[hotelNo] === undefined) {
      state.mrm02022[hotelNo] = {
        [prodNo]: mrm02022,
      };
    } else {
      state.mrm02022[hotelNo][prodNo] = mrm02022;
    }
  },
  SET_ROOM_DETAIL(state, { hotel, rooms, hotelNo }) {
    state.hotel[hotelNo] = hotel;
    state.rooms[hotelNo] = rooms;
  },
  SET_MRM0203(state, { mrm0203, hotelNo }) {
    state.mrm0203[hotelNo] = mrm0203;
  },
  SET_ROOMS_IMAGES(state, { roomImages, hotelNo }) {
    state.roomsImages[hotelNo] = roomImages;
  },
  SET_MRM0204(state, { mrm0204, hotelNo }) {
    state.mrm0204[hotelNo] = mrm0204;
  },
  SET_ROOM_MRM0204(state, { mrm0204, hotelNo, prodNo }) {
    if (state.roomMrm0204[hotelNo] === undefined) {
      state.roomMrm0204[hotelNo] = {
        [prodNo]: mrm0204,
      };
    } else {
      state.roomMrm0204[hotelNo][prodNo] = mrm0204;
    }
  },
  SET_MRM0205(state, { mrm0205, hotelNo }) {
    state.mrm0205[hotelNo] = mrm0205;
  },
  SET_MRM0206(state, { mrm0206, hotelNo }) {
    state.mrm0206[hotelNo] = mrm0206;
  },
  SET_ROOM_DETAIL_ROOM(state, { room, hotelNo, prodNo }) {
    if (state.room[hotelNo] === undefined) {
      state.room[hotelNo] = {
        [prodNo]: room,
      };
    } else {
      state.room[hotelNo][prodNo] = room;
    }
  },
  SET_AROUND(state, { mrm01011 }) {
    state.around = mrm01011;
  },
};
const actions = {
  async fetchMrm0201({ commit }, { hotelNo }) {
    const data = await api.getMrm0201({
      hotel_no: hotelNo,
    });
    commit("SET_MRM0201", { mrm0201: data.data.Res_data, hotelNo });
  },
  async fetchMrm02022({ commit }, { hotelNo, prodNo, SDATE, PROC_CD }) {
    const data = await api.getMrm02022({
      hotel_no: hotelNo,
      prod_no: prodNo,
      SDATE: SDATE,
      PROC_CD: PROC_CD
    });
    commit("SET_MRM02022", { mrm02022: data.data.Res_data, hotelNo, prodNo, SDATE, PROC_CD});
  },
  async fetchRoomDetail(
    { commit },
    {
      hotelNo,
      arrDate,
      depDate,
      areaCd,
      hotelType,
      adultCnt,
      childCnt,
      roomCnt,
      cpYn,
      camcelAvailYn,
      memId,
      S_SALES_YN,
      D_SALES_YN 
    }
  ) {
    const [data, mrm0101Data] = await Promise.all([
      api.getMrm0202({
        hotel_no: hotelNo,
        sdate: arrDate,
        edate: depDate,
        area_cd: areaCd,
        hotel_type: hotelType,
        adult_cnt: adultCnt,
        child_cnt: childCnt,
        room_cnt: roomCnt,
        cp_yn: cpYn,
        camcel_avail_yn: camcelAvailYn,
        S_SALES_YN: S_SALES_YN,
        D_SALES_YN: D_SALES_YN
      }),
      api.getMrm01011({
        hotel_no: hotelNo,
        mem_id: memId,
        sdate: arrDate,
        edate: depDate,
      }),
    ]);
    commit("SET_ROOM_DETAIL", {
      hotel: mrm0101Data.data.Res_data,
      rooms: data.data.Res_data,
      hotelNo,
    });
  },
  async fetchRoomDetailRoom(
    { commit },
    {
      hotelNo,
      prodNo,
      arrDate,
      depDate,
      adultCnt,
      childCnt,
      roomCnt,
      mem_id,
      session_id,
      S_SALES_YN,
      D_SALES_YN,
      ROOM_TYPE
    }
  ) {
    const data = await api.getMrm02021({
      hotel_no: hotelNo,
      prod_no: prodNo,
      sdate: arrDate,
      edate: depDate,
      adult_cnt: adultCnt,
      child_cnt: childCnt,
      room_cnt: roomCnt,
      mem_id,
      session_id,
      S_SALES_YN,
      D_SALES_YN,
      ROOM_TYPE
    });
    if (data.data.Res_data.length > 0) {
      const room = Object.keys(data.data.Res_data[0]).reduce(
        (arr, cur) => ({
          ...arr,
          [cur.toLowerCase()]: data.data.Res_data[0][cur],
        }),
        {}
      );
      const imageData = await api.getMrm0203({
        proc_cd: "02",
        hotel_no: hotelNo,
        room_type: room.room_type,
      });
      commit("SET_ROOM_DETAIL_ROOM", {
        room: { ...room, images: imageData.data.Res_data },
        hotelNo,
        prodNo,
      });
    }
  },
  async fetchMrm0203({ commit }, { procCd, hotelNo }) {
    const data = await api.getMrm0203({
      proc_cd: procCd,
      hotel_no: hotelNo,
    });
    commit("SET_MRM0203", { mrm0203: data.data.Res_data, hotelNo });
  },
  async fetchRoomsImages({ commit }, { hotelNo }) {
    const data = await api.getMrm0203({
      proc_cd: "02",
      hotel_no: hotelNo,
    });
    commit("SET_ROOMS_IMAGES", { roomImages: data.data.Res_data, hotelNo });
  },
  async fetchRoomImages(_, { hotelNo, roomType }) {
    const data = await api.getMrm0203({
      proc_cd: "02",
      hotel_no: hotelNo,
      room_type: roomType,
    });
    return data.data.Res_data;
  },
  async fetchMrm0204({ commit }, { hotelNo }) {
    const data = await api.getMrm0204({
      hotel_no: hotelNo,
    });
    commit("SET_MRM0204", { mrm0204: data.data.Res_data, hotelNo });
  },
  async fetchRoomMrm0204({ commit }, { hotelNo, prodNo }) {
    const data = await api.getMrm0204({
      hotel_no: hotelNo,
      prod_no: prodNo,
    });
    commit("SET_ROOM_MRM0204", {
      mrm0204: data.data.Res_data,
      hotelNo,
      prodNo,
    });
  },
  async fetchMrm0205({ commit }, { hotelNo }) {
    const data = await api.getMrm0205({
      hotel_no: hotelNo,
      proc_cd: "01",
    });
    const images = await Promise.all(
      data.data.Res_data.map((item) =>
        api.getMrm0205({
          hotel_no: hotelNo,
          proc_cd: "02",
          booking_no: item.BOOKING_NO,
        })
      )
    );
    const processedData = data.data.Res_data.map((item, index) => ({
      ...item,
      images: images[index].data.Res_data,
    }));
    commit("SET_MRM0205", { mrm0205: processedData, hotelNo });
  },
  async fetchMrm0206({ commit }, { hotelNo, mem_id,room_type }) {
    const data = await api.getMrm0206({
      proc_cd: "01",
      hotel_no: hotelNo,
      room_type:room_type,
      mem_id,
    });
    commit("SET_MRM0206", { mrm0206: data.data.Res_data, hotelNo });
  },
  async fetchAround({ commit }, params) {
    const data = await api.getMrm01011(params);
    commit("SET_AROUND", { mrm01011: data.data.Res_data });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
