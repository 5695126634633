import fetch from "./fetch";
const CD086_URL = () => "/PC/WPC000_CD086";
const M0000_URL = () => "/PC/WPC_M0000";
const TA_111 = () => "/TA/WTA111_U101";
const MCD00_URL = () => "/PC/WPC_MCD00";
const MCS01_URL = () => "/PC/WPC_MCS01";
const MCS01_01_URL = () => "/PC/WPC_MCS01_01";
const MCS01_02_URL = () => "/PC/WPC_MCS01_02";
const MCS01_03_URL = () => "/PC/WPC_MCS01_03";
const MCS01_04_URL = () => "/PC/WPC_MCS01_04";
const MEV01_01_URL = () => "/PC/WPC_MEV01_01";
const MEV01_02_URL = () => "/PC/WPC_MEV01_02";
const MEV01_04_URL = () => "/PC/WPC_MEV01_04";
const MFD01_01_URL = () => "/PC/WPC_MFD01_01";
const MFD01_02_URL = () => "/PC/WPC_MFD01_02";
const MFD01_03_URL = () => "/PC/WPC_MFD01_03";
const MFD01_04_URL = () => "/PC/WPC_MFD01_04";
const MFD01_011_URL = () => "/PC/WPC_MFD01_011";
const MHM00_URL = () => "/PC/WPC_MHM00";
const MHM01_01_URL = () => "/PC/WPC_MHM01_01";
const MHM01_02_URL = () => "/PC/WPC_MHM01_02";
const MHM01_03_URL = () => "/PC/WPC_MHM01_03";
const MHM01_04_URL = () => "/PC/WPC_MHM01_04";
const WEATHER_URL = () => "/PC/WPC_WEATHER";
const MHM02_URL = () => "/PC/WPC_MHM02";
const MHM021_URL = () => "/PC/WPC_MHM021";
const MMAA_URL = () => "/PC/WPC_MMAA";
const MMB00_URL = () => "/PC/WPC_MMB00";
const MMB01_01_URL = () => "/PC/WPC_MMB01_01";
const MMB01_02_URL = () => "/PC/WPC_MMB01_02";
const MMB01_10_URL = () => "/PC/WPC_MMB01_10";
const MMB01_21_URL = () => "/PC/WPC_MMB01_21";
const MMB01_URL = () => "/PC/WPC_MMB01";
const MMB02_URL = () => "/PC/WPC_MMB02";
const MMB02_01_URL = () => "/PC/WPC_MMB02_01";
const MMB02_02_URL = () => "/PC/WPC_MMB02_02";
const MMB03_URL = () => "/PC/WPC_MMB03";
const MMB03_01_URL = () => "/PC/WPC_MMB03_01";
const MMB03_02_URL = () => "/PC/WPC_MMB03_02";
const MMB03_03_URL = () => "/PC/WPC_MMB03_03";
const MMB03_04_URL = () => "/PC/WPC_MMB03_04";
const MMB09_01_URL = () => "/PC/WPC_MMB09_01";
const MMY01_01_URL = () => "/PC/WPC_MMY01_01";
const MMY01_011_URL = () => "/PC/WPC_MMY01_011";
const MMY01_04_URL = () => "/PC/WPC_MMY01_04"; // 작동 안함
const MMY01_041_URL = () => "/PC/WPC_MMY01_041";
const MMY01_09_URL = () => "/PC/WPC_MMY01_09";
const MMY01_091_URL = () => "/PC/WPC_MMY01_091";
const MMY01_10_URL = () => "/PC/WPC_MMY01_10";
const MMY01_101_URL = () => "/PC/WPC_MMY01_101";
const MMY01_102_URL = () => "/PC/WPC_MMY01_102";
const MMY01_11_URL = () => "/PC/WPC_MMY01_11";
const MMY01_12_URL = () => "/PC/WPC_MMY01_12";
const MMY01_122_URL = () => "/PC/WPC_MMY01_122";
const MMY01_13_URL = () => "/PC/WPC_MMY01_13";
const MMY01_121_URL = () => "/PC/WPC_MMY01_121";
const MMY01_20_URL = () => "/PC/WPC_MMY01_20";
const MMY01_22_URL = () => "/PC/WPC_MMY01_22";
const MMY01_201_URL = () => "/PC/WPC_MMY01_201";
const MMY01_421_URL = () => "/PC/WPC_MMY01_421";
const MMY02_01_URL = () => "/PC/WPC_MMY02_01";
const MMY02_02_URL = () => "/PC/WPC_MMY02_02";
const MMY02_021_URL = () => "/PC/WPC_MMY02_021";
const MMY02_03_URL = () => "/PC/WPC_MMY02_03";
const MMY09_01_URL = () => "/PC/WPC_MMY09_01";
const MMZ01_11_URL = () => "/PC/WPC_MMZ01_11";
const PJ_100 = () => "/PJ/WPJ100_S100";
const PJ_101 = () => "/PJ/WPJ100_S101";
const PJ_102 = () => "/PJ/WPJ100_S102";
const PJ_103 = () => "/PJ/WPJ100_S103";
const PJ_104 = () => "/PJ/WPJ100_S104";
const PJ_105 = () => "/PJ/WPJ100_S105";
const PJ_106 = () => "/PJ/WPJ100_S106";
const MRM01_01_URL = () => "/PC/WPC_MRM01_01";
const MRM01_02_URL = () => "/PC/WPC_MRM01_02";
const MRM01_03_URL = () => "/PC/WPC_MRM01_03";
const MRM01_04_URL = () => "/PC/WPC_MRM01_04";
const MRM01_05_URL = () => "/PC/WPC_MRM01_05";
const MRM01_051_URL = () => "/PC/WPC_MRM01_051";
const MRM01_011_URL = () => "/PC/WPC_MRM01_011";
const MRM02_01_URL = () => "/PC/WPC_MRM02_01";
const MRM02_011_URL = () => "/PC/WPC_MRM02_011";
const MRM02_02_URL = () => "/PC/WPC_MRM02_02";
const MRM02_021_URL = () => "/PC/WPC_MRM02_021";
const MRM02_022_URL = () => "/PC/WPC_MRM02_022";
const MRM02_03_URL = () => "/PC/WPC_MRM02_03";
const MRM02_04_URL = () => "/PC/WPC_MRM02_04";
const MRM02_05_URL = () => "/PC/WPC_MRM02_05";
const MRM02_06_URL = () => "/PC/WPC_MRM02_06";
const PAY01_URL = () => "/PC/WPC_PAY01";
const PAY03_URL = () => "/PC/WPC_PAY03";
export default {
/* common */
  getCd086: fetch("GET", CD086_URL),
  getM0000: fetch("GET", M0000_URL),
  getTa111: fetch("GET", TA_111),
  getMcd00: fetch("GET", MCD00_URL),
  getMcs01: fetch("GET", MCS01_URL),
  getMcs0101: fetch("GET", MCS01_01_URL),
  getMcs0102: fetch("GET", MCS01_02_URL),
  getMcs0103: fetch("GET", MCS01_03_URL),
  getMcs0104: fetch("GET", MCS01_04_URL),
  getMev0101: fetch("GET", MEV01_01_URL),
  getMev0102: fetch("GET", MEV01_02_URL),
  getMev0104: fetch("GET", MEV01_04_URL),
  getMfd0101: fetch("GET", MFD01_01_URL),
  getMfd0102: fetch("GET", MFD01_02_URL),
  getMfd0103: fetch("GET", MFD01_03_URL),
  getMfd0104: fetch("GET", MFD01_04_URL),
  getMfd01011: fetch("GET", MFD01_011_URL),
  getMhm00: fetch("GET", MHM00_URL),
  getMhm0101: fetch("GET", MHM01_01_URL),
  getMhm0102: fetch("GET", MHM01_02_URL),
  getMhm0103: fetch("GET", MHM01_03_URL),
  getMhm0104: fetch("GET", MHM01_04_URL),
  getWeather: fetch("GET", WEATHER_URL),
  getMhm02: fetch("GET", MHM02_URL),
  getMhm021: fetch("GET", MHM021_URL),
  getMmaa1: fetch("get", MMAA_URL),
  getMmb00: fetch("GET", MMB00_URL),
  postMmb0101: fetch("POST", MMB01_01_URL),
  postMmb0110: fetch("POST", MMB01_10_URL),
  getMmb0102: fetch("GET", MMB01_02_URL),
  getMmb0121: fetch("GET", MMB01_21_URL),
  postMmb01: fetch("POST", MMB01_URL),
  getMmb02: fetch("GET", MMB02_URL),
  getMmb0201: fetch("GET", MMB02_01_URL),
  getMmb0202: fetch("GET", MMB02_02_URL),
  getMmb03: fetch("GET", MMB03_URL),
  getMmb0301: fetch("GET", MMB03_01_URL),
  getMmb0302: fetch("GET", MMB03_02_URL),
  getMmb0303: fetch("GET", MMB03_03_URL),
  getMmb0304: fetch("GET", MMB03_04_URL),
  getMmb0901: fetch("GET", MMB09_01_URL),
  getMmy0101: fetch("GET", MMY01_01_URL),
  getMmy01011: fetch("GET", MMY01_011_URL),
  getMmy0104: fetch("GET", MMY01_04_URL),
  getMmy01041: fetch("GET", MMY01_041_URL),
  getMmy0109: fetch("GET", MMY01_09_URL),
  getMmy01091: fetch("GET", MMY01_091_URL),
  getMmy0110: fetch("GET", MMY01_10_URL),
  getMmy01101: fetch("GET", MMY01_101_URL),
  getMmy01102: fetch("GET", MMY01_102_URL),
  getMmy0111: fetch("GET", MMY01_11_URL),
  getMmy0112: fetch("GET", MMY01_12_URL),
  getMmy01122: fetch("GET", MMY01_122_URL),
  getMmy0113: fetch("GET", MMY01_13_URL),
  getMmy01121: fetch("GET", MMY01_121_URL),
  getMmy0120: fetch("GET", MMY01_20_URL),
  getMmy0122: fetch("GET", MMY01_22_URL),
  getMmy01201: fetch("GET", MMY01_201_URL),
  getMmy01421: fetch("GET", MMY01_421_URL),
  getMmy0201: fetch("GET", MMY02_01_URL),
  getMmy0202: fetch("GET", MMY02_02_URL),
  getMmy02021: fetch("GET", MMY02_021_URL),
  getMmy0203: fetch("GET", MMY02_03_URL),
  getMmy0901: fetch("GET", MMY09_01_URL),
  getMmz0111: fetch("GET", MMZ01_11_URL),
  getpj100: fetch("GET", PJ_100),
  getpj101: fetch("GET", PJ_101),
  getpj102: fetch("GET", PJ_102),
  getpj103: fetch("GET", PJ_103),
  getpj104: fetch("GET", PJ_104),
  getpj105: fetch("GET", PJ_105),
  getpj106: fetch("GET", PJ_106),
  getMrm0101: fetch("GET", MRM01_01_URL),
  getMrm0102: fetch("GET", MRM01_02_URL),
  getMrm0103: fetch("GET", MRM01_03_URL),
  getMrm0104: fetch("GET", MRM01_04_URL),
  getMrm0105: fetch("GET", MRM01_05_URL),
  getMrm01051: fetch("GET", MRM01_051_URL),
  getMrm01011: fetch("GET", MRM01_011_URL),
  getMrm0201: fetch("GET", MRM02_01_URL),
  getMrm02011: fetch("GET", MRM02_011_URL),
  getMrm0202: fetch("GET", MRM02_02_URL),
  getMrm02021: fetch("GET", MRM02_021_URL),
  getMrm02022: fetch("GET", MRM02_022_URL),
  getMrm0203: fetch("GET", MRM02_03_URL),
  getMrm0204: fetch("GET", MRM02_04_URL),
  getMrm0205: fetch("GET", MRM02_05_URL),
  getMrm0206: fetch("GET", MRM02_06_URL),
  getPay01: fetch("GET", PAY01_URL),
  getPay03: fetch("GET", PAY03_URL),
};
