import { createWebHistory, createRouter } from "vue-router";

import DefaultLayout from "@/layout/Default";

const routes = [
  {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/Home"),
      },
      {
        path: "homelogin",
        component: () => import("@/views/Home_login"),
      },
    ],
  },
  // 소셜 로그인에 사용하는 callback 페이지
  {
    path: "/callback",
    name: "Callback",
    component: DefaultLayout,
    children: [
      {
        path: "naver",
        component: () => import("@/views/callback/Naver"),
      },
      {
        path: "kakao",
        component: () => import("@/views/callback/Kakao"),
      },
    ],
  },
  /** 숙소 (숙소메인, 전체리뷰, 숙소목록, 숙소목록이미지형, 숙소상세, 객실상세, 숙소 없음) **/
  {
    path: "/rooms",
    name: "Rooms",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/rooms/Main"),
      },
      {
        path: "list",
        component: () => import("@/views/rooms/List"),
      },
      {
        path: "thumbnail",
        component: () => import("@/views/rooms/ListThumbnail"),
      },
      {
        path: ":id",
        component: () => import("@/views/rooms/Detail"),
      },
      {
        path: "reviews/:id",
        component: () => import("@/views/rooms/Reviews"),
      },
      {
        path: ":id/:prodId",
        component: () => import("@/views/rooms/DetailRoom"),
      },
      {
        path: "norent",
        component: () => import("@/views/rooms/DetailRoom_norent"),
      },
      {
        path: "norooms",
        component: () => import("@/views/rooms/Norooms"),
      },
			{
				path: 'noreviews',
				component: () => import('@/views/rooms/Noreviews')
			}
    ],
  },
  /** 레저·티켓 (레저메인, 레저목록, 레저상세, 레어이-구매하기) **/
  {
    path: "/leisure",
    name: "Leisure",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/leisure/Main"),
      },
      {
        path: "list",
        component: () => import("@/views/leisure/List"),
      },
      {
        path: ":id",
        component: () => import("@/views/leisure/Detail"),
      },
    ],
  },
  {
    path: "/feed",
    name: "Feed",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/feed/Main"),
      },
      {
        path: ":id",
        component: () => import("@/views/feed/Detail"),
      },
    ],
  },
  /** 예약 (예약하기, 결제, 예약완료, 예약상세, 취소상세, 비회원예약) **/
  {
    path: "/order",
    name: "Order",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/order/Main"),
      },
      {
        path: "guest",
        component: () => import("@/views/order/Apply_guest"),
      },
      {
        path: "apply",
        component: () => import("@/views/order/Apply"),
      },
      {
        path: "complete/:id",
        component: () => import("@/views/order/Complete"),
      },
      {
        path: "detail/:id",
        component: () => import("@/views/order/Detail"),
      },
      {
        path: "cancel/:id",
        component: () => import("@/views/order/Cancel"),
      },
    ],
  },
  /** 내주변 (숙박, 레저/티켓, 숙박없음, 레저없음) **/
  {
    path: "/around",
    name: "Around",
    component: DefaultLayout,
    children: [
      {
        path: "rooms",
        component: () => import("@/views/around/Rooms"),
      },
    ],
  },
  /** 픽 (숙박 픽, 레저 픽, 숙박픽 없음, 레저픽 없음) **/
  {
    path: "/pick",
    name: "Pick",
    component: DefaultLayout,
    children: [
      {
        path: "rooms",
        component: () => import("@/views/pick/Rooms"),
      },
      {
        path: "leisure",
        component: () => import("@/views/pick/Leisure"),
      },
      {
        path: "norooms",
        component: () => import("@/views/pick/Norooms"),
      },
      {
        path: "noleisure",
        component: () => import("@/views/pick/Noleisure"),
      },
    ],
  },
  /** 회원가입 (약관동의, 휴대폰인증, 정보입력, 가입완료) **/
  {
    path: "/join",
    name: "Join",
    component: DefaultLayout,
    children: [
      {
        path: "step1",
        component: () => import("@/views/join/Step1"),
      },
      {
        path: "step2",
        component: () => import("@/views/join/Step2"),
      },
      {
        path: "step3",
        component: () => import("@/views/join/Step3"),
      },
      {
        path: "complete",
        component: () => import("@/views/join/Complete"),
      },
    ],
  },
  {
    path: "/member",
    name: "Member",
    component: DefaultLayout,
    children: [
      /** 아이디 찾기 (아이디찾기, 인증, 완료) **/
      {
        path: "find/id/step1",
        component: () => import("@/views/member/Findid_step1"),
      },
      {
        path: "find/id/step2",
        component: () => import("@/views/member/Findid_step2"),
      },
      {
        path: "find/id/complete",
        component: () => import("@/views/member/Findid_complete"),
      },
      {
				path: 'find/id/list',
				component: () => import('@/views/member/Findid_list')
			},
      /** 비밀번호 찾기 (비밀번호찾기, 인증, 변경, 완료) **/
      {
        path: "find/password/step1",
        component: () => import("@/views/member/Findpw_step1"),
      },
      {
        path: "find/password/step2",
        component: () => import("@/views/member/Findpw_step2"),
      },
      {
        path: "find/password/step3",
        component: () => import("@/views/member/Findpw_step3"),
      },
      {
        path: "find/password/complete",
        component: () => import("@/views/member/Findpw_complete"),
      },
      /** 회원탈퇴 (비번입력, 사유입력, 완료) **/
      {
        path: "withdraw/step1",
        component: () => import("@/views/member/Withdraw_step1"),
      },
      {
        path: "withdraw/step2",
        component: () => import("@/views/member/Withdraw_step2"),
      },
      {
        path: "withdraw/complete",
        component: () => import("@/views/member/Withdraw_complete"),
      },
      /** 휴면해제 (인증, 번호, 완료) **/
      {
        path: "dormant",
        component: () => import("@/views/member/Dormant"),
      },
      {
        path: "dormant/auth",
        component: () => import("@/views/member/Dormant_auth"),
      },
      {
        path: "dormant/complete",
        component: () => import("@/views/member/Dormant_complete"),
      },
    ],
  },
  /** 여행 취향 (여행지 취향, 여행취향, 완료) **/
  {
    path: "/favorite",
    name: "Style",
    component: DefaultLayout,
    children: [
      {
        path: "step1",
        component: () => import("@/views/favorite/Step1"),
      },
      {
        path: "step2",
        component: () => import("@/views/favorite/Step2"),
      },
      {
        path: "complete",
        component: () => import("@/views/favorite/Complete"),
      },
    ],
  },
  /* 레이어 모음 */
  {
    path: "/layers",
    name: "Layers",
    component: DefaultLayout,
    children: [
      /** 시설 보기 **/
      {
        path: "facility",
        component: () => import("@/components/layers/Facility"),
      },
      /** 지역선택, 날짜선택, 인원선택 , 공유하기 **/
      {
        path: "location",
        component: () => import("@/components/layers/Location"),
      },
      {
        path: "date",
        component: () => import("@/components/layers/Date"),
      },
      {
        path: "member",
        component: () => import("@/components/layers/Member"),
      },
      {
        path: "share",
        component: () => import("@/components/layers/Share"),
      },
      /** 회원 (로그인)**/
      {
        path: "login",
        component: () => import("@/components/layers/Login"),
      },
      {
        path: "time",
        component: () => import("@/components/layers/Time"),
      },
      /** 예약 (방문수단, 쿠폰적용, 쿠폰없음, 쿠폰적용내역, 쿠폰적용내역없음, 군인적용확인) **/
      {
        path: "way",
        component: () => import("@/components/layers/Way"),
      },
      {
        path: "coupon",
        component: () => import("@/components/layers/Coupon"),
      },
      {
        path: "couponstatus",
        component: () => import("@/components/layers/Couponstatus"),
      },
      {
        path: "nocoupon",
        component: () => import("@/components/layers/Nocoupon"),
      },
      {
        path: "nocouponstatus",
        component: () => import("@/components/layers/Nocouponstatus"),
      },
      {
        path: "army",
        component: () => import("@/components/layers/Army"),
      },
      /** 숙박 (정렬, 필터, 선착순쿠폰, 갤러리) **/
      {
        path: "sorting",
        component: () => import("@/components/layers/Sorting"),
      },
      {
        path: "filter",
        component: () => import("@/components/layers/Filter"),
      },
      {
        path: "coupon3",
        component: () => import("@/components/layers/Coupon3"),
      },
      {
        path: "gallery/:id",
        component: () => import("@/components/layers/Gallery"),
      },
      /** 마이헬로스테이 (쿠폰등록) **/
      {
        path: "couponregister",
        component: () => import("@/components/layers/Coupon_register"),
      },
      /** 피드 (필터, 주변예약, 없음) **/
      {
        path: "filterfeed",
        component: () => import("@/components/layers/Filter_feed"),
      },
      {
        path: "feed",
        component: () => import("@/components/layers/Feed"),
      },
      {
        path: "feedno",
        component: () => import("@/components/layers/Feedno"),
      },
      /** 레저 (구매하기) **/
      {
        path: "buy",
        component: () => import("@/components/layers/Buy"),
      },
      /** 전체메뉴 **/
      {
        path: "menu",
        component: () => import("@/components/layers/Menu"),
      },
      /** 지도 (숙소보기, 레저보기) **/
      {
        path: "maprooms",
        component: () => import("@/components/layers/map/Rooms"),
      },
      {
        path: "mapleisure",
        component: () => import("@/components/layers/map/Leisure"),
      },
      /** 팝업 (픽헤제, 목록삭제, 로그인, 뒤로가기, 리뷰신고) **/
      {
        path: "message/pickunset",
        component: () => import("@/components/layers/Message_pickunset"),
      },
      {
        path: "message/historydelete",
        component: () => import("@/components/layers/Message_historydelete"),
      },
      {
        path: "message/login",
        component: () => import("@/components/layers/Message_login"),
      },
      {
				path: 'message/sns',
				component: () => import('@/components/layers/Message_sns')
			},
      {
				path: 'message/errorAlert:id',
				component: () => import('@/components/layers/Message_alert')
			},
      {
				path: 'message/kakaomap',
				component: () => import('@/components/layers/kakaomap')
			},
      {
        path: "message/back",
        component: () => import("@/components/layers/Message_back"),
      },
      {
        path: "message/cancel",
        component: () => import("@/components/layers/Message_cancel"),
      },
      {
        path: "message/error",
        component: () => import("@/components/layers/Message_error"),
      },
      {
        path: "message/police",
        component: () => import("@/components/layers/Message_police"),
      },
      // {
			// 	path: 'message/daejeon',
			// 	component: () => import('@/components/layers/Message_daejeon')
			// },
			// {
			// 	path: 'message/daejeoncoupon',
			// 	component: () => import('@/components/layers/Message_daejeoncoupon')
			// },
			// {
			// 	path: 'message/daejeonalarm',
			// 	component: () => import('@/components/layers/Message_daejeonalarm')
			// },
      {
        path: "message/policecomplete",
        component: () => import("@/components/layers/Message_policecomplete"),
      },
      /**  
       * 숙박대전
       **/
      {
        path: 'Sbdeajeon',
        component: () => import('@/components/layers/Sbdeajeon')
      },
      {
        path: 'Sbdeajeon/auth',
        component: () => import('@/components/layers/Sbdeajeon_auth')
      },
      {
        path: 'Sbdeajeon/result/:id',
        component: () => import('@/components/layers/Sbdeajeon_result')
      },
      /**
       * 정책과 약관
       * (서비스, 개인정보처리방침, 위치정보, 청소년, 숙박규칙, 취소/환불, 개인정보수집, 3자제공, 쿠폰, 포인트)
       **/
      {
        path: "terms/service",
        component: () => import("@/components/layers/Terms_service"),
      },
      {
        path: "terms/private",
        component: () => import("@/components/layers/Terms_private"),
      },
      {
        path: "terms/location",
        component: () => import("@/components/layers/Terms_location"),
      },
      {
        path: "terms/youth",
        component: () => import("@/components/layers/Terms_youth"),
      },
      {
        path: "terms/rooms",
        component: () => import("@/components/layers/Terms_rooms"),
      },
      {
        path: "terms/cancel",
        component: () => import("@/components/layers/Terms_cancel"),
      },
      {
        path: "terms/cancel2",
        component: () => import("@/components/layers/Terms_cancel2"),
      },
      {
        path: "terms/agree",
        component: () => import("@/components/layers/Terms_agree"),
      },
      {
        path: "terms/third",
        component: () => import("@/components/layers/Terms_third"),
      },
      {
        path: "terms/coupon",
        component: () => import("@/components/layers/Terms_coupon"),
      },
      {
        path: "terms/point",
        component: () => import("@/components/layers/Terms_point"),
      },
      /* 로딩 */
      {
        path: "loading",
        component: () => import("@/components/layers/Loading"),
      },
      {
        path: "image",
        component: () => import("@/components/layers/Image"),
      },
    ],
  },
  /** 이벤트 (목록, 상세, 없음) **/
  {
    path: "/event",
    name: "event",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/event/Main"),
      },
      {
        path: ":id",
        component: () => import("@/views/event/Info"),
      },
      {
        path: ":id/thumbnail",
        component: () => import("@/views/event/Info_thumbnail"),
      },
      {
        path: "nolist",
        component: () => import("@/views/event/Nolist"),
      },
      {
        path: "event_detail/:id",
        component: () => import("@/views/event/event_detail"),
      },
      // {
      //   path : 'daejeon',
      //   component: () => import('@/views/event/Daejeon')
      // },
    ],
  },
  /** 비회원 예약조회 (번호입력, 상세, 없음) **/
  {
    path: "/guest",
    name: "Guest",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/guest/Main"),
      },
      {
        path: "complete",
        component: () => import("@/views/guest/Complete"),
      },
      {
        path: "noresult",
        component: () => import("@/views/guest/Noresult"),
      },
    ],
  },
  {
    path: "/mypage",
    name: "Mypage",
    component: DefaultLayout,
    children: [
      /** 마이헬로스테이 (로그인전, 로그인후, 비밀번호확인, 정보수정, SNS연결설정) **/
      {
        path: "",
        component: () => import("@/views/mypage/Main"),
      },
      {
        path: "login",
        component: () => import("@/views/mypage/Login"),
      },
      {
        path: "password",
        component: () => import("@/views/mypage/Password"),
      },
      {
        path: "edit",
        component: () => import("@/views/mypage/Edit"),
      },
      {
        path: "sns",
        component: () => import("@/views/mypage/Sns"),
      },
      {
				path: 'snslink',
				component: () => import('@/views/mypage/Sns_link')
			},
      /** 마이헬로스테이 포인트 (포인트내역, 포인트없음) **/
      {
        path: "point",
        component: () => import("@/views/mypage/Point"),
      },
      {
        path: "nopoint",
        component: () => import("@/views/mypage/Nopoint"),
      },
      /** 마이헬로스테이 쿠폰 (쿠폰내역) **/
      {
        path: "coupon",
        component: () => import("@/views/mypage/Coupon"),
      },
      {
        path: "nocoupon",
        component: () => import("@/views/mypage/Nocoupon"),
      },
      {
        path: "usedcoupon",
        component: () => import("@/views/mypage/Couponused"),
      },
      {
				path: 'nousedcoupon',
				component: () => import('@/views/mypage/Nousedcoupon')
			},
      /** 마이헬로스테이 숙소예약 내역 (방문전, 방문후, 취소) **/
      {
        path: "booking/list",
        component: () => import("@/views/mypage/Booking_list"),
      },
      {
        path: "booking/list/end",
        component: () => import("@/views/mypage/Booking_end"),
      },
      {
        path: "booking/list/cancel",
        component: () => import("@/views/mypage/Booking_cancel"),
      },
      /** 마이헬로스테이 숙소예약 내역 없음 (방문전, 방문후, 취소) **/
      {
        path: "nobooking",
        component: () => import("@/views/mypage/Nobooking"),
      },
      {
        path: "nobookingend",
        component: () => import("@/views/mypage/Nobooking_end"),
      },
      {
        path: "nobookingcancel",
        component: () => import("@/views/mypage/Nobooking_cancel"),
      },
      /** 마이헬로스테이 예약 취소 **/
      {
        path: "booking/cancel/:id",
        component: () => import("@/views/mypage/Cancel"),
      },
      /** 마이헬로스테이 레저예약 내역 (사용전, 사용후, 취소) **/
      {
        path: "leisure/list",
        component: () => import("@/views/mypage/Leisure_list"),
      },
      {
        path: "leisure/list/end",
        component: () => import("@/views/mypage/Leisure_end"),
      },
      {
        path: "leisure/list/cancel",
        component: () => import("@/views/mypage/Leisure_cancel"),
      },
      /** 마이헬로스테이 레저예약 내역 없음 (사용전, 사용후, 취소) **/
      {
        path: "noleisureend",
        component: () => import("@/views/mypage/Noleisure_end"),
      },
      {
        path: "noleisurecancel",
        component: () => import("@/views/mypage/Noleisure_cancel"),
      },
      /** 마이헬로스테이 장바구니 (장바구니, 장바구니없음) **/
      {
        path: "cart",
        component: () => import("@/views/mypage/Cart"),
      },
      {
        path: "nocart",
        component: () => import("@/views/mypage/Nocart"),
      },
      /** 마이헬로스테이 최근본상품 (숙박, 레저, 숙박없음, 레저없음) **/
      {
        path: "recent",
        component: () => import("@/views/mypage/Recent"),
      },
      {
        path: "recent/leisure",
        component: () => import("@/views/mypage/Recent_leisure"),
      },
      {
        path: "norecent",
        component: () => import("@/views/mypage/Norecent"),
      },
      {
        path: "norecentleisure",
        component: () => import("@/views/mypage/Norecentleisure"),
      },
      /** 마이헬로스테이 내가 초대한 회원 (목록, 없음) **/
      {
        path: "recommend",
        component: () => import("@/views/mypage/Recommend"),
      },
      {
        path: "norecommend",
        component: () => import("@/views/mypage/Norecommend"),
      },
      /** 마이헬로스테이 1:1문의 (목록, 상세, 쓰기) **/
      {
        path: "inquiry/list",
        component: () => import("@/views/mypage/Inquiry_list"),
      },
      {
        path: "inquiry/info/:seq",
        component: () => import("@/views/mypage/Inquiry_info"),
      },
      {
        path: "inquiry/write/:seq?",
        component: () => import("@/views/mypage/Inquiry_write"),
      },
      {
        path: "inquiry/no",
        component: () => import("@/views/mypage/Noinquiry"),
      },
      /** 마이헬로스테이 나의 리뷰 (목록, 쓰기, 수정) **/
      {
        path: "myreview",
        component: () => import("@/views/mypage/Myreview"),
      },
      {
        path: "myreviewno",
        component: () => import("@/views/mypage/Nomyreview"),
      },
      {
        path: "myreview/write",
        component: () => import("@/views/mypage/Myreview_write"),
      },
      {
        path: "myreview/modify",
        component: () => import("@/views/mypage/Myreview_modify"),
      },
      /** 마이헬로스테이 스탬프 (스탬프카드, 적립내역) **/
      {
        path: "stamp",
        component: () => import("@/views/mypage/Stamp"),
      },
      {
        path: "stamplist",
        component: () => import("@/views/mypage/Stamplist"),
      },
      /** 혜택 안내 **/
      {
        path: "benefit",
        component: () => import("@/views/mypage/Benefit"),
      },
      /** 환경 설정 **/
      {
        path: "settings",
        component: () => import("@/views/mypage/Settings"),
      },
    ],
  },
  /** 검색 - (검색, 숙박 검색, 레저 검색, 검색 결과 없음) **/
  {
    path: "/search",
    name: "search",
    component: DefaultLayout,
    children: [
      {
        path: "",
        component: () => import("@/views/search/Main"),
      },
      {
        path: "leisure",
        component: () => import("@/views/search/Leisure"),
      },
      {
        path: "rooms",
        component: () => import("@/views/search/Rooms"),
      },
      {
        path: "noresult",
        component: () => import("@/views/search/Noresult"),
      },
    ],
  },
  /** 고객센터 (공지사항, 공지사항없음, FAQ, 파트너쉽, 약관 및 정책) **/
  {
    path: "/customer",
    name: "customer",
    component: DefaultLayout,
    children: [
      {
        path: "notice",
        component: () => import("@/views/customer/Notice"),
      },
      {
        path: "nonotice",
        component: () => import("@/views/customer/Nonotice"),
      },
      {
        path: "faq",
        component: () => import("@/views/customer/Faq"),
      },
      {
        path: "partnership",
        component: () => import("@/views/customer/Partnership"),
      },
      {
        path: "terms",
        component: () => import("@/views/customer/Terms"),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    //console.log(savedPosition);
    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split("?")[0] == from.fullPath.split("?")[0]) return;
    }
    if (savedPosition) {
      return savedPosition;
    }
    //console.log("up");
    return { top: 0 };
  },
  routes,
});

export default router;
