//import api from "@/urls/mmz01";
import api from "@/urls/urls_all";

const state = {
  mmz0111: [],
};

const getters = {};

const mutations = {
  SET_MMZ0111(state, { mmz0111 }) {
    state.mmz0111 = mmz0111;
  },
};

const actions = {
  async fetchMmz0111({ commit }, params) {
    const data = await api.getMmz0111(params);
    commit("SET_MMZ0111", { mmz0111: data.data.Res_data });
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};