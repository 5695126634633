<template>
	<div id="wrap" class="wrap">
		<!--<transition name="fade-transform">-->
			<router-view :key="key"></router-view>
		<!--</transition>-->
	</div>
</template>

<script>
export default {
	name: 'DefaultLayout',
	computed: {
		key() {
			return this.$route.path
		}
	}
}
</script>
