const state = {
  filterItems: {},
};

const getters = {};

const mutations = {
  SET_FILTER_ITEMS(state, filterItems) {
    state.filterItems = filterItems;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
