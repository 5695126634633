import router from "../../router/routes";

const state = {
  location: {
    lat: null,
    lng: null,
  },
  phoneCode: null,
  shareMsg: null,
  shareMsg2: null,
  fcmToken: null,     
  dataSaveSuccess: null,
  data: {},
  errorLog: null,
  settings: null,
  push: null,
  res_message: null,
  lang: null,
  urlhost: null,    //
  uselang: {
    ko: null,
    en: null,
    jp: null,
    cn: null,
  },
  selectlang: null,
  usefeed: null,
  logo: null,
  loc_name: null,
  last_uptime: {
    days: null,
    hour: null,
    seconds: null,
  },
};

const getters = {};

const mutations = {
  SET_LOCATION(state, { location }) {
    state.location = location;
  },
  SET_RESMESSAGE(state, { res_message }) {
    state.res_message = res_message;
  },
  SET_PHONE_CODE(state, { phoneCode }) {
    state.phoneCode = phoneCode;
  },
  SET_SHARE_MESSAGE(state, { shareMsg }) {
    state.shareMsg = shareMsg;
  },
  SET_SHARE_MESSAGE2(state, { shareMsg2 }) {
    state.shareMsg2 = shareMsg2;
  },
  SET_FCM_TOKEN(state, { fcmToken }) {
    state.fcmToken = fcmToken;
  },
  SET_DATA_SAVE_SUCCESS(state, { dataSaveSuccess }) {
    state.dataSaveSuccess = dataSaveSuccess;
  },
  SET_DATA(state, { key, value }) {
    state.data[key] = value;
  },
  SET_ERROR(state, { errorLog }) {
    state.errorLog = errorLog;
  },
  SET_OPEN_APP_SETTING(state, { settings }) {
    state.settings = settings;
  },
  SET_PUSH_ON_OFF(state, { push }) {
    state.push = push;
  },
  SET_LANG(state, { lang }) {
    state.lang = lang;
  },
  SET_URL(state, { urlhost }) {
    state.urlhost = urlhost;
  },
  SET_USELANG(state, { uselang }) {
    state.uselang = uselang;
  },
  SET_USESELECTLANG(state, { useselectlang }) {
    state.useselectlang = useselectlang;
  },
  SET_USEFEED(state, { usefeed }) {
    state.usefeed = usefeed;
  },
  SET_USELOGO(state, { logo }) {
    state.logo = logo;
  },
  SET_LOC_NAME(state, { loc_name }) {
    state.loc_name = loc_name;
  },
  SET_LAST_UPTIME(state, { last_uptime }) {
    state.last_uptime = last_uptime;
  },
};

const actions = {
  async initialize({ commit }) {
    window.receivedPhoneCode = function receivedPhoneCode(phoneCode) {
      commit("SET_PHONE_CODE", { phoneCode: phoneCode.message["code"] });
    };
    window.receivedLocation = function receivedLocation(location) {
      commit("SET_LOCATION", {
        location: {
          lat: location.message["lat"],
          lng: location.message["lng"],
        },
      });
    };
    window.receivedShare = function receivedShare(share) {
      commit("SET_SHARE_MESSAGE", { shareMsg: share.message });
    };
    window.receivedShare2 = function receivedShare2(share2) {
      commit("SET_SHARE_MESSAGE2", { shareMsg2: share2.message });
    };
    window.receivedFCMappToken = function receivedFCMappToken(token) {
      commit("SET_FCM_TOKEN", { fcmToken: token.message["token"] });
    };
    window.receivedSaveData = function receivedSaveData(data) {
      commit("SET_DATA_SAVE_SUCCESS", { dataSaveSuccess: data.message });
    };
    window.receivedLoadData = function receivedLoadData(data) {
      commit("SET_DATA", {
        key: data.message["key"],
        value: data.message["value"],
      });
    };
    window.error = function error(error) {
      commit("SET_ERROR", { errorLog: error.message });
    };
    window.receivedOpenAppSettings = function receivedOpenAppSettings(
      settings
    ) {
      commit("SET_OPEN_APP_SETTING", { settings: settings.message });
    };
    window.receivedPushOnOff = function receivedPushOnOff(push) {
      commit("SET_PUSH_ON_OFF", { push: push.message });
    };
    window.receivedChangePath = function receivedChangePath(path) {
      var changePath = path.message;
      router.push(changePath);
    };
  },
  requestPhoneCode() {
    window.flutter_inappwebview.callHandler("requestPhoneCode");
  },
  requestLocation() {
    window.flutter_inappwebview.callHandler("requestLocation");
  },
  requestShare() {
    window.flutter_inappwebview.callHandler(
      "requestShare",
      "호텔 예약은 헬로스테이",
      "http://hs.hotelsalad.com/logo.png"
    );
  },
  requestShare2() {
    window.flutter_inappwebview.callHandler("requestShare2");
  },
  requestFCMappToken() {
    window.flutter_inappwebview.callHandler("requestFCMappToken");
  },
  requestSaveData(_, { key, value }) {
    window.flutter_inappwebview.callHandler("requestSaveData", key, value);
  },
  requestLoadData(_, { key }) {
    window.flutter_inappwebview.callHandler("requestLoadData", key);
  },
  requestOpenAppSettings() {
    window.flutter_inappwebview.callHandler("requestOpenAppSettings");
  },
  requestPushOnOff() {
    window.flutter_inappwebview.callHandler("requestPushOnOff");
  },
  requestPushOn() {
    window.flutter_inappwebview.callHandler("requestPushOn", "FCM_ON");
  },
  requestPushOff() {
    window.flutter_inappwebview.callHandler("requestPushOff", "FCM_OFF");
  },
  requestOpenNotificationSettings() {
    window.flutter_inappwebview.callHandler("requestOpenNotificationSettings");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
