//import api from "@/urls/mmy09";
import api from "@/urls/urls_all";

const state = {
  mmy0901: [],
};

const getters = {};

const mutations = {
  SET_MMY0901(state, { mmy0901 }) {
    state.mmy0901 = mmy0901;
  },
};

const actions = {
  async fetchMmy0901({ commit }, { hp_no, booking_no }) {
    const data = await api.getMmy0901({
      proc_cd: "01",
      hp_no,
      booking_no,
    });
    commit("SET_MMY0901", { mmy0901: data.data.Res_data });
    return data.data.Res_cd;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
