// import api from "@/urls/mcd00";
import api from "@/urls/urls_all";
const state = {
  mcd00: {},
};

const getters = {};

const mutations = {
  SET_MCD00(state, { mcd00, code_grp }) {
    state.mcd00[code_grp] = mcd00;
  },
};

const actions = {
  async fetchMcd00({ commit }, { code_grp, proc_cd}) {
    const data = await api.getMcd00({ code_grp, proc_cd });
    commit("SET_MCD00", { mcd00: data.data.Res_data, code_grp: code_grp ?? proc_cd });

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
